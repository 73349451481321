import React from "react";
import { StyleSheet, Text, View, useWindowDimensions } from "react-native";
import Overlay from "./Overlay";
import CloseButton from "./CloseButton";
import { useSelector } from "react-redux";
import CafeMenu from "./CafeMenu";
import { stl } from "../template/Styles";
import { CafeData } from "../App.types";
import { RootState } from "../store";
import { ScrollView } from "react-native-gesture-handler";

interface Props {
  handleClose: () => void;
  data: CafeData;
}

const CafeOverlay = ({ handleClose, data }: Props) => {
  const color = useSelector((state: RootState) => state.color);
  const { width } = useWindowDimensions();
  return (
    <Overlay
      handleClose={handleClose}
      header={() => {
        return (
          <View>
            <View style={[styles.header, { backgroundColor: color.card }]}>
              <Text
                style={[
                  stl.headlineL,
                  { color: color.text, maxWidth: width - 84 },
                ]}
              >
                Cafe
              </Text>
              <CloseButton onPress={handleClose} />
            </View>
          </View>
        );
      }}
    >
      <View style={styles.content}>
        {/* <FoodTeaser data={data.foodOfTheDay} /> */}
        {data.menu.map((x, index) => {
          return <CafeMenu data={x} key={x.title} />;
        })}
      </View>
    </Overlay>
  );
};

export default CafeOverlay;

const styles = StyleSheet.create({
  date: {
    padding: 12,
    paddingBottom: 4,
    fontWeight: "300",
    fontSize: 12,
    fontFamily: "FS_Elliot_Pro-Light",
  },

  content: {
    padding: 24,
  },
  headline: {
    fontWeight: "bold",
    fontSize: 24,
    fontFamily: "FS_Elliot_Pro-Heavy",
    paddingRight: 20,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 10,
    minHeight: 64,
  },
});

const FOOD = {
  title: "Food",
  items: [
    {
      name: "Sandwich",
      price: "4€",
    },
    {
      name: "Sandwich",
      price: "4€",
    },
    {
      name: "Sandwich",
      price: "4€",
    },
    {
      name: "Sandwich",
      price: "4€",
    },
  ],
};

const DRINKS = {
  title: "Getränke",
  items: [
    {
      name: "Cola",
      price: "1,50€",
    },
    {
      name: "Cafe",
      price: "1,50€",
    },
    {
      name: "Bier",
      price: "1,50€",
    },
    {
      name: "Frangelico",
      price: "1,50€",
    },
  ],
};
