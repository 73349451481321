import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  Text,
  Pressable,
  Platform,
} from "react-native";
import { useSelector } from "react-redux";
import { NewsType } from "../App.types";
import { RootState } from "../store";
import { stl } from "../template/Styles";

interface Props {
  onPress: () => void;
  news: NewsType;
}

const NewsCard = ({ onPress, news }: Props) => {
  const color = useSelector((state: RootState) => state.color);

  const sourceInit = news.image.uri?.startsWith(
    "https://confluence.mutabor.de/download/"
  )
    ? news.image
    : require("../assets/SplashScreen.png");
  const [source, setSource] = useState(
    Platform.OS === "web" ? require("../assets/IconMutabor.png") : sourceInit
  );

  useEffect(() => {
    if (
      Platform.OS === "web" &&
      news.image.uri?.startsWith("https://confluence.mutabor.de/download/")
    ) {
      fetch(news.image.uri, {
        method: "GET",
        headers: {
          Authorization: "Basic dHdpbmtsZXI6SnVsaWEyMDA3",
        },
      })
        .then((res) => res.blob())
        .then((content) => setSource(URL.createObjectURL(content)));
    }
  }, []);

  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [
        stl.shdw,
        styles.box,
        { backgroundColor: color.card, opacity: pressed ? 0.7 : 1 },
      ]}
    >
      <View style={styles.cover}>
        <Image
          style={styles.image}
          defaultSource={require("../assets/SplashScreen.png")}
          source={source}
        />
      </View>

      <View style={styles.infoBox}>
        <Text style={[stl.copyM, { color: color.text }]}>
          {news.date.format("D MMM YYYY")}
        </Text>
        <Text style={[stl.headlineM, { color: color.text }]}>{news.title}</Text>
      </View>
    </Pressable>
  );
};

export default NewsCard;

const styles = StyleSheet.create({
  box: {
    padding: 12,
    flexDirection: "row",
    borderRadius: 4,
    marginBottom: 12,
    width: "100%",
  },
  infoBox: {
    flexDirection: "column",
    flex: 1,
  },
  cover: {
    paddingRight: 12,
    overflow: "hidden",
    borderRadius: 4,
  },
  image: {
    width: 80,
    height: 80,
    borderRadius: 4,
  },
});
