import { ExpoPushToken } from 'expo-notifications';
import { Page } from "../App.types";

type User = {
    id: number,
    user: string
}


export class ConfluenceManager {

    static getNews = async () => {

        try {

            const response = await fetch('https://confluence.mutabor.de/rest/api/content/39290054/child/page', {
                method: 'GET',
                //mode: "no-cors",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',

                }
            }).then((response) => response.json())
                .catch(err => console.log(err))

            let news = []

            for (const child of response.results) {

                const content = await fetch('https://confluence.mutabor.de/rest/api/content/' + child.id + '?expand=body.export_view,version,body.editor', {//39290102
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',

                    }
                }).then((response) => response.json())
                    .then(response => { return { value: response.body.export_view.value, title: response.title, date: response.version.when, img: response.body.editor.value } })
                    .catch(err => console.log(err))
                if (content != null) {
                    news.push({ ...content })
                }

            }


            return news

        }
        catch (e) {
            console.log(e)
            return null
        }
    }

    static setToken = async (token: ExpoPushToken) => {

        const statusQuo = await fetch('https://confluence.mutabor.de/rest/api/content/39290056?expand=body.storage,version', {
            method: 'GET',
            //mode: "no-cors",
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',
            }
        }).then((response) => response.json())
            .catch(err => console.log(err))



        if (statusQuo.body.storage.value.includes(token.data)) {

        } else {

            const data = {
                "id": "39290056",
                "type": "page",
                "title": "Subscribers",
                "space": { "key": "APP" },
                "body": { "storage": { "value": `${statusQuo.body.storage.value} <p>${token.data}</p>`, "representation": "storage" } },
                "version": { "number": statusQuo.version.number + 1 }
            }



            const result = await fetch('https://confluence.mutabor.de/rest/api/content/39290056', {
                method: 'PUT',
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',
                },
                body: JSON.stringify(data)
            }).then((response) => response.json())
                .catch(err => console.log(err))


            return result
        }
    }


    static getPage = async (id: number) => {

        try {
            const result = await fetch('https://confluence.mutabor.de/rest/api/content/' + id + '?expand=body.export_view', {
                method: 'GET',
                //mode: "no-cors",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',
                    //'Authorization': 'Bearer MjkwNjEzOTYyNTAzOrt/6P+zAeCNsp2TpqiQQ+x2xrt3'
                },

            })
                .then((response) => response.json())
                .then(response => {

                    return { body: response.body.export_view.value, title: response.title }
                })

            return result
        }
        catch (e) {
            console.log(e)
            return null
        }
    }


    static getAllPages = async (start: number = 0) => {

        try {

            const result: {
                pages: any[],
                size: number
            } = await fetch('https://confluence.mutabor.de/rest/api/space/APP/content/page?expand=ancestors,children.page&limit=200&start=' + start, {
                method: 'GET',
                //mode: "no-cors",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',
                    //'Authorization': 'Bearer MjkwNjEzOTYyNTAzOrt/6P+zAeCNsp2TpqiQQ+x2xrt3'
                },

            })
                .then((response) => response.json())
                .then((response) => {
                    return { pages: response.results, size: response.size }
                })

            let otherPages: Page[] = []
            if (result.size >= 200) {
                const res = await ConfluenceManager.getAllPages(start + 200)
                if (res != null && res.length > 0) {
                    otherPages = res
                }
            }

            const newPages: Page[] = result.pages.map(page => {
                return {
                    id: page.id,
                    title: page.title,
                    ancestors: page.ancestors,
                    children: []
                }
            })


            return [...newPages, ...otherPages]

        }
        catch (e) {
            console.log(e)
            return null
        }
    }

    static getMenu = async () => {

        try {
            //44499344
            const result = await fetch('https://confluence.mutabor.de/rest/api/content/44499344?expand=body.view', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',
                },

            })
                .then((response) => response.json())

            return result.body.view.value
        } catch (e) {
            console.log(e)
            return null
        }
    }

    static getFood = async () => {

        try {
            //44499344
            const response = await fetch('https://confluence.mutabor.de/rest/api/content/44499344/child/page', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',
                },

            })
                .then((response) => response.json())

            const foods = []

            for (const child of response.results) {
                const res = await fetch('https://confluence.mutabor.de/rest/api/content/' + child.id + '?expand=body.editor', {
                    method: 'GET',
                    
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',
                    },

                })
                    .then((response) => response.json())

                foods.push({ value: res.body.editor.value, id: child.id })
            }

            return foods
        }
        catch (e) {
            console.log(e)
            return null
        }
    }

    static getCafeData = async () => {

        const menu = await ConfluenceManager.getMenu()

        const foods = await ConfluenceManager.getFood()

        if (menu == null || foods == null) {
            return null
        }
        return { menu, foods }
    }

    static saveFood = async ({ id, user }: User) => {

        const res1 = await fetch('https://confluence.mutabor.de/rest/api/content/' + id + '?expand=body.storage,version', {
            method: 'GET',
            
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',
            },
        })
            .then((response) => response.json())
            .catch(err => console.log(err))

        const text = res1.body.storage.value
        const index = text.indexOf('Anmeldungen</td><td colspan="1">') + 32
        const textEnd = text.slice(index)
        const textStart = text.slice(0, index)
        const newText = textStart + user + ', ' + textEnd




        const data = {
            "id": id,
            "type": res1.type,
            "title": res1.title,
            "space": { "key": "APP" },
            "body": { "storage": { "value": newText, "representation": "storage" } },
            "version": { "number": res1.version.number + 1 }
        }

        const res2 = await fetch('https://confluence.mutabor.de/rest/api/content/' + id, {
            method: 'PUT',
            //mode: "no-cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
            .catch(err => console.log(err))


    }

    static deleteFood = async ({ id, user }: User) => {

        const res1 = await fetch('https://confluence.mutabor.de/rest/api/content/' + id + '?expand=body.storage,version', {
            method: 'GET',
            
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',
            },
        })
            .then((response) => response.json())
            .catch(err => console.log(err))

        const text = res1.body.storage.value.replace(user, "")



        const data = {
            "id": id,
            "type": res1.type,
            "title": res1.title,
            "space": { "key": "APP" },
            "body": { "storage": { "value": text, "representation": "storage" } },
            "version": { "number": res1.version.number + 1 }
        }

        const res2 = await fetch('https://confluence.mutabor.de/rest/api/content/' + id, {
            method: 'PUT',
            //mode: "no-cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic dHdpbmtsZXI6SnVsaWEyMDA3',
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
            .catch(err => console.log(err))


    }
}