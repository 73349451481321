import React, { useState, useEffect } from "react";
import * as Images from "../assets/faces/index";
import {
  Pressable,
  Text,
  View,
  StyleSheet,
  Image,
  useWindowDimensions,
  Platform,
} from "react-native";
import { stl } from "../template/Styles";
import {
  AirplainOutline,
  HomeOutline,
  OfficeOutline,
  VacationOutline,
} from "./Icons";
import { useSelector } from "react-redux";
import { Office, Person } from "../App.types";
import { RootState } from "../store";
import AsyncStorage from "@react-native-async-storage/async-storage";

interface Props {
  person: Person;
  onPress: () => void;
  office: Office | null;
}

const People = ({ person, onPress, office }: Props) => {
  const color = useSelector((state: RootState) => state.color);
  const { width } = useWindowDimensions();

  const getCardWidth = () => {
    let x = Math.trunc(width / 400);
    let cardsize = x > 0 ? `${100 / x}%` : "100%";
    return cardsize;
  };

  const [cardWidth, setcardWidth] = useState(getCardWidth());
  const [token, setToken] = useState<null | string>(null);

  let image = `${person.givenName}_${person.surname}`;
  image = image
    .toLocaleLowerCase()
    .replace(" ", "_")
    .replace("ä", "ae")
    .replace("ü", "ue")
    .replace("ö", "oe");

  const [source, setSource] = useState(
    Images.default[image as keyof typeof Images.default]
  );

  useEffect(() => {
    setcardWidth(getCardWidth());
  }, [width]);

  useEffect(() => {
    if (Platform.OS === "web") {
      getImg();
    } else {
      getToken();
    }
  }, []);

  const getImg = async () => {
    const Token = await getToken();
    const response = await fetch(
      `https://graph.microsoft.com/beta/users/${person.id}/photo/$value`,
      {
        headers: {
          Authorization: "Bearer " + Token,
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          return null;
        } else {
          return res.blob();
        }
      })
      .catch((e) => {});

    if (response === null || !response) {
      return;
    }

    setSource(URL.createObjectURL(response));
  };

  const getToken = async () => {
    const accessToken = await AsyncStorage.getItem("userToken");
    if (accessToken) {
      setToken(accessToken);
    }
    return accessToken;
  };

  const jobTitle = person.jobTitle !== null ? person.jobTitle : "";

  return (
    <View
      style={{
        width: cardWidth,
      }}
    >
      <Pressable
        onPress={onPress}
        style={({ pressed }) => [
          stl.shdw,
          styles.card,
          {
            backgroundColor: color.card,
            opacity: pressed ? 0.7 : 1,
            flex: 1,
            paddingHorizontal: 12,
            marginRight: 12,
            marginBottom: 12,
          },
        ]}
      >
        <View style={styles.cover}>
          {Platform.OS != "web" && token !== null ? (
            <Image
              style={styles.image}
              defaultSource={
                Images.default[image as keyof typeof Images.default]
                  ? Images.default[image as keyof typeof Images.default]
                  : require("../assets/SplashScreen.png")
              }
              source={{
                uri: `https://graph.microsoft.com/beta/users/${person.id}/photo/$value`,
                headers: {
                  Authorization: "Bearer " + token,
                },
              }}
            />
          ) : (
            <Image
              style={styles.image}
              defaultSource={require("../assets/SplashScreen.png")}
              source={source}
            />
          )}
        </View>

        <View style={styles.content}>
          <Text
            style={[stl.headlineM, styles.paddingBottom, { color: color.text }]}
          >
            {person.displayName}
          </Text>

          <Text
            style={[
              stl.copyM,
              styles.paddingBottom,
              { color: `${color.text}B3` },
            ]}
          >
            {jobTitle}
          </Text>

          {/* <Text style={[stl.copyS, styles.paddingBottom, { color: `${text}B3` }]}>{mail}</Text> */}
        </View>

        {office != null && <OfficeIndicator office={office} />}
      </Pressable>
    </View>
  );
};

interface OfficeProps {
  office: Office;
}

const OfficeIndicator = ({ office }: OfficeProps) => {
  const color = useSelector((state: RootState) => state.color);
  const size = 12;
  return (
    <View style={[styles.circle, { backgroundColor: color.text }]}>
      {office == "urlaub" && (
        <VacationOutline color={color.background} width={size} height={size} />
      )}
      {office == "reise" && (
        <AirplainOutline color={color.background} width={size} height={size} />
      )}
      {office == "office" && (
        <OfficeOutline color={color.background} width={size} height={size} />
      )}
      {office == "home" && (
        <HomeOutline color={color.background} width={size} height={size} />
      )}
    </View>
  );
};

export default People;

const styles = StyleSheet.create({
  card: {
    flexDirection: "row",
    borderRadius: 4,
    zIndex: 1,
    padding: 12,
  },
  cover: {
    paddingRight: 12,
    borderRadius: 4,

    overflow: "hidden",
  },
  image: {
    width: 80,
    height: 80,
    borderRadius: 4,
  },
  paddingBottom: {
    paddingBottom: 4,
  },
  content: {
    paddingLeft: 0,
    flex: 1,
  },
  mail: {
    textTransform: "lowercase",
  },
  circle: {
    borderRadius: 40,
    position: "absolute",
    bottom: 12,
    right: 12,
    padding: 4,
  },
});
