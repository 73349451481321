import { Platform } from 'react-native';
import * as Linking from 'expo-linking';


export const callNumber = (phone: string) => {

    let phoneNumber = phone;
    if (Platform.OS === 'ios') {
        phoneNumber = `telprompt:${phone}`;
    }
    else {
        phoneNumber = `tel:${phone}`;
    }
    Linking.canOpenURL(phoneNumber)
        .then(supported => {
            if (!supported) {
                alert('Phone number is not available');
            } else {
                return Linking.openURL(phoneNumber);
            }
        })
        .catch(err => console.log(err));
};

export const mailTo = (mail: string) => {

    let mailAdress = `mailto:${mail}`;

    Linking.canOpenURL(mailAdress)
        .then(supported => {
            if (!supported) {
                alert('Mail Adress is not available');
            } else {
                return Linking.openURL(mailAdress);
            }
        })
        .catch(err => console.log(err));
};

export const teamsTo = (mail: string) => {

    //let mailAdress2 = "https://teams.microsoft.com/l/chat/0/0?users=" + mail;
    let mailAdress = "msteams:/l/chat/0/0?users=" + mail;

    Linking.canOpenURL(mailAdress)
        .then(supported => {
            // if (!supported) {
            //     alert('Mail Adress is not available');
            // } else {
            return Linking.openURL(mailAdress);
            //}
        })
        .catch(err => console.log(err));
};