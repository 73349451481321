import React from 'react'
import { Pressable, StyleSheet, Text } from 'react-native'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { stl } from '../template/Styles'

interface Props {
    onPress: () => void,
    text: string,
    type?: "secondary" | string
}

const Button = ({ onPress, text, type }: Props) => {
    const color = useSelector((state: RootState) => state.color)

    const t = type == "secondary"
    return (
        <Pressable onPress={onPress} style={({ pressed }) => [stl.shdw, styles.box, { opacity: pressed ? 0.7 : 1, backgroundColor: t ? color.text : color.baseColor }]}>
            <Text style={[stl.btn, { color: t ? color.background : '#fff' }]}>{text}</Text>
        </Pressable>
    )
}

export default Button

const styles = StyleSheet.create({
    box: {
        paddingHorizontal: 12,
        paddingVertical: 6,
        borderRadius: 4
    }
})
