import React, { useState } from 'react'
import { Modal, Pressable, StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import 'moment/locale/de'
import { stl } from '../template/Styles'
import TerminDetailOverlay from './TerminDetailOverlay';
import { TerminProp } from '../App.types'
import { RootState } from '../store'

interface Props {
    termin: TerminProp,
    setOverlay: React.Dispatch<React.SetStateAction<boolean>>
}

const Termin = ({ termin, setOverlay }: Props) => {

    const { end, start, subject, location } = termin
    const color = useSelector((state: RootState) => state.color)
    const [extended, setExtended] = useState(false)

    return (
        <Pressable style={[styles.box, stl.shdw, { backgroundColor: color.card }]} onPress={() => {
            setExtended(true)
            setOverlay(true)
        }}>
            <View style={[styles.timeBox, {}]}>
                <Text style={[stl.copyM, styles.time, { color: color.text }]}>{`${start.local().format('kk:mm')} — ${end.format('kk:mm')}`}</Text>
            </View>
           <View style={[styles.info, {}]}>
                <Text style={[stl.headlineL, { color: color.text }]} numberOfLines={2}>{subject}</Text>
                {location && location.displayName != "" && <Text style={[stl.copyM, { color: color.text }]}>{location?.displayName}</Text>}
            </View>
            <Modal visible={extended} transparent={true} animationType={"slide"} statusBarTranslucent={true}>
                <TerminDetailOverlay handleClose={() => {
                    setExtended(false)
                    setOverlay(false)
                }} {...{ termin }} />
            </Modal>
        </Pressable>
    )
}

export default Termin

const styles = StyleSheet.create({
    box: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: 4,
        padding: 12,
        marginVertical: 12,
    },
    timeBox: {
        width: 45,
        justifyContent: "center"
    },
    time: {
        textAlign: "center"
    },
    info: {
        paddingLeft: 20,
        flex: 1
    },
    icon: {
        position: "absolute",
        top: 12,
        right: 12
    },
})
