// Adapted from https://reactnavigation.org/docs/auth-flow.html
import React, { useEffect, useState } from "react";
import {
  TouchableOpacity,
  StyleSheet,
  View,
  Text,
  ActivityIndicator,
  Platform,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { AuthManager } from "../auth/AuthManager";
import { Mutabor } from "../components/Icons";
import { stl } from "../template/Styles";
import { _getCalendar, _updateUser, _getFood } from "../functions/loadApp";
import { RootState } from "../store";
import { NavigationStackScreenProps } from "react-navigation-stack";
import * as WebBrowser from "expo-web-browser";
import {
  makeRedirectUri,
  useAuthRequest,
  useAutoDiscovery,
} from "expo-auth-session";
import { AuthConfig } from "../auth/AuthConfig";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";

WebBrowser.maybeCompleteAuthSession();

const SignInScreen = ({ navigation }: NavigationStackScreenProps) => {
  const [loading, setLoading] = useState(Platform.OS === "web");
  const discovery = useAutoDiscovery(
    `https://login.microsoftonline.com/d7be3a69-5dd4-4fc0-90ec-21437e405ab1/v2.0`
  );
  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId: AuthConfig.appId,
      scopes: AuthConfig.appScopes,
      responseType: "token",
      // For usage in managed apps using the proxy
      redirectUri: makeRedirectUri({
        native: "de.mutabor.mutaborapp://redirect",
        useProxy: false,
      }),
    },
    discovery
  );

  useEffect(() => {
    saveTokens();
  }, [response]);

  useEffect(() => {
    if (request != null) {
      setLoading(false);
    }
  }, [request]);

  const saveTokens = async () => {
    if (
      response?.type == "success" &&
      typeof response.authentication?.accessToken != "undefined"
    ) {
      await AsyncStorage.setItem(
        "userToken",
        response.authentication?.accessToken
      );

      let expire = moment()
        .add(response.authentication.expiresIn, "seconds")
        .format();

      await AsyncStorage.setItem("expireTime", expire);

      getData();
      navigation.navigate("App");
    }
  };

  const color = useSelector((state: RootState) => state.color);
  const dispatch = useDispatch();

  const signInAsync = async () => {
    await setLoading(true);

    try {
      await AuthManager.signInAsync();
    } catch (error) {
      alert(error);
      console.log(error);
      return;
    }
    const res = await getData();

    if (res) {
      navigation.navigate("App");
    }

    setLoading(false);
  };

  const getData = async () => {
    try {
      const events = await _getCalendar();
      dispatch({
        type: "UPDATE_EVENTS",
        payload: {
          events,
        },
      });

      const payload = await _updateUser();
      if (payload != null) {
        dispatch({
          type: "UPDATE_DATA",
          payload,
        });
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  return (
    <View style={[styles.container, { backgroundColor: color.background }]}>
      <View style={styles.logo}>
        <Mutabor
          color={color.text}
          // width="80"
          // height="80"
        />
      </View>
      {loading == false && (
        <TouchableOpacity
          onPress={Platform.OS === "web" ? () => promptAsync() : signInAsync}
        >
          <View style={[styles.button, { backgroundColor: color.text }]}>
            <Text style={[stl.btn, { color: color.background }]}>Sign In</Text>
          </View>
        </TouchableOpacity>
      )}
      {loading == true && (
        <View>
          <ActivityIndicator size="large" color={color.text} />
          <Text style={[stl.headlineM, { color: color.text }]}>
            Logging in...
          </Text>
        </View>
      )}
    </View>
  );
};

export default SignInScreen;

const styles = StyleSheet.create({
  container: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    padding: 24,
    paddingBottom: 80,
  },
  button: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 4,
  },
});
