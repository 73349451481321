import { AuthConfig } from "./AuthConfig";
import { Platform } from "react-native";
import * as AuthSession from "expo-auth-session";
import moment from "moment";
import AsyncStorage from "@react-native-async-storage/async-storage";

const redirectUrl = AuthSession.makeRedirectUri({
  native: "de.mutabor.mutaborapp://redirect",
  useProxy: false,
});

// const redirectUrl = "exp://192.168.1.4:19000"

const scope = AuthConfig.appScopes.join("%20");

const AuthUrl =
  `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?` +
  `client_id=${AuthConfig.appId}` +
  `&scope=${scope}` +
  `&redirect_uri=${redirectUrl}` +
  `&response_type=${Platform.OS === "web" ? "token" : "code"}`;

export class AuthManager {
  static signInAsync = async () => {
    if (Platform.OS === "web") {
      const result = await AuthSession.startAsync({
        authUrl: AuthUrl,
        returnUrl: redirectUrl,
      });
      if (result.type != "success") {
        return;
      }
      await AsyncStorage.setItem("userToken", result.params.access_token);
      //await AsyncStorage.setItem("refreshToken", result.refreshToken);
      let expire = moment().add(result.params.expires_in, "seconds").format();
      await AsyncStorage.setItem("expireTime", expire);
    } else {
      console.log("url", redirectUrl);
      //authenificating & fetching authenfication Code
      const result1 = await AuthSession.startAsync({
        authUrl: AuthUrl,
        returnUrl: redirectUrl,
      });

      if (result1.type != "success") {
        return;
      }

      //geting Token with authenfication code
      const result = await fetch(
        `https://login.microsoftonline.com/common/oauth2/v2.0/token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `grant_type=authorization_code&client_id=${AuthConfig.appId}&redirect_uri=${redirectUrl}&code=${result1.params.code}&scope=${scope}`,
        }
      ).then((response) => response.json());

      //Store the access token, refresh token, and expiration time in storage
      await AsyncStorage.setItem("userToken", result.access_token);
      await AsyncStorage.setItem("refreshToken", result.refresh_token);
      let expire = moment().add(result.expires_in, "seconds").format();

      await AsyncStorage.setItem("expireTime", expire);
    }
  };

  static signOutAsync = async () => {
    // Clear storage
    await AsyncStorage.removeItem("userToken");
    await AsyncStorage.removeItem("refreshToken");
    await AsyncStorage.removeItem("expireTime");
  };

  static getAccessTokenAsync = async () => {
    const expireTime = await AsyncStorage.getItem("expireTime");

    if (expireTime !== null) {
      // Get expiration time - 5 minutes
      // If it's <= 5 minutes before expiration, then refresh
      const expire = moment(expireTime).subtract(5, "minutes");
      const now = moment();

      if (now.isSameOrAfter(expire)) {
        // Expired, refresh
        const refreshToken = await AsyncStorage.getItem("refreshToken");
        if (refreshToken == null) {
          return null;
        }

        const result = await fetch(
          `https://login.microsoftonline.com/common/oauth2/v2.0/token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `grant_type=refresh_token&client_id=${AuthConfig.appId}&refresh_token=${refreshToken}&scope=${scope}`,
          }
        ).then((response) => response.json());

        // Store the new access token, refresh token, and expiration time in storage
        await AsyncStorage.setItem("userToken", result.access_token);
        await AsyncStorage.setItem("refreshToken", result.refresh_token);
        let expire = moment().add(result.expires_in, "seconds").format();

        await AsyncStorage.setItem("expireTime", expire);
        //console.log(result.access_token)
        return result.access_token;
      }

      // Not expired, just return saved access token
      const accessToken = await AsyncStorage.getItem("userToken");
      return accessToken;
    }

    return null;
  };
}
