import React from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  Platform,
  useWindowDimensions,
} from "react-native";
import Tag from "./Tag";

// type FilterProps = {
//     readonly filters: readonly string[],
//     activeFilters: string[],
//     onPress(x: string): void
// }

type FilterProps = {
  filters: readonly string[];
  activeFilters: string[];
  onPress: (x: FilterProps["filters"][number]) => void;
};

const Filter = ({ filters, activeFilters, onPress }: FilterProps) => {
  const { width, height } = useWindowDimensions();

  if (Platform.OS === "web" && (width > 800 || height > 1000)) {
    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          paddingHorizontal: 24,
        }}
      >
        {filters.map((tag) => (
          <Tag
            key={tag}
            tag={tag}
            onPress={onPress}
            active={activeFilters.includes(tag) ? true : false}
          />
        ))}
      </View>
    );
  }
  return (
    <View
      style={{
        height: 56,
        overflow: Platform.OS === "web" ? "scroll" : "visible",
      }}
    >
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        style={{ overflow: "visible" }}
        contentContainerStyle={[styles.box, { overflow: "visible" }]}
      >
        {filters.map((tag) => (
          <Tag
            key={tag}
            tag={tag}
            onPress={onPress}
            active={activeFilters.includes(tag) ? true : false}
          />
        ))}
      </ScrollView>
    </View>
  );
};
export default Filter;

const styles = StyleSheet.create({
  box: {
    paddingHorizontal: 20,
    alignItems: "center",
  },
});
