import { Person } from "../App.types";

type PeopleState = Person[];

interface PeopleAction {
  type: "UPDATE_DATA";
  payload: {
    people: PeopleState;
  };
}

const initalPerson: Person[] = [];

const peopleReducer = (
  state: PeopleState = initalPerson,
  action: PeopleAction
) => {
  switch (action.type) {
    case "UPDATE_DATA":
      return action.payload.people;
    default:
      return state;
  }
};

export default peopleReducer;
