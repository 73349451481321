import React from "react";
import { Provider } from "react-redux";
import AppNavigator from "./navigation/AppNavigator";
import { useFonts } from "@use-expo/font";
import store from "./store";
import AppLoading from "expo-app-loading";

const App = () => {
  let [fontsLoaded] = useFonts({
    "FS_Elliot_Pro-Light": require("./assets/fonts/FS_Elliot_Pro-Light.otf"),
    "FS_Elliot_Pro-Heavy": require("./assets/fonts/FS_Elliot_Pro-Heavy.otf"),
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <Provider store={store}>
        <AppNavigator />
      </Provider>
    );
  }
};

export default App;
