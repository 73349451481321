import React from "react";
import Svg, { G, Path, SvgProps } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-Svg: title */

export const AirplainOutline = (props: SvgProps) => (
  <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
    <Path d="M10.6868 29.9998L6.84526 23.1532L0 19.3089L2.75959 16.5488L8.47992 16.9512L12.548 12.6538L1.95495 4.72892L4.5915 2.09186L18.9968 5.84235L22.6235 2.01177C23.8559 0.742076 25.348 0.00955872 26.7209 -0.000208183C27.6486 -0.00411494 28.4708 0.310379 29.0782 0.915927C29.6856 1.52343 30.0137 2.3751 30 3.31663C29.9805 4.69572 29.2442 6.17442 27.9806 7.37185L24.2758 10.8821L28.1115 25.6145L25.4749 28.2515L17.3719 17.42L13.0441 21.5182L13.4464 27.2397L10.6868 29.9998ZM2.56233 18.9554L7.99362 22.0027L11.0403 27.435L11.8371 26.638L11.4328 20.8912L17.5692 15.0799L25.6409 25.8723L26.3694 25.1437L22.5278 10.384L26.9045 6.23889C27.8654 5.32861 28.4239 4.25621 28.4357 3.2971C28.4435 2.77359 28.2814 2.33213 27.9708 2.02154C27.588 1.63868 27.0764 1.5625 26.7307 1.56445C25.7737 1.57226 24.6859 2.13093 23.7465 3.09785L19.4929 7.59062L5.06022 3.83232L4.33175 4.56093L14.8858 12.4584L9.10683 18.5628L3.36111 18.1584L2.56233 18.9554Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const AirplainSolid = (props: SvgProps) => (
  <Svg width="31" height="30" viewBox="0 0 31 30" fill="none" {...props}>
    <Path d="M30.2308 0.738589C28.9269 -0.5236 26.4036 -0.186062 24.3093 1.90054L19.9348 6.37446L3.93496 2.34241L2.06892 4.14875L13.7998 12.6445L8.33485 18.2313L1.97382 17.7976L0 19.7082L6.81117 23.4089L10.6341 30.0021L12.6079 28.0915L12.1599 21.934L17.9652 16.6111L26.9382 28.2224L28.8043 26.4161L24.5439 10.5804L29.0283 6.4706C31.2113 4.46788 31.5325 1.99873 30.2308 0.738589Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const Arrow = (props: SvgProps) => (
  <Svg width="12" height="22" viewBox="0 0 12 22" fill="none" {...props}>
    <Path d="M1.17854 22L0 20.9175L9.12415 11.001L0 1.08449L1.17854 0L11.2991 11.001L1.17854 22Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)

export const CalenderOutline = (props: SvgProps) => (
  <Svg width="22" height="23" viewBox="0 0 22 23" fill="none" {...props}>
    <Path d="M18.5313 2.45099V0H13.6949V2.45099H8.30514V0H3.4687V2.45099H0V22.8462H12.8843C14.1921 22.8462 15.434 22.5689 16.558 22.0705C18.6358 21.1514 20.3092 19.4741 21.2262 17.3915C21.7234 16.2649 22 15.0201 22 13.7092V2.45099H18.5313ZM13.3176 21.2833V14.1436H20.4407C20.2221 17.981 17.1461 21.0641 13.3176 21.2833ZM20.4523 12.5923H11.77V21.2949H1.54766V8.42527H20.4523V12.5923ZM20.4523 6.87401H1.54766V4.00225H5.01636V1.55126H6.75747V4.00225H15.2425V1.55126H16.9836V4.00225H20.4523V6.87401Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const CalenderSolid = (props: SvgProps) => (
  <Svg width="22" height="23" viewBox="0 0 22 23" fill="none" {...props}>
    <Path d="M11.8292 14.3449V12.6803H13.4938H22V8.20874H0V22.8511H11.8292V14.3449Z" fill={props.color ? props.color : "black"} />
    <Path d="M22 6.54403V2.63009H18.2692V0H14.7319V2.63009H7.26814V0H3.73082V2.63009H0V6.54403H22Z" fill={props.color ? props.color : "black"} />
    <Path d="M13.4938 14.3448V22.8386C18.0777 22.6034 21.7524 18.9288 21.9875 14.3448H13.4938Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const ClockOutline = (props: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path d="M11 1.60146C16.1827 1.60146 20.3985 5.81729 20.3985 11C20.3985 16.1827 16.1807 20.3985 11 20.3985C5.81929 20.3985 1.60146 16.1807 1.60146 11C1.60146 5.81929 5.81729 1.60146 11 1.60146ZM11 0C4.92448 0 0 4.92448 0 11C0 17.0755 4.92448 22 11 22C17.0755 22 22 17.0755 22 11C22 4.92448 17.0735 0 11 0Z" fill={props.color ? props.color : "black"} />
    <Path d="M16.5851 15.8784L10.1993 11.4163V3.4491H11.8007V10.5816L17.5019 14.5672L16.5851 15.8784Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const ClockSolid = (props: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path d="M11 0C4.92448 0 0 4.92448 0 11C0 17.0755 4.92448 22 11 22C17.0755 22 22 17.0755 22 11C22 4.92448 17.0735 0 11 0ZM16.5851 15.8784L10.1993 11.4164V3.44914H11.8007V10.5816L17.5019 14.5672L16.5851 15.8784Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const CloseOutline = (props: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path d="M11 1.60146C16.1827 1.60146 20.3985 5.81729 20.3985 11C20.3985 16.1827 16.1827 20.3985 11 20.3985C5.81729 20.3985 1.60146 16.1827 1.60146 11C1.60146 5.81729 5.81729 1.60146 11 1.60146ZM11 0C4.92448 0 0 4.92448 0 11C0 17.0755 4.92448 22 11 22C17.0755 22 22 17.0755 22 11C22 4.92448 17.0735 0 11 0Z" fill={props.color ? props.color : "black"} />
    <Path d="M14.861 5.8318L5.98422 15.0581L7.13823 16.1684L16.015 6.94209L14.861 5.8318Z" fill={props.color ? props.color : "black"} />
    <Path d="M7.13823 5.83136L5.98422 6.94165L14.861 16.168L16.015 15.0577L7.13823 5.83136Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const CloseSolid = (props: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path d="M11 0C4.92448 0 0 4.92448 0 11C0 17.0755 4.92448 22 11 22C17.0755 22 22 17.0755 22 11C22 4.92448 17.0735 0 11 0ZM16.0146 15.0577L14.8595 16.1687L11 12.155L7.13849 16.1687L5.98344 15.0577L9.88699 11L5.98344 6.94231L7.13849 5.8313L11 9.84495L14.8615 5.8313L16.0146 6.94231L12.111 11L16.0146 15.0577Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const Close = (props: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path d="M14.861 5.8318L5.98424 15.0581L7.13825 16.1684L16.015 6.94209L14.861 5.8318Z" fill={props.color ? props.color : "black"}/>
    <Path d="M7.13825 5.83136L5.98424 6.94165L14.861 16.168L16.015 15.0577L7.13825 5.83136Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)

export const GoToArrow = (props: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path d="M11.3952 0L10.2166 1.08449L18.6045 10.2006H0V11.8014H18.6045L10.2166 20.9175L11.3952 22L21.5158 11.001L11.3952 0Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)


export const HomeOutline = (props: SvgProps) => (
  <Svg width="20" height="22" viewBox="0 0 20 22" fill="none" {...props}>
    <Path d="M9.99905 1.79753L18.4867 6.99361V20.4568H14.0131V14.0604V12.5191H12.4998H7.50024H5.98695V14.0604V20.4568H1.51329V6.99553L9.99905 1.79753ZM9.99905 0L0 6.12278V22H7.50024V14.0624H12.4998V22H20V6.12278L9.99905 0Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const HomeSolid = (props: SvgProps) => (
  <Svg width="20" height="22" viewBox="0 0 20 22" fill="none" {...props}>
    <Path d="M9.99905 0L0 6.12278V22H7.50024V14.0624H12.4998V22H20V6.12278L9.99905 0Z" fill={props.color ? props.color : "black"} />
  </Svg>
)


export const InfoOutline = (props: SvgProps) => (
  <Svg width="22" height="23" viewBox="0 0 22 23" fill="none" {...props}>
    <Path d="M11 2.44765C16.1827 2.44765 20.3985 6.66348 20.3985 11.8462C20.3985 17.0289 16.1827 21.2447 11 21.2447C5.81729 21.2447 1.60146 17.0289 1.60146 11.8462C1.60146 6.66348 5.81729 2.44765 11 2.44765ZM11 0.846191C4.92448 0.846191 0 5.77067 0 11.8462C0 17.9217 4.92448 22.8462 11 22.8462C17.0755 22.8462 22 17.9217 22 11.8462C22 5.77067 17.0735 0.846191 11 0.846191Z" fill={props.color ? props.color : "black"} />
    <Path d="M11.8047 9.79846H10.2033V17.4694H11.8047V9.79846Z" fill={props.color ? props.color : "black"} />
    <Path d="M11 8.30504C11.5749 8.30504 12.0409 7.83899 12.0409 7.26409C12.0409 6.68919 11.5749 6.22314 11 6.22314C10.4251 6.22314 9.95905 6.68919 9.95905 7.26409C9.95905 7.83899 10.4251 8.30504 11 8.30504Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const InfoSolid = (props: SvgProps) => (
  <Svg width="22" height="23" viewBox="0 0 22 23" fill="none" {...props}>
    <Path d="M11 0.851074C4.92448 0.851074 0 5.77555 0 11.8511C0 17.9266 4.92448 22.8511 11 22.8511C17.0755 22.8511 22 17.9266 22 11.8511C22 5.77555 17.0735 0.851074 11 0.851074ZM11.8047 17.4742H10.2033V9.80321H11.8047V17.4742ZM11 8.30985C10.4255 8.30985 9.95905 7.84343 9.95905 7.26891C9.95905 6.69439 10.4255 6.22796 11 6.22796C11.5745 6.22796 12.0409 6.69439 12.0409 7.26891C12.0409 7.84343 11.5745 8.30985 11 8.30985Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const LocationOutline = (props: SvgProps) => (
  <Svg width="18" height="23" viewBox="0 0 18 23" fill="none" {...props}>
    <Path d="M8.62657 1.67425C12.4606 1.67425 15.5789 4.79254 15.5789 8.62657C15.5789 10.845 15.0348 11.6674 14.1307 13.0278L14.0051 13.2182L8.62657 20.2459L3.24804 13.2182L3.12247 13.0278C2.21838 11.6674 1.67425 10.845 1.67425 8.62657C1.67425 4.79254 4.79254 1.67425 8.62657 1.67425ZM8.62657 12.425C10.665 12.425 12.3225 10.7675 12.3225 8.72912C12.3225 6.69072 10.665 5.03321 8.62657 5.03321C6.58817 5.03321 4.93066 6.69072 4.93066 8.72912C4.93066 10.7675 6.58817 12.425 8.62657 12.425ZM8.62657 0C3.86333 0 0 3.86124 0 8.62657C0 11.5 0.866424 12.649 1.88353 14.1893L8.62657 23L15.3696 14.1893C16.3867 12.649 17.2531 11.5 17.2531 8.62657C17.2531 3.86124 13.3898 0 8.62657 0ZM8.62657 10.7508C7.5111 10.7508 6.60491 9.84668 6.60491 8.72912C6.60491 7.61156 7.50901 6.70746 8.62657 6.70746C9.74413 6.70746 10.6482 7.61156 10.6482 8.72912C10.6482 9.84668 9.74204 10.7508 8.62657 10.7508Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const LocationSolid = (props: SvgProps) => (
  <Svg width="18" height="23" viewBox="0 0 18 23" fill="none" {...props}>
    <Path d="M8.62657 0C3.86333 0 0 3.86124 0 8.62657C0 11.5 0.866424 12.649 1.88353 14.1893L8.62657 23L15.3696 14.1893C16.3867 12.649 17.2531 11.5 17.2531 8.62657C17.2531 3.86124 13.3898 0 8.62657 0ZM8.62657 11.703C6.98371 11.703 5.65059 10.372 5.65059 8.72912C5.65059 7.08626 6.98371 5.75314 8.62657 5.75314C10.2694 5.75314 11.6025 7.08626 11.6025 8.72912C11.6025 10.372 10.2694 11.703 8.62657 11.703Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const LogInOutline = (props: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path d="M11 0C5.74922 0 1.36323 3.67934 0.266235 8.59782H1.91373C2.9787 4.57616 6.64804 1.60146 11 1.60146C16.1827 1.60146 20.3985 5.81729 20.3985 11C20.3985 16.1827 16.1827 20.3985 11 20.3985C6.64804 20.3985 2.9787 17.4238 1.91373 13.4022H0.266235C1.36323 18.3207 5.74922 22 11 22C17.0755 22 22 17.0755 22 11C22 4.92448 17.0735 0 11 0Z" fill={props.color ? props.color : "black"}/>
    <Path d="M9.3485 5.27888L13.8186 10.1994H0V11.8008H13.8186L9.3485 16.7193L10.5356 17.7963L16.7092 11.0001L10.5356 4.2019L9.3485 5.27888Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)

export const LogInSolid = (props: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path d="M10.968 0C5.16269 0 0.410373 4.49809 0 10.1993H13.7865L9.31647 5.2788L10.5015 4.20182L16.6752 11L10.5035 17.7962L9.31847 16.7192L13.7865 11.7987H0C0.410373 17.4999 5.16269 21.998 10.968 21.998C17.0435 21.998 21.968 17.0735 21.968 10.998C21.968 4.92247 17.0415 0 10.968 0Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)

export const LogOutOutline = (props: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path d="M11 22C16.2488 22 20.6368 18.3207 21.7338 13.4022H20.0863C19.0213 17.4238 15.352 20.3985 11 20.3985C5.81729 20.3985 1.60146 16.1827 1.60146 11C1.60146 5.81729 5.81729 1.60146 11 1.60146C15.352 1.60146 19.0213 4.57616 20.0863 8.59782H21.7338C20.6368 3.67934 16.2488 0 11 0C4.92448 0 0 4.92448 0 11C0 17.0755 4.92448 22 11 22Z" fill={props.color ? props.color : "black"}/>
    <Path d="M12.6495 16.7193L8.18146 11.8008H22V10.1994H8.18146L12.6495 5.27888L11.4644 4.2019L5.29083 11.0001L11.4644 17.7963L12.6495 16.7193Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)

export const LogOutSolid = (props: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path d="M11 22C16.8053 22 21.5576 17.5019 21.968 11.8007H8.18144L12.6495 16.7212L11.4644 17.7982L5.29081 11L11.4644 4.20182L12.6495 5.2788L8.18144 10.1993H21.968C21.5556 4.49809 16.8053 0 11 0C4.92448 0 0 4.92448 0 11C0 17.0755 4.92448 22 11 22Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)

export const OfficeOutline = (props: SvgProps) => (
  <Svg width="27" height="23" viewBox="0 0 27 23" fill="none" {...props}>
    <Path d="M18.1899 10.2473H8.99115V11.7392H18.1899V10.2473Z" fill={props.color ? props.color : "black"}/>
    <Path d="M18.1899 6.62354H8.99115V8.11547H18.1899V6.62354Z" fill={props.color ? props.color : "black"}/>
    <Path d="M18.1899 3H8.99115V4.49193H18.1899V3Z" fill={props.color ? props.color : "black"}/>
    <Path d="M21.6004 7.64615V0H5.39965V7.64615H0V23H6.8192V22.9907H11.2447V15.3072H15.9345V22.9907H20.179V23H26.9982V7.64615H21.6004ZM1.41956 9.13808H5.39965V21.5081H1.41956V9.13808ZM17.3559 21.5006V15.3091V13.8172H15.9363H11.2447H9.82512V15.3091V21.5006H6.8192V7.64615V1.49193H20.1808V7.64615V21.4987H17.3559V21.5006ZM25.5804 21.5081H21.6004V9.13808H25.5804V21.5081Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)

export const OfficeSolid = (props: SvgProps) => (
  <Svg width="28" height="22" viewBox="0 0 28 22" fill="none" {...props}>
    <Path d="M5.01056 15.3521V21.9661H13.8706V18.0465H19.1614V21.9661H27.2788V15.3521H5.01056Z" fill={props.color ? props.color : "black"} />
    <Path d="M0 21.9999H3.4091V13.7504H27.2788V5.50293H0V21.9999Z" fill={props.color ? props.color : "black"} />
    <Path d="M22.8588 0H4.64423V3.90155H22.8588V0Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const OfficeOutline2 = (props: SvgProps) => (
  <Svg width="21" height="22" viewBox="0 0 21 22" fill="none" {...props}>
    <Path d="M5.25149 0V5.5035H0V22H11.0968L10.8982 13.7518H15.3533L15.5519 22H21V0H5.25149ZM5.25149 20.3984H1.58895V7.10511H5.25149V20.3984ZM19.411 20.3984H16.9422V12.1502H9.30928V20.3984H6.84044V1.6016H19.411V20.3984Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)

export const OfficeOutline3 = (props: SvgProps) => (
  <Svg width="15" height="23" viewBox="0 0 15 23" fill="none" {...props}>
    <Path d="M13.4868 1.49242V21.5076H11.2301V18.0676V16.5751H9.7169H5.47415H3.96091V18.0676V21.5057H1.51324V1.49242H13.4868ZM15 0H0V23H5.47415V18.0676H9.7169V22.9981H15V0Z" fill={props.color ? props.color : "black"}/>
    <Path d="M6.81719 10.0627H2.6936V11.5552H6.81719V10.0627Z" fill={props.color ? props.color : "black"}/>
    <Path d="M6.81719 13.6426H2.6936V15.135H6.81719V13.6426Z" fill={props.color ? props.color : "black"}/>
    <Path d="M6.81719 6.48462H2.6936V7.97704H6.81719V6.48462Z" fill={props.color ? props.color : "black"}/>
    <Path d="M6.81719 2.90454H2.6936V4.39696H6.81719V2.90454Z" fill={props.color ? props.color : "black"}/>
    <Path d="M12.4993 10.0627H8.37573V11.5552H12.4993V10.0627Z" fill={props.color ? props.color : "black"}/>
    <Path d="M12.4993 13.6426H8.37573V15.135H12.4993V13.6426Z" fill={props.color ? props.color : "black"}/>
    <Path d="M12.4993 6.48462H8.37573V7.97704H12.4993V6.48462Z" fill={props.color ? props.color : "black"}/>
    <Path d="M12.4993 2.90454H8.37573V4.39696H12.4993V2.90454Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)

export const MutaborIcon = (props: SvgProps) => (
  <Svg width={44} height={44} viewBox="0 0 44 44" {...props}>
    <Path
      d="M21.913 2.42C10.926 2.42 2.02 11.329 2.02 22.315c0 10.987 8.906 19.894 19.893 19.894S41.807 33.3 41.807 22.314A19.893 19.893 0 0021.913 2.421zm0 41.38a21.5 21.5 0 1121.5-21.486c-.015 11.866-9.634 21.48-21.5 21.487zm8.413-13.06h-4.068V20.642l-4.345 2.554-4.37-2.554V30.74h-4.03V13.296l8.4 5.016 8.413-5.016V30.74z"
      fill={props.color}
      fillRule="nonzero"
    />
  </Svg>
);

export const Mutabor = (props: SvgProps) => (
  <Svg viewBox="0 0 191 27" width={191} height={27} {...props}>
      <Path
        d="M11.9 8L0 1v24.6h5.7V11.3l6.1 3.5 6.1-3.5v14.2h5.7V1L11.9 8zm170.9 1.8c0-1.9-1.2-3.1-3.2-3.1h-3.3v6.4h3.4c1.4.1 3.1-.9 3.1-3.3zm1.4 15.8l-4.2-7.3h-3.7v7.3h-6V1.7h10.9c4.9 0 7.6 4.4 7.6 8.1 0 3.6-2.1 5.5-3.4 6.6l5.3 9.2h-6.5zM72.4 7.3v18.2h-6.1V7.3H59V1.6h20.6v5.7h-7.2zm91.3 6.3c0 7.1-5.7 12.7-12.8 12.7-7 0-12.8-5.7-12.8-12.7 0-7.1 5.7-12.8 12.8-12.8 7.1 0 12.8 5.7 12.8 12.8zm-6.1-.1c-.1-3.7-3.1-6.7-6.8-6.7-3.7.1-6.6 3.1-6.5 6.9.1 3.7 3.1 6.7 6.8 6.7 3.7-.1 6.6-3.2 6.5-6.9zm-25.2 5.6c0 3.6-2.4 6.6-7.7 6.4h-11.9V1.7h11.1c4.4 0 7.5 2.6 7.5 6.1 0 2.5-1 4.5-3.9 5.2 3 .5 4.9 3.4 4.9 6.1zM125 8.7c0-1.3-.9-2.2-2.3-2.2h-4.1V11h4.1c1.4 0 2.3-.9 2.3-2.3zm1 9.5c0-1.6-1-2.6-2.7-2.6h-4.8v5h4.9c1.6.1 2.6-.8 2.6-2.4zm-25.6 7.4L98.7 22h-9.5l-1.7 3.6h-6.6L93.9.4l13 25.2h-6.5zm-6.5-14.4L91 17.5h5.7l-2.8-6.3zm-41.5 5c0 6.3-4 9.7-10.2 9.7-6.1 0-10.1-3.3-10.1-9.7V1.7H38v15.2c0 2.3 2 3.8 4.3 3.8 2.2 0 4.2-1.5 4.2-3.8V1.7h5.9v14.5z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color ? props.color : "#000000"}
      />
  </Svg>
)

export const NewsOutline = (props: SvgProps) => (
  <Svg width="27" height="20" viewBox="0 0 27 20" fill="none" {...props}>
    <Path d="M26.8863 8.06189H22.7316V9.51776H26.8863V8.06189Z" fill={props.color ? props.color : "black"} />
    <Path d="M25.7845 3.25451L21.8803 4.67542L22.3782 6.04349L26.2824 4.62259L25.7845 3.25451Z" fill={props.color ? props.color : "black"} />
    <Path d="M22.378 11.5372L21.8801 12.9053L25.7842 14.3262L26.2821 12.9581L22.378 11.5372Z" fill={props.color ? props.color : "black"} />
    <Path d="M16.9136 0L9.61965 5.09918L0 5.09372V12.4859L4.80983 12.4841V20H9.61965V12.4804L16.9136 17.5796H19.2411V0H16.9136ZM8.16379 18.5441H6.2657V12.4841L8.16379 12.4823V18.5441ZM17.7853 16.1237H17.3722L10.455 11.2866L10.0783 11.0227H9.61965L1.45587 11.03V6.55141L9.61965 6.55505H10.0783L10.455 6.29117L17.3722 1.45587H17.7853V16.1237V16.1237Z" fill={props.color ? props.color : "black"} />
  </Svg>
);

export const NewsSolid = (props: SvgProps) => (
  <Svg width="27" height="20" viewBox="0 0 27 20" fill="none" {...props}>
    <Path d="M9.09917 13.9346H5.33029V20.0001H9.09917V13.9346Z" fill={props.color ? props.color : "black"} />
    <Path d="M16.9136 17.5796L9.61965 12.4804L0 12.4859V5.09372L9.61965 5.09918L16.9136 0H19.2411V17.5796H16.9136Z" fill={props.color ? props.color : "black"} />
    <Path d="M26.8863 8.06189H22.7316V9.51776H26.8863V8.06189Z" fill={props.color ? props.color : "black"} />
    <Path d="M25.7845 3.25451L21.8803 4.67542L22.3782 6.04349L26.2824 4.62259L25.7845 3.25451Z" fill={props.color ? props.color : "black"} />
    <Path d="M22.378 11.5372L21.8801 12.9053L25.7842 14.3262L26.2822 12.9581L22.378 11.5372Z" fill={props.color ? props.color : "black"} />
  </Svg>
);

export const PeopleOutline = (props: SvgProps) => (
  <Svg width="31" height="23" viewBox="0 0 31 23" fill="none" {...props}>
    <Path d="M8.1116 6.99565C9.44546 6.99565 10.5297 8.09553 10.5297 9.4486C10.5297 10.8017 9.44546 11.9015 8.1116 11.9015C6.77774 11.9015 5.69346 10.8017 5.69346 9.4486C5.69346 8.09553 6.77774 6.99565 8.1116 6.99565ZM8.1116 5.33545C5.87145 5.33545 4.05682 7.17827 4.05682 9.4486C4.05682 11.7189 5.87349 13.5617 8.1116 13.5617C10.3497 13.5617 12.1664 11.7189 12.1664 9.4486C12.1664 7.17827 10.3517 5.33545 8.1116 5.33545Z" fill={props.color ? props.color : "black"} />
    <Path d="M20.1838 1.6602C22.2624 1.6602 23.9542 3.37643 23.9542 5.48489C23.9542 7.59334 22.2624 9.30957 20.1838 9.30957C18.1053 9.30957 16.4134 7.59542 16.4134 5.48489C16.4134 3.37436 18.1053 1.6602 20.1838 1.6602ZM20.1838 0C17.197 0 14.7768 2.45502 14.7768 5.48489C14.7768 8.51475 17.197 10.9698 20.1838 10.9698C23.1707 10.9698 25.5909 8.51475 25.5909 5.48489C25.5909 2.45502 23.1707 0 20.1838 0Z" fill={props.color ? props.color : "black"} />
    <Path d="M20.1839 13.6905C24.6867 13.6905 28.4428 16.9963 29.2161 21.3398H11.1517C11.927 16.9963 15.6811 13.6905 20.1839 13.6905ZM20.1839 12.0303C14.2101 12.0303 9.36978 16.9424 9.36978 23H31C31 16.9424 26.1576 12.0303 20.1839 12.0303Z" fill={props.color ? props.color : "black"} />
    <Path d="M1.84531 21.3398C2.57157 18.5195 5.10632 16.4318 8.10955 16.4318C9.16314 16.4318 10.1594 16.6891 11.0391 17.1436C11.3358 16.6684 11.6672 16.2181 12.0293 15.7947C10.8673 15.1431 9.53138 14.7695 8.10955 14.7695C3.63129 14.7716 0 18.4552 0 23H9.36976C9.36976 22.4355 9.41272 21.8814 9.49251 21.3398H1.84531Z" fill={props.color ? props.color : "black"} />
  </Svg>
);

export const PeopleSolid = (props: SvgProps) => (
  <Svg width="31" height="23" viewBox="0 0 31 23" fill="none" {...props}>
    <Path d="M8.1116 13.5617C10.351 13.5617 12.1664 11.7202 12.1664 9.4486C12.1664 7.17697 10.351 5.33545 8.1116 5.33545C5.87221 5.33545 4.05682 7.17697 4.05682 9.4486C4.05682 11.7202 5.87221 13.5617 8.1116 13.5617Z" fill={props.color ? props.color : "black"} />
    <Path d="M20.1839 10.9698C23.1701 10.9698 25.5909 8.51411 25.5909 5.48489C25.5909 2.45567 23.1701 0 20.1839 0C17.1976 0 14.7768 2.45567 14.7768 5.48489C14.7768 8.51411 17.1976 10.9698 20.1839 10.9698Z" fill={props.color ? props.color : "black"} />
    <Path d="M9.36978 23C9.36978 16.9403 14.2122 12.0303 20.1839 12.0303C26.1556 12.0303 30.998 16.9424 30.998 23H9.36978Z" fill={props.color ? props.color : "black"} />
    <Path d="M10.4602 15.1244C9.7155 14.8961 8.92787 14.7716 8.1116 14.7716C3.63129 14.7716 0 18.4552 0 23H7.73312C7.73312 20.0241 8.75602 17.2847 10.4602 15.1244Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const ProfilOutline = (props: SvgProps) => (
  <Svg width="22" height="23" viewBox="0 0 22 23" fill="none" {...props}>
    <Path d="M10.749 1.63077C12.8171 1.63077 14.4987 3.31658 14.4987 5.38766C14.4987 7.45874 12.8171 9.14455 10.751 9.14455C8.68502 9.14455 7.0013 7.46078 7.0013 5.38766C7.0013 3.31658 8.68299 1.63077 10.749 1.63077ZM10.749 0C7.78012 0 5.37451 2.4115 5.37451 5.38766C5.37451 8.36382 7.78012 10.7753 10.749 10.7753C13.7179 10.7753 16.1255 8.36382 16.1255 5.38766C16.1255 2.4115 13.7179 0 10.749 0Z" fill={props.color ? props.color : "black"} />
    <Path d="M10.749 13.8555C15.2247 13.8555 18.9581 17.1028 19.7268 21.3693H1.77116C2.54185 17.1028 6.27532 13.8555 10.749 13.8555ZM10.749 12.2247C4.81325 12.2247 0 17.0498 0 23.0001H21.5C21.5 17.0498 16.6867 12.2247 10.749 12.2247Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const ProfilSolid = (props: SvgProps) => (
  <Svg width="22" height="23" viewBox="0 0 22 23" fill="none" {...props}>
    <Path d="M10.749 10.7753C13.7173 10.7753 16.1235 8.36319 16.1235 5.38766C16.1235 2.41214 13.7173 0 10.749 0C7.78075 0 5.37451 2.41214 5.37451 5.38766C5.37451 8.36319 7.78075 10.7753 10.749 10.7753Z" fill={props.color ? props.color : "black"} />
    <Path d="M10.749 12.2247C4.81325 12.2247 0 17.0498 0 23.0001H21.5C21.5 17.0498 16.6867 12.2247 10.749 12.2247Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const SearchOutline = (props: SvgProps) => (
  <Svg width="22" height="23" viewBox="0 0 22 23" fill="none" {...props}>
    <Path d="M12.6841 0.846191C7.97834 0.846191 4.161 4.66158 4.161 9.36931C4.161 11.5855 5.00648 13.6045 6.39478 15.1197L0 21.7625L1.1247 22.8462L7.55072 16.1722C8.97808 17.252 10.7569 17.8924 12.6841 17.8924C17.3919 17.8924 21.2072 14.077 21.2072 9.36931C21.2072 4.66158 17.3919 0.846191 12.6841 0.846191ZM12.6841 16.3303C8.8453 16.3303 5.72309 13.2081 5.72309 9.36931C5.72309 5.53049 8.84725 2.40828 12.6841 2.40828C16.521 2.40828 19.6452 5.53049 19.6452 9.36931C19.6452 13.2081 16.5229 16.3303 12.6841 16.3303Z" fill={props.color ? props.color : "black"} />
  </Svg>
);

export const SearchSolid = (props: SvgProps) => (
  <Svg width="22" height="23" viewBox="0 0 22 23" fill="none" {...props}>
    <Path d="M12.6841 0.851074C7.97834 0.851074 4.161 4.66646 4.161 9.3742C4.161 11.5904 5.00648 13.6094 6.39478 15.1246L0 21.7674L1.1247 22.8511L7.55072 16.1771C8.97808 17.2569 10.7569 17.8973 12.6841 17.8973C17.3919 17.8973 21.2072 14.0819 21.2072 9.3742C21.2072 4.66646 17.3919 0.851074 12.6841 0.851074Z" fill={props.color ? props.color : "black"} />
  </Svg>
);

export const SwitchOutline = (props: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path d="M16.0763 1.60058C18.0949 1.60058 19.738 3.22917 19.738 5.2299C19.738 7.23063 18.0949 8.85922 16.0763 8.85922H5.27667C3.25805 8.85922 1.61489 7.23063 1.61489 5.2299C1.61489 3.22917 3.25805 1.60058 5.27667 1.60058H16.0763ZM16.0763 0H5.27667C2.36178 0 0 2.34085 0 5.2299C0 8.11895 2.36178 10.4598 5.27667 10.4598H16.0763C18.9912 10.4598 21.3529 8.11895 21.3529 5.2299C21.3529 2.34085 18.9891 0 16.0763 0Z" fill={props.color ? props.color : "black"}/>
    <Path d="M16.0763 13.1409C18.0949 13.1409 19.738 14.7695 19.738 16.7702C19.738 18.7709 18.0949 20.3995 16.0763 20.3995H5.27667C3.25805 20.3995 1.61489 18.7709 1.61489 16.7702C1.61489 14.7695 3.25805 13.1409 5.27667 13.1409H16.0763ZM16.0763 11.5403H5.27667C2.36178 11.5403 0 13.8811 0 16.7702C0 19.6592 2.36178 22.0001 5.27667 22.0001H16.0763C18.9912 22.0001 21.3529 19.6592 21.3529 16.7702C21.3529 13.8811 18.9891 11.5403 16.0763 11.5403Z" fill={props.color ? props.color : "black"}/>
    <Path d="M15.8139 15.4995C16.5204 15.4995 17.0957 16.0697 17.0957 16.77C17.0957 17.4702 16.5204 18.0404 15.8139 18.0404C15.1073 18.0404 14.5341 17.4702 14.5341 16.77C14.5341 16.0697 15.1073 15.4995 15.8139 15.4995ZM15.8139 13.8989C14.2151 13.8989 12.9192 15.1854 12.9192 16.77C12.9192 18.3545 14.2151 19.641 15.8159 19.641C17.4146 19.641 18.7126 18.3565 18.7126 16.77C18.7106 15.1854 17.4146 13.8989 15.8139 13.8989Z" fill={props.color ? props.color : "black"}/>
    <Path d="M5.54717 3.95947C6.25369 3.95947 6.82899 4.52968 6.82899 5.22993C6.82899 5.93018 6.25369 6.50039 5.54717 6.50039C4.84065 6.50039 4.26535 5.93018 4.26535 5.22993C4.26535 4.52968 4.84065 3.95947 5.54717 3.95947ZM5.54717 2.35889C3.94842 2.35889 2.65045 3.64335 2.65045 5.22993C2.65045 6.81651 3.9464 8.10097 5.54717 8.10097C7.14793 8.10097 8.44389 6.81651 8.44389 5.22993C8.44389 3.64335 7.14591 2.35889 5.54717 2.35889Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)

export const SwitchSolid = (props: SvgProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <Path d="M5.00982 10.0196H16.1519C18.9189 10.0196 21.1617 7.77683 21.1617 5.00982C21.1637 2.24282 18.9209 0 16.1519 0H5.00982C2.24282 0 0 2.24282 0 5.00982C0 7.77683 2.24282 10.0196 5.00982 10.0196ZM5.69207 1.95471C7.38068 1.95471 8.74718 3.32321 8.74718 5.00982C8.74718 6.69644 7.37868 8.06493 5.69207 8.06493C4.00346 8.06493 2.63696 6.69644 2.63696 5.00982C2.63696 3.32321 4.00546 1.95471 5.69207 1.95471Z" fill={props.color ? props.color : "black"}/>
    <Path d="M16.1519 11.9805H5.00982C2.24282 11.9805 0 14.2233 0 16.9903C0 19.7573 2.24282 22.0001 5.00982 22.0001H16.1519C18.9189 22.0001 21.1617 19.7573 21.1617 16.9903C21.1637 14.2233 18.9209 11.9805 16.1519 11.9805ZM15.4736 20.0454C13.785 20.0454 12.4185 18.6769 12.4185 16.9903C12.4185 15.3017 13.787 13.9352 15.4736 13.9352C17.1622 13.9352 18.5287 15.3037 18.5287 16.9903C18.5307 18.6769 17.1622 20.0454 15.4736 20.0454Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)

export const Refresh = (props: SvgProps) => (
  <Svg width="23" height="22" viewBox="0 0 23 22" fill="none" {...props}>
    <Path d="M21.2333 1.42931L21.0271 5.72522C20.5287 4.81239 19.9001 3.96962 19.1494 3.21694C14.8595 -1.07296 7.87917 -1.07296 3.58927 3.21694C2.18199 4.62422 1.2011 6.35179 0.714661 8.25752H2.37617C2.82058 6.78618 3.6173 5.45297 4.7203 4.34997C8.38563 0.684635 14.3511 0.684635 18.0164 4.34997C18.7591 5.09264 19.3596 5.94141 19.81 6.86625L15.5942 7.06844L15.6703 8.66789L22.5045 8.33959L22.8328 1.50538L21.2333 1.42931Z" fill={props.color ? props.color : "black"}/>
    <Path d="M18.1125 17.65C14.4471 21.3153 8.48171 21.3153 4.81638 17.65C4.0737 16.9073 3.47316 16.0585 3.02275 15.1337L7.23858 14.9315L7.16251 13.332L0.328298 13.6603L0 20.4945L1.59945 20.5706L1.80564 16.2747C2.30409 17.1875 2.93267 18.0303 3.68335 18.783C7.97325 23.0729 14.9536 23.0729 19.2435 18.783C20.6508 17.3757 21.6317 15.6501 22.1161 13.7424H20.4546C20.0102 15.2137 19.2135 16.547 18.1125 17.65Z" fill={props.color ? props.color : "black"}/>
  </Svg>
)

export const VacationOutline = (props: SvgProps) => (
  <Svg width="30" height="26" viewBox="0 0 30 26" fill="none" {...props}>
    <Path d="M17.0757 26.0003H12.9243V10.6249L5.73657 17.739L5.44408 16.4014C4.78362 13.3917 5.65543 10.2979 7.77645 8.07289H0L0.749151 6.92206C2.48333 4.25425 5.4271 2.66252 8.62373 2.66252C8.76337 2.66252 8.90301 2.66626 9.04076 2.67186L6.84992 0.500994L8.20103 0.21142C10.5259 -0.287394 12.9752 0.112405 15 1.3118C17.0248 0.114273 19.4741 -0.287394 21.799 0.21142L23.1501 0.500994L20.9592 2.67C21.0989 2.66439 21.2385 2.66066 21.3763 2.66066C24.5729 2.66066 27.5167 4.25238 29.2508 6.9202L30 8.07102H22.2235C24.3446 10.2979 25.2164 13.3898 24.5559 16.3995L24.2634 17.7372L17.0757 10.6249V26.0003ZM14.4339 24.5057H15.5661V7.01547L23.2595 14.6322C23.318 12.5174 22.5066 10.4623 20.963 8.93227C20.5932 8.5661 20.1837 8.23542 19.7459 7.94772L17.6607 6.57831H27.0713C25.6032 5.04077 23.5596 4.15523 21.3763 4.15523C20.5535 4.15523 19.7421 4.28227 18.959 4.53261L15.9756 5.48914L20.0082 1.49676C18.391 1.45379 16.7719 1.9115 15.4208 2.81385L14.9981 3.09595L14.5754 2.81385C13.2243 1.9115 11.6033 1.45752 9.98805 1.49676L14.0206 5.48914L11.0391 4.53261C10.2579 4.28227 9.44458 4.15523 8.62184 4.15523C6.43855 4.15523 4.39678 5.04077 2.92678 6.57831H12.3374L10.2522 7.94772C9.81256 8.23542 9.40307 8.56796 9.0351 8.93227C7.4934 10.4623 6.68197 12.5174 6.74047 14.6322L14.4339 7.01547V24.5057V24.5057Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const VacationSolid = (props: SvgProps) => (
  <Svg width="29" height="26" viewBox="0 0 29 26" fill="none" {...props}>
    <Path d="M19.9978 6.77453H29C27.3704 4.34899 24.527 2.74094 21.2898 2.74094C20.3052 2.74094 19.3568 2.89097 18.4686 3.16603L21.5671 0.199992C19.1699 -0.298196 16.6039 0.132669 14.498 1.49259C12.3921 0.132669 9.82608 -0.296272 7.42884 0.199992L10.5274 3.16603C9.6392 2.89097 8.69076 2.73902 7.70614 2.73902C4.47298 2.74094 1.62964 4.34899 0 6.77453H9.00222C8.49986 7.09383 8.02363 7.46314 7.58155 7.88631C5.29282 10.0772 4.47097 13.1375 5.10996 15.9573L13.0934 8.31525V26.0019H15.9066V8.31333L23.89 15.9554C24.529 13.1375 23.7072 10.0753 21.4184 7.88439C20.9764 7.46314 20.5001 7.09191 19.9978 6.77453Z" fill={props.color ? props.color : "black"} />
  </Svg>
)

export const WikiOutline = (props: SvgProps) => (
  <Svg width="27" height="22" viewBox="0 0 27 22" fill="none" {...props}>
    <Path d="M22.8372 4.0923V1.84651V0L21.0306 0.619305L13.5 3.19757L5.96936 0.622565L4.16282 0.00488925V1.8514V4.0923H0V22H27V4.0923H22.8372ZM21.484 1.84651V17.836L14.1766 20.3442V4.3498L21.484 1.84651ZM5.51604 1.8514L12.8234 4.3498V20.3442L5.51604 17.8409V1.8514ZM1.35321 5.3961H4.16282V17.8409V18.7617L5.06271 19.0697L9.81249 20.6962H1.35321V5.3961ZM25.6468 20.6962H17.1824L21.9373 19.0648L22.8372 18.7568V17.8376V5.3961H25.6468V20.6962Z" fill={props.color ? props.color : "black"} />
  </Svg>
);

export const WikiSolid = (props: SvgProps) => (
  <Svg width="26" height="21" viewBox="0 0 26 21" fill="none" {...props}>
    <Path d="M3.87532 19.4601L2.95436 19.1625V18.1791V2.52661H0V21H8.63696L3.87532 19.4601Z" fill={props.color ? props.color : "black"} />
    <Path d="M23.0456 2.52661V18.1741V19.1575L22.1247 19.4567L17.3581 21H26V2.52661H23.0456Z" fill={props.color ? props.color : "black"} />
    <Path d="M12.339 2.60731L4.27475 0.00500488V18.1791L12.339 20.7865V2.60731Z" fill={props.color ? props.color : "black"} />
    <Path d="M13.6594 2.60735V20.7865L21.7252 18.1741V0L13.6594 2.60735Z" fill={props.color ? props.color : "black"} />
  </Svg>
);