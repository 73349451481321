import React, { Ref } from 'react'
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { stl } from '../template/Styles'
import { Arrow } from './Icons'
import Tag from './Tag'

interface Props {
    pfad: string[],
    onPress: (x: string) => void,
}

const Crums = ({ pfad, onPress }: Props, ref: Ref<ScrollView>) => {

    const color = useSelector((state: RootState) => state.color)

    return (
        <ScrollView ref={ref} horizontal={true} showsHorizontalScrollIndicator={false} style={{ maxHeight: 56, minHeight: 56, overflow: "visible" }} contentContainerStyle={{ paddingHorizontal: 20 }} >

            {pfad.map((crum, index) => {

                return (<View key={index} style={[styles.tileBox]}>

                    {crum !== "MutaborApp" && crum !== "Wiki" && <View style={[styles.crumBox]}><Arrow color={color.text} /></View>}

                    <Tag tag={crum === "MutaborApp" ? "Wiki" : crum} onPress={onPress} />

                </View>)
            })}
        </ScrollView>
    )
}

export default React.forwardRef(Crums)

const styles = StyleSheet.create({
    crumBox: {
        paddingVertical: 8,
        paddingHorizontal: 8,
        zIndex: 2,
    },
    tileBox: {
        flexDirection: "row",
        alignItems: "center"
    },
    crum: {
        textTransform: "uppercase",
        opacity: 0.7
    }
})
