import React from "react";
import { Mutabor, ProfilOutline, Refresh, Arrow } from "./Icons";
import { useSelector } from "react-redux";
import { TouchableOpacity, Text, View, StyleSheet } from "react-native";
import { stl } from "../template/Styles";
import { RootState } from "../store";

interface Props {
    handleBack?: () => void,
    back?: boolean,
    titel?: string,
    handleMenu?: () => void,
    refresh?: boolean
}

const TitelBar = ({ handleBack, back, titel, handleMenu, refresh }: Props) => {
    const color = useSelector((state: RootState) => state.color)

    return (
        <View style={styles.box}>
            {handleBack && back &&
                <TouchableOpacity onPress={() => handleBack()} style={{ position: "absolute", top: 18, left: 24 }}>
                    <Arrow color={color.text} style={{ transform: [{ rotate: "180deg" }] }} height={21} width={15} />
                </TouchableOpacity>
            }
         
            <Mutabor
                color={color.text}
                height={23}
                width={170}
                style={{
                    alignSelf: "center"
                }}

            />
    
            {handleMenu &&
                <TouchableOpacity
                    onPress={handleMenu}
                    style={{ position: "absolute", right: 24, alignSelf: "center" }}
                >

                    {refresh ? <Refresh color={color.text} /> :
                        <ProfilOutline
                            color={color.text}
                        //opacity={"0.7"}
                        />}
                </TouchableOpacity>
            }
        </View>
    )

}

export default TitelBar;

const styles = StyleSheet.create({
    box: {
        width: "100%",
        paddingTop: 16,
        paddingBottom: 16,
        flexDirection: "row",
        justifyContent: "space-around",
        //height: 56
    },
    titel: {
        paddingBottom: 16,
        maxWidth: 180,
    }
})
