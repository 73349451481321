import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { stl } from "../template/Styles";

interface Props {
  active?: boolean;
  tag: string;
  onPress: (str: string) => void;
  card?: boolean;
}

const Tag = ({ tag, active, onPress, card }: Props) => {
  const color = useSelector((state: RootState) => state.color);

  return (
    <Pressable
      onPress={() => onPress(tag)}
      style={({ pressed }) => [
        stl.shdw,
        {
          opacity: pressed ? 0.7 : 1,
          backgroundColor: active
            ? color.text
            : card
            ? color.background
            : color.card,
        },
        styles.tag,
      ]}
    >
      <Text
        style={[stl.tags, { color: active ? color.background : color.text }]}
      >
        {tag}
      </Text>
    </Pressable>
  );
};

export default Tag;

const styles = StyleSheet.create({
  tag: {
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 8,
    margin: 4,
  },
});
