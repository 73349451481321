import {
  BatchRequestContent,
  BatchRequestStep,
  BatchResponseContent,
  Client,
} from "@microsoft/microsoft-graph-client";
import { GraphAuthProvider } from "./GraphAuthProvider";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { Office, User } from "../App.types";
// Set the authProvider to an instance
// of GraphAuthProvider
const clientOptions = {
  authProvider: new GraphAuthProvider(),
};

// Initialize the client
const graphClient = Client.initWithMiddleware(clientOptions);

type ResponseGroup = {
  value: MicrosoftGraph.User[];
};

export default class GraphManager {
  static getEvents = async () => {
    // GET /me/events
    return graphClient
      .api("/users/app@mutabor.de/events")
      .orderby("createdDateTime DESC")
      .get();
  };

  static getEventIstances = async (
    id: string,
    startTime: string,
    endTime: string
  ) => {
    return graphClient
      .api(
        `/users/app@mutabor.de/events/${id}/instances?startDateTime=${startTime}&endDateTime=${endTime}`
      )
      .get();
  };

  static getMultipleEventIstances = async (
    arr: { id: string; startTime: string; endTime: string }[]
  ) => {
    let requests: BatchRequestStep[] = [];
    arr.forEach(({ id, startTime, endTime }, index) => {
      requests.push({
        id: `${index}`,
        request: new Request(
          `/users/app@mutabor.de/events/${id}/instances?startDateTime=${startTime}&endDateTime=${endTime}`,
          {
            method: "GET",
          }
        ),
      });
    });

    let batchRequestContent = new BatchRequestContent(requests);

    let content = await batchRequestContent.getContent();

    let batchResponse = await graphClient.api("/$batch").post(content);

    let batchResponseContent = new BatchResponseContent(batchResponse);
    const responses = batchResponseContent.getResponses();

    let events: MicrosoftGraph.Event[] = [];
    await Promise.all(
      Array.from(responses.values()).map(async (res) => {
        if (res.ok) {
          let event = await res.json();

          events = [...events, ...event.value];
        } else {
          // console.log(res);
        }
      })
    );

    return events;
  };

  static getUserAsync = async () => {
    // GET /me
    return graphClient.api("/me").version("beta").get();
  };

  static getAllPeople = async () => {
    let userRequestStep = {
      id: "1",
      request: new Request(
        "/users?$filter=givenName ge 'a' and givenName le 'l' and mail ge 'a'",
        {
          method: "GET",
        }
      ),
    };

    let userRequestStep2 = {
      id: "2",
      request: new Request("/users?$filter=givenName ge 'l' and mail ge 'a'", {
        method: "GET",
      }),
    };

    let batchRequestContent = new BatchRequestContent([
      userRequestStep,
      userRequestStep2,
    ]);

    let content = await batchRequestContent.getContent();

    let batchResponse = await graphClient
      .api("/$batch")
      .version("beta")
      .post(content);

    let batchResponseContent = new BatchResponseContent(batchResponse);
    const response1 = batchResponseContent.getResponseById("1");
    const response2 = batchResponseContent.getResponseById("2");
    let people1: MicrosoftGraph.User[] = [];
    let people2: MicrosoftGraph.User[] = [];

    if (response1.ok) {
      let data = await response1.json();
      people1 = data.value;
    }

    if (response2.ok) {
      let data = await response2.json();
      people2 = data.value;
    }

    return [...people1, ...people2];
  };

  // static getPeopleAsync1 = async () => {
  //   // GET /users bis l(mit gültiger Mail)
  //   return graphClient
  //     .api("/users")
  //     .filter("givenName ge 'a' and givenName le 'l' and mail ge 'a'")
  //     .version("beta")
  //     .get();
  // };

  // static getPeopleAsync2 = async () => {
  //   // GET /users ab l(mit gültiger Mail)
  //   return graphClient
  //     .api("/users")
  //     .filter("givenName ge 'l' and mail ge 'a'")
  //     .version("beta")
  //     .get();
  // };

  static getUserPhotoAsync = async () => {
    return graphClient.api("/me/photos/648x648/$value").version("beta").get();
  };

  static getUsersPhotoAsync = async (id: string, pixel = "648x648") => {
    return graphClient
      .api(`/users/${id}/photos/${pixel}/$value`)
      .version("beta")
      .get();
  };

  static getUsersProfile = async (id: string) => {
    return graphClient.api(`/users/${id}/profile`).version("beta").get();
  };

  static updateUser = async () => {
    try {
      // Get the signed-in user from Graph
      const user: MicrosoftGraph.User = await GraphManager.getUserAsync();

      // Update UI with display name and email

      const newUser: User = {
        name: user.displayName!,
        // Work/School accounts have email address in mail attribute
        // Personal accounts have it in userPrincipalName
        email: user.mail !== null ? user.mail! : user.userPrincipalName!,
        //userPhoto: userPhoto,
        id: user.id!,
        jobTitle: user.jobTitle !== null ? user.jobTitle! : null,
        department: user.department !== null ? user.department! : null,
        status: null,
      };

      return newUser;
      //this.props.updateUser(newUser);
    } catch (error) {
      // console.log(error);
      return null;
    }
  };

  static updatePeople = async () => {
    try {
      // Get the signed-in user from Graph
      // //alle Leute von a bis l
      // const people1: { value: MicrosoftGraph.User[] } =
      //   await GraphManager.getPeopleAsync1();
      // //alle Leute von ab l
      // const people2: { value: MicrosoftGraph.User[] } =
      //   await GraphManager.getPeopleAsync2();

      let people = await GraphManager.getAllPeople();

      // let people = [...people1.value, ...people2.value];

      people = people.filter(
        (person) =>
          "department" in person &&
          person.department != null &&
          person.department != "IT" &&
          "onPremisesDistinguishedName" in person &&
          !person.onPremisesDistinguishedName?.includes("Freelancer")
      );

      // people[1].photo = this.getUsersPhotoAsync(people[1].id)
      // people.map(person => {
      //   person.photo = this.getUsersPhotoAsync(person.id)
      // })

      return people;
      //this.props.updateUser(newUser);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  static setOffice = async (info: Office, id: string) => {
    const directoryObject = {
      "@odata.id": `https://graph.microsoft.com/v1.0/directoryObjects/${id}`,
    };
    let groupID = null;

    switch (info) {
      case "home":
        groupID = "122912da-3a06-4198-90d3-b71ebbef9128";
        break;

      case "office":
        groupID = "97eb40f4-b470-4762-a1e6-9d4a7318634b";
        break;

      case "reise":
        groupID = "8a4021b8-a550-475c-a3c9-5ed6bc78c9b5";
        break;

      case "urlaub":
        groupID = "a7b11634-684a-408a-a1f0-b17e2c5e1649";
        break;
    }

    if (groupID == null) return null;

    try {
      let deleteHome = {
        id: "1",
        request: new Request(
          `/groups/122912da-3a06-4198-90d3-b71ebbef9128/members/${id}/$ref`,
          {
            method: "DELETE",
          }
        ),
      };

      let deleteOffice = {
        id: "2",
        request: new Request(
          `/groups/97eb40f4-b470-4762-a1e6-9d4a7318634b/members/${id}/$ref`,
          {
            method: "DELETE",
          }
        ),
      };

      let deleteReise = {
        id: "3",
        request: new Request(
          `/groups/8a4021b8-a550-475c-a3c9-5ed6bc78c9b5/members/${id}/$ref`,
          {
            method: "DELETE",
          }
        ),
      };

      let deleteUrlaub = {
        id: "4",
        request: new Request(
          `/groups/a7b11634-684a-408a-a1f0-b17e2c5e1649/members/${id}/$ref`,
          {
            method: "DELETE",
          }
        ),
      };

      let addGroup = {
        id: "5",
        request: new Request(`/groups/${groupID}/members/$ref`, {
          method: "POST",
          body: JSON.stringify(directoryObject),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      };

      let batchRequestContent = new BatchRequestContent([
        deleteHome,
        deleteOffice,
        deleteReise,
        deleteUrlaub,
        addGroup,
      ]);

      let content = await batchRequestContent.getContent();

      let batchResponse = await graphClient.api("/$batch").post(content);

      let batchResponseContent = new BatchResponseContent(batchResponse);
      let responses = batchResponseContent.getResponses();
    } catch (e) {
      // console.log(e);
    }
  };

  static getGroups = async () => {
    try {
      let homeGroup = {
        id: "1",
        request: new Request(
          "/groups/122912da-3a06-4198-90d3-b71ebbef9128/members",
          {
            method: "GET",
          }
        ),
      };

      let officeGroup = {
        id: "2",
        request: new Request(
          "/groups/97eb40f4-b470-4762-a1e6-9d4a7318634b/members",
          {
            method: "GET",
          }
        ),
      };

      let reiseGroup = {
        id: "3",
        request: new Request(
          "/groups/8a4021b8-a550-475c-a3c9-5ed6bc78c9b5/members",
          {
            method: "GET",
          }
        ),
      };

      let urlaubGroup = {
        id: "4",
        request: new Request(
          "/groups/a7b11634-684a-408a-a1f0-b17e2c5e1649/members",
          {
            method: "GET",
          }
        ),
      };

      let batchRequestContent = new BatchRequestContent([
        homeGroup,
        officeGroup,
        reiseGroup,
        urlaubGroup,
      ]);

      let content = await batchRequestContent.getContent();

      let batchResponse = await graphClient
        .api("/$batch")
        // .version("beta")
        .post(content);

      let batchResponseContent = new BatchResponseContent(batchResponse);
      let home: string[];
      let office: string[];
      let reise: string[];
      let urlaub: string[];

      let homeResponse = batchResponseContent.getResponseById("1");
      if (homeResponse.ok) {
        let data: { value: User[] } = await homeResponse.json();
        // console.log(data);
        home = data.value.map((user) => user.id);
      } else {
        console.log(`Get home failed with status ${homeResponse.status}`);
        home = [];
      }

      let officeResponse = batchResponseContent.getResponseById("2");
      if (officeResponse.ok) {
        let data: { value: User[] } = await officeResponse.json();
        // console.log(data);
        office = data.value.map((user) => user.id);
      } else {
        console.log(`Get office failed with status ${officeResponse.status}`);
        office = [];
      }

      let reiseResponse = batchResponseContent.getResponseById("3");
      if (reiseResponse.ok) {
        let data: { value: User[] } = await reiseResponse.json();
        // console.log(data);
        reise = data.value.map((user) => user.id);
      } else {
        console.log(`Get reise failed with status ${reiseResponse.status}`);
        reise = [];
      }

      let urlaubResponse = batchResponseContent.getResponseById("4");
      if (urlaubResponse.ok) {
        let data: { value: User[] } = await urlaubResponse.json();
        // console.log(data);
        urlaub = data.value.map((user) => user.id);
      } else {
        console.log(`Get urlaub failed with status ${urlaubResponse.status}`);
        urlaub = [];
      }

      return {
        home,
        office,
        urlaub,
        reise,
      };

      // const home: ResponseGroup = await graphClient
      //   .api(`/groups/122912da-3a06-4198-90d3-b71ebbef9128/members`)
      //   .get();
      // const office: ResponseGroup = await graphClient
      //   .api(`/groups/97eb40f4-b470-4762-a1e6-9d4a7318634b/members/`)
      //   .get();
      // const reise: ResponseGroup = await graphClient
      //   .api(`/groups/8a4021b8-a550-475c-a3c9-5ed6bc78c9b5/members/`)
      //   .get();
      // const urlaub: ResponseGroup = await graphClient
      //   .api(`/groups/a7b11634-684a-408a-a1f0-b17e2c5e1649/members/`)
      //   .get();

      // return {
      //   home: home.value.map((user) => user.id),
      //   office: office.value.map((user) => user.id),
      //   reise: reise.value.map((user) => user.id),
      //   urlaub: urlaub.value.map((user) => user.id),
      // };
    } catch (e) {
      // console.log(e);
    }

    return null;
  };

  static checkGroups = async () => {
    const myGroup: { value: MicrosoftGraph.Group[] } = await graphClient
      .api("/me/memberOf")
      //.count(true)
      //.search("description:Group used in Mutabor App")
      //.filter("startswith(description, 'Group used in Mutabor App')")
      .get()
      .catch((e) => {
        // console.log(e);
      });

    return myGroup.value.filter((group) =>
      group.description?.includes("Group used in Mutabor App")
    );
  };
}

// Home Office Group ID 122912da-3a06-4198-90d3-b71ebbef9128
// KK Group ID 97eb40f4-b470-4762-a1e6-9d4a7318634b
// Dienstreise Group ID 8a4021b8-a550-475c-a3c9-5ed6bc78c9b5
// Urlaub Group ID a7b11634-684a-408a-a1f0-b17e2c5e1649

//api('/groups/{group-id}/members/$ref')
//	.post(directoryObject);

//6df66d02-dbc4-45bc-b39f-3ec1a452387a
