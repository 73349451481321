import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import { ConfluenceManager } from "../confluence/confluence";
import CloseButton from "./CloseButton";
import Overlay from "./Overlay";
import { stl } from "../template/Styles";
import { RootState } from "../store";
import CustomHtml from "./CustomHtml";
import { Content } from "../App.types";

interface Props {
  pageId: number;
  handleClose: () => void;
}

const WikiOverlay = ({ pageId, handleClose }: Props) => {
  const { width } = useWindowDimensions();
  const color = useSelector((state: RootState) => state.color);

  const [data, setData] = useState<Content | null>(null);

  useEffect(() => {
    getConfluence(pageId);
  }, [pageId]);

  const getConfluence = async (id: number) => {
    const result = await ConfluenceManager.getPage(id);

    if (result != null) {
      const html = result.body;

      setData({ content: html == "" ? null : html, title: result.title });
    }
  };

  return (
    <Overlay
      handleClose={handleClose}
      header={() => {
        return (
          <View>
            <View style={[styles.header, { backgroundColor: color.card }]}>
              <Text
                style={[stl.headlineL, { color: color.text, paddingRight: 44 }]}
              >
                {data != null ? data.title : "Loading"}
              </Text>
              <CloseButton
                onPress={handleClose}
                style={{ position: "absolute", right: 20 }}
              />
            </View>
          </View>
        );
      }}
    >
      {data == null && <ActivityIndicator size="large" color={color.text} />}
      {data != null && data.content == null && (
        <Text style={[stl.copyM, { color: color.text, paddingHorizontal: 24 }]}>
          Diese Seite ist leer
        </Text>
      )}
      {data != null && data.content != null && (
        <View style={{ width, paddingHorizontal: 24 }}>
          <CustomHtml
            source={data.content}
            contentWidth={width - 48}
            getConfluence={getConfluence}
          />

          <View style={{ height: 100 }} />
        </View>
      )}
    </Overlay>
  );
};

export default WikiOverlay;

const styles = StyleSheet.create({
  headline: {
    fontWeight: "bold",
    fontSize: 24,
    fontFamily: "FS_Elliot_Pro-Heavy",
    paddingLeft: 20,
    paddingRight: 64,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 10,
    minHeight: 64,
  },
});
