import {
  createAppContainer,
  createSwitchNavigator,
} from "react-navigation";
import SignInScreen from "./SignInScreen";
import AuthLoadingScreen from "./AuthLoadingScreen";
import TapNavigation from "./TapNavigator";

export default createAppContainer(
  createSwitchNavigator(
    {
      App: TapNavigation,
      Auth: SignInScreen,
      AuthLoading: AuthLoadingScreen,
    },
    {
      initialRouteName: "AuthLoading",
    }
  )
);
