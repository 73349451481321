import React from "react";
import { StyleSheet, Text, useWindowDimensions, View } from "react-native";
import Overlay from "./Overlay";
import CloseButton from "./CloseButton";
import Button from "./Button";
import { stl, tagsStyles } from "../template/Styles";
import { useSelector } from "react-redux";
import {
  LocationOutline,
  ClockOutline,
  CalenderOutline,
  ProfilOutline,
} from "./Icons";
import { TerminProp } from "../App.types";
import { RootState } from "../store";
import HTML from "react-native-render-html";
import * as Linking from "expo-linking";

interface Props {
  termin: TerminProp;
  handleClose: () => void;
}

const TerminDetailOverlay = ({ termin, handleClose }: Props) => {
  const { end, start, onlineMeeting, organizer, subject, location, body } =
    termin;
  const color = useSelector((state: RootState) => state.color);
  const { width } = useWindowDimensions();

  return (
    <Overlay
      handleClose={handleClose}
      header={() => {
        return (
          <View>
            <View style={[styles.header]}>
              {/* <Text style={[stl.headlineL, { color: color.text, maxWidth: width - 84 }]}>{news.title}</Text> */}
              <CloseButton onPress={handleClose} />
            </View>
          </View>
        );
      }}
      pos={"absolute"}
    >
      <View style={{ padding: 24, paddingTop: 100, flex: 1 }}>
        <Text style={[stl.copyM, { color: color.text }]}>
          {start.fromNow()}
        </Text>
        <Text style={[stl.headlineL, styles.headline, { color: color.text }]}>
          {subject}
        </Text>

        <View style={[styles.iconBox]}>
          <CalenderOutline color={color.text} height={16} width={16} />
          <Text
            style={[stl.copyS, styles.label, { color: color.text }]}
          >{`${start.format("D.M.YYYY")} – ${end.format("D.M.YYYY")}`}</Text>
        </View>

        <View style={[styles.iconBox]}>
          <ClockOutline color={color.text} height={16} width={16} />
          <Text
            style={[stl.copyS, styles.label, { color: color.text }]}
          >{`${start.format("H:mm")} – ${end.format("H:mm")}`}</Text>
        </View>

        {location != null && location.displayName != "" && (
          <View style={[styles.iconBox]}>
            <LocationOutline color={color.text} height={16} width={16} />
            <Text style={[stl.copyS, styles.label, { color: color.text }]}>
              {location.displayName}
            </Text>
          </View>
        )}

        {organizer != null && (
          <View style={[styles.iconBox]}>
            <ProfilOutline color={color.text} height={16} width={16} />
            <Text style={[stl.copyS, styles.label, { color: color.text }]}>
              Organisator:{" "}
              {organizer.emailAddress?.name != null
                ? organizer.emailAddress.name
                : organizer.emailAddress}
            </Text>
          </View>
        )}

        {onlineMeeting != null && typeof onlineMeeting.joinUrl == "string" && (
          <View style={{ paddingTop: 24, paddingBottom: 12 }}>
            <Button
              onPress={() => {
                if (typeof onlineMeeting.joinUrl == "string") {
                  Linking.openURL(onlineMeeting.joinUrl);
                }
              }}
              text="An online Meeting Teilnehmen"
            />
          </View>
        )}

        {body != null && body.content != null && (
          <View style={{ paddingTop: 24 }}>
            <HTML
              source={{
                html: body.content
                  .replace(/(<style[^>]*>)[^]*(<\/style>)/g, "")
                  .replace(
                    /font-family:[^;]*;/g,
                    "font-family: FS_Elliot_Pro-Light;"
                  ),
              }}
              tagsStyles={tagsStyles(color)}
              ignoredStyles={["color"]}
              contentWidth={width - 48}
            />
          </View>
        )}
      </View>
    </Overlay>
  );
};

export default TerminDetailOverlay;

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 10,
    minHeight: 64,
  },
  info: {
    paddingTop: 24,
    paddingBottom: 12,
  },
  headline: {
    paddingVertical: 12,
  },
  label: {
    paddingLeft: 12,
  },
  iconBox: {
    flexDirection: "row",
    paddingVertical: 12,
    alignItems: "center",
  },
  body: {
    paddingVertical: 24,
  },
});
