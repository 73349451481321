import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
  StatusBar,
  NativeScrollEvent,
  ScrollView,
  NativeSyntheticEvent,
} from "react-native";
import Overlay2 from "./Overlay2";
import CloseButton from "./CloseButton";
import { useSelector } from "react-redux";
import Week from "./Week";
import Termin from "./Termin";
import { Moment } from "moment";
import "moment/locale/de";
import { stl } from "../template/Styles";
import { RootState } from "../store";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import Constants from "expo-constants";
import Overlay3 from "./Overlay3";

interface Props {
  handleClose: () => void;
  today: Moment;
  setSecondOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  secondOverlay: boolean;
}

const StundenplanOverlay = ({
  handleClose,
  today,
  setSecondOverlay,
  secondOverlay,
}: Props) => {
  const color = useSelector((state: RootState) => state.color);
  const events = useSelector((state: RootState) => state.events);

  const { width } = useWindowDimensions();

  const [activeDay, setActiveDay] = useState(today.clone());
  const [sections, setSections] = useState([]);

  useEffect(() => {}, [events]);

  const animation = useSharedValue(0);
  const ref = useRef<ScrollView>(null!);

  useEffect(() => {
    toggleBackground();
  }, [secondOverlay]);

  const toggleBackground = () => {
    if (secondOverlay) {
      animation.value = withTiming(1, { duration: 300 });

      StatusBar.setBarStyle(
        color.background == "#ffffff" ? "light-content" : "dark-content",
        true
      );

      ref.current?.scrollTo({ x: 0, y: 110, animated: false });
    }

    if (!secondOverlay) {
      animation.value = withTiming(0, { duration: 300 });

      scrollToSection(activeDay);
      StatusBar.setBarStyle(
        color.background == "#ffffff" ? "dark-content" : "light-content",
        true
      );
    }
  };

  const getWeek = (date: Moment) => {
    const startOfWeek = date.startOf("week");
    const endOfWeek = startOfWeek.clone().endOf("week").subtract(2, "d");

    let days = [];
    let day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(day);
      day = day.clone().add(1, "d");
    }

    return days;
  };

  const [week, setWeek] = useState(getWeek(today));

  useEffect(() => {
    scrollToSection(today);
  }, []);

  const scrollToSection = (day: Moment) => {
    const sectionIndex = events.findIndex((data) =>
      data.title.isSame(day, "day")
    );

    if (sectionIndex >= 0) {
      let offset = 100;

      for (let index = 0; index < sectionIndex; index++) {
        const termin = events[index];

        offset += 48 + termin.data.length * 114;
      }

      if (offset == 100) {
        offset = 0;
      }

      ref.current?.scrollTo({ x: 0, y: offset, animated: true });

      setActiveDay(day);
      return true;
    }

    return false;
  };

  const onDay = (day: Moment) => {
    scrollToSection(day);
  };

  const onNextWeek = () => {
    const day = week[0].clone().add(1, "weeks");
    const nextWeek = getWeek(day);
    let i = 0;
    while (!scrollToSection(nextWeek[i]) && i < nextWeek.length) {
      i++;
    }

    setWeek(nextWeek);
  };

  const onLastWeek = () => {
    const day = week[0].clone().subtract(1, "weeks");
    const nextWeek = getWeek(day);
    let i = 0;
    while (!scrollToSection(nextWeek[i]) && i < nextWeek.length) {
      i++;
    }
    setWeek(nextWeek);
  };

  const currentMonth = () => {
    let month: string[] = [];
    week.map((day) => {
      if (!month.includes(day.format("MMMM"))) {
        month.push(day.format("MMMM"));
      }
    });

    return month.join("/");
  };

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (events.length == 0) {
      return;
    }
    const {
      contentOffset: { y },
    } = event.nativeEvent;
    let currentY = 70;
    let currentDay: Moment | undefined;
    for (let index = 0; index < events.length && currentY <= y; index++) {
      const section = events[index];
      currentY += 48 + section.data.length * 114;
      currentDay = section.title.clone();
    }

    if (currentDay && !currentDay.isSame(activeDay, "day")) {
      setActiveDay(currentDay);

      if (!week.some((day) => day.isSame(currentDay, "day"))) {
        const nextWeek = getWeek(currentDay);
        setWeek(nextWeek);
      }
    }
  };

  const scale = useAnimatedStyle(() => {
    return {
      transform: [{ scale: 1 - animation.value * 0.1 }],
      borderRadius: animation.value * 10,
    };
  });

  const opacity = useAnimatedStyle(() => {
    return {
      opacity: animation.value * 0.2,
    };
  });

  return (
    <Animated.View style={[styles.container, scale]}>
      <Overlay3
        ref={ref}
        handleClose={handleClose}
        header={() => {
          return (
            <View>
              <View style={[styles.header, { backgroundColor: color.card }]}>
                <Text
                  style={[
                    stl.headlineL,
                    styles.headline,
                    { color: color.text, maxWidth: width - 84 },
                  ]}
                >
                  Stundenplan
                </Text>
                <CloseButton onPress={handleClose} />
              </View>

              <View style={[styles.week, { backgroundColor: color.card }]}>
                <Week
                  week={week}
                  {...{
                    activeDay,
                    onNextWeek,
                    onLastWeek,
                    onDay,
                    today,
                    events,
                  }}
                  month={currentMonth()}
                />
              </View>
            </View>
          );
        }}
        onScroll={handleScroll}
      >
        <View
          style={{ backgroundColor: color.background, paddingHorizontal: 24 }}
        >
          {events.map((datum) => {
            return (
              <View key={datum.title.valueOf()}>
                <Text style={[stl.copyL, styles.day, { color: color.text }]}>
                  {datum.title.format("dddd, D. MMM YYYY")}
                </Text>
                {datum.data.map((termin) => (
                  <Termin
                    {...{ termin }}
                    key={termin.id}
                    setOverlay={setSecondOverlay}
                  />
                ))}
              </View>
            );
          })}
        </View>
      </Overlay3>

      {secondOverlay && (
        <Animated.View
          style={[
            opacity,
            {
              width: "100%",
              height: "100%",
              backgroundColor: color.text,
              position: "absolute",
              borderRadius: 10,
            },
          ]}
        />
      )}
    </Animated.View>
  );
};

export default StundenplanOverlay;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    opacity: 1,
    overflow: "hidden",
    alignItems: "center",
  },
  headline: {
    paddingRight: 20,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 10,
    minHeight: 64,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  week: {},
  day: {
    paddingVertical: 12,
  },
});
