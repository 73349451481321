import React from "react";
import {
  TextInput,
  TouchableOpacity,
  StyleSheet,
  View,
  Pressable,
} from "react-native";
import { CloseSolid, SearchOutline } from "./Icons";
import { Ionicons } from "@expo/vector-icons";
import { stl } from "../template/Styles";
import { useSelector } from "react-redux";
import { RootState } from "../store";

interface Props {
  text: string;
  placeholder: string;
  onChangeText: (text: string) => void;
}

const Search = ({ text, placeholder, onChangeText }: Props) => {
  const color = useSelector((state: RootState) => state.color);
  return (
    <View style={styles.box}>
      <View
        style={[
          stl.shdw,
          styles.searchBox,
          { backgroundColor: color.text == "#ffffff" ? color.text : "#191B21" },
        ]}
      >
        <View style={styles.searchIconBox}>
          <SearchOutline color={color.background} />
        </View>
        <TextInput
          style={[styles.searchInput, { color: color.background }]}
          placeholder={placeholder}
          placeholderTextColor={`${color.background}B0`}
          onChangeText={onChangeText}
          value={text}
          returnKeyType="search"
        />
        {text > "" && (
          <Pressable
            onPress={() => onChangeText("")}
            style={({ pressed }) => [
              styles.closeIconBox,
              { opacity: pressed ? 0.7 : 1 },
            ]}
          >
            <CloseSolid color={color.card} />
            {/* <Ionicons name="ios-close" size={44} color={color.background} /> */}
          </Pressable>
        )}
      </View>
    </View>
  );
};

export default Search;

const styles = StyleSheet.create({
  searchInput: {
    flex: 1,
    height: 44,
    fontFamily: "FS_Elliot_Pro-Light",
    width: "100%",
    borderRadius: 4,
    paddingLeft: 44,
    zIndex: 1,
  },
  box: {
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
  searchBox: {
    borderRadius: 4,
    height: 44,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  searchIconBox: {
    padding: 12,
    zIndex: 0,
    position: "absolute",
    left: 0,
  },
  closeIconBox: {
    padding: 12,
    zIndex: 2,
    position: "absolute",
    right: 0,
  },
});
