import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { Menu } from "../App.types";
import { RootState } from "../store";
import { stl } from "../template/Styles";

interface MenuProps {
  name: string;
  price: string;
}

const MenuItem = ({ name, price }: MenuProps) => {
  const color = useSelector((state: RootState) => state.color);
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingVertical: 8,
        flex: 1,
      }}
    >
      <Text style={[stl.copyM, { color: color.text }]}>{name}</Text>
      <Text style={[stl.copyM, { color: color.text }]}>{price}</Text>
    </View>
  );
};

interface Props {
  data: Menu;
}

const CafeMenu = ({ data }: Props) => {
  const color = useSelector((state: RootState) => state.color);
  const { title, items } = data;
  return (
    <View
      style={[
        styles.box,
        {
          // backgroundColor: color.background
        },
      ]}
    >
      <Text style={[stl.headlineM, { color: color.text }]}>{title}</Text>
      <View style={[styles.menu, {}]}>
        {items.map((item, index) => {
          return <MenuItem {...item} key={item.name} />;
        })}
      </View>
    </View>
  );
};

export default CafeMenu;

const styles = StyleSheet.create({
  box: {
    marginVertical: 12,
    // padding: 12,FS_Elliot_Pro-Light
    // borderRadius: 4
  },
  menu: {},
  title: {
    fontFamily: "FS_Elliot_Pro-Heavy",
    fontSize: 24,
    fontWeight: "900",
  },
  text: {
    fontFamily: "FS-Elliot-light",
    fontSize: 18,
    fontWeight: "300",
  },
});
