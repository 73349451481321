import { Moment } from 'moment'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { TerminData } from '../App.types'
import { RootState } from '../store'
import { stl } from '../template/Styles'
import Button from './Button'
import Termin from './Termin'

interface Props {
    today: Moment,
    todaysTermine: TerminData | null,
    onPress: () => void,
    setOverlay: React.Dispatch<React.SetStateAction<boolean>>
}

const TodaysTermine = ({ today, todaysTermine, onPress, setOverlay }: Props) => {

    const color = useSelector((state: RootState) => state.color)

    return (
        <View style={{ padding: 24, flex: 1 }}>
            <Text style={[stl.copyXL, styles.text, { color: color.text }]}>{today.format("dddd, D.M.YYYY")}</Text>


            {todaysTermine != null && <View style={[styles.termineBox, {}]}>
                {
                    todaysTermine.data.map((termin) => <View key={termin.id} style={[styles.termine, {}]}>
                        <Termin {...{ termin }} setOverlay={setOverlay} />
                    </View>)
                }
            </View>
            }
 

            {
                todaysTermine == null && <View style={[styles.emptyTermine, {}]}>
                    <Text style={[stl.copyM, { color: color.text }]}>Heute stehen keine Termine im Mutabor Stundenplan.</Text>
                </View>
            }


            <View style={{ alignSelf: "flex-start" }}>
                <Button onPress={onPress} text={"Ganzen Stundenplan anzeigen"} type={"secondary"} />
            </View>
        </View>
    )
}

export default TodaysTermine

const styles = StyleSheet.create({
    text: {
        paddingBottom: 12,
    },
    termine: {
        borderRadius: 4,
        // flex: 1,
        width: "100%"
    },
    termineBox: {
        alignSelf: "stretch",

        paddingBottom: 12
    },
    emptyTermine: {

        paddingBottom: 12
    },
})
