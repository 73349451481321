import moment from 'moment'
import React from 'react'
import { StyleSheet, Text, View, ScrollView } from 'react-native'
import { useSelector } from 'react-redux'
import { NewsType } from '../App.types'
import { RootState } from '../store'
import { stl } from '../template/Styles'
import Filter from './Filter'
import NewsCard from './NewsCard'
import Tag from './Tag'

interface Props {
    news: NewsType[]
    openNews: (n: NewsType) => void,
    filter: string[],
    handleFilter: (x: string) => void,
    maxHeight?: boolean
}

const News = ({ news, openNews, filter, handleFilter, maxHeight }: Props) => {

    const color = useSelector((state: RootState) => state.color)

    return (
        <View style={{ paddingVertical: 24, width: "100%" }}>
            <Text style={[stl.copyXL, styles.text, { color: color.text }]}>News</Text>

            <Filter
                activeFilters={filter}
                filters={[...Tags]}
                onPress={tag => handleFilter(tag)}
            />

            {filter.length > 0 && <View style={{paddingHorizontal: 20, flexDirection: "row"}}><Tag tag="Filter löschen" onPress={() => handleFilter("Filter löschen")}/></View>}

            <ScrollView style={[styles.newsBox, { maxHeight: maxHeight ? 250 : undefined }]} contentContainerStyle={{ width: "100%" }} >
                {news.map((message, index) =>
                    <NewsCard
                        key={message.title}
                        news={message}
                        onPress={() => openNews(message)}
                    />
                )}
                {news.length == 0 &&
                    <NewsCard
                        news={{
                            date: moment(),
                            title: "Keine News gefunden",
                            tags:[],
                            text: "",
                            image: {uri: "bla"}
                        }}
                        onPress={() => {}}
                    />
                }
            </ScrollView>
        </View>
    )
}

export default News

const styles = StyleSheet.create({
    text: {
        paddingBottom: 0,
        paddingHorizontal: 24
    },
    newsBox: {
        paddingVertical: 12,
        width: "100%",
        minHeight: 232,
        paddingHorizontal: 24
    }
})


const Tags = [
    "P&O",
    "Inter Company",
    "Management",
    "IT"
]

