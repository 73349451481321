export const AuthConfig = {
    appId: "37513143-f7d4-4860-a542-3d38a9c3d2cf",
    appScopes: [
        "openid",
        "offline_access",
        "profile",
        "User.Read",
        "User.ReadWrite",
        "User.Read.All",
        "Group.ReadWrite.All"
    ],
};

//Microsoft Graph Token
export const graphToken = "o9[m[ZMTecDlffErc20EkfPBWx8E=Xa.";

// FIGMA Client secret
// hklb862ROF8poN1jwbOJE7DtY6gEcI
export const FigmaConfig = {
    clientId: "HTfdxOcXJiHH8LtPHSVhFx",
    clientSecret: "hklb862ROF8poN1jwbOJE7DtY6gEcI",
};

// 42119-faa2e138-9290-45b0-95e0-246e6ddf8d28
export const figmaToken = "42119-faa2e138-9290-45b0-95e0-246e6ddf8d28";


//FaceApp Push WAZFZGAXTJ


//Confluence persönlicher Zugrifffs Tolen
export const confluenceToken = "MjkwNjEzOTYyNTAzOrt/6P+zAeCNsp2TpqiQQ+x2xrt3"