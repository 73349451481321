import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  useWindowDimensions,
  Text,
  ScrollView,
  Image,
  Platform,
} from "react-native";
import * as Images from "../assets/faces/index";
import { useSelector } from "react-redux";
import { callNumber, mailTo, teamsTo } from "../functions/mailPhoneTo";
import Overlay from "./Overlay";
import CloseButton from "./CloseButton";
import { stl } from "../template/Styles";
import { Person } from "../App.types";
import { RootState } from "../store";
import GraphManager from "../graph/GraphManager";
import Tag from "./Tag";
import AsyncStorage from "@react-native-async-storage/async-storage";

interface Props {
  person: Person;
  handleClose: () => void;
}

const PeopleOverlay = ({ person, handleClose }: Props) => {
  const color = useSelector((state: RootState) => state.color);
  const { height } = useWindowDimensions();

  const [token, setToken] = useState<null | string>(null);
  const [profile, setProfile] =
    useState<null | { skills: { displayName: string }[] }>(null);

  let image = `${person.givenName}_${person.surname}`;
  image = image
    .toLocaleLowerCase()
    .replace(" ", "_")
    .replace("ä", "ae")
    .replace("ü", "ue")
    .replace("ö", "oe");

  const [source, setSource] = useState(
    Images.default[image as keyof typeof Images.default]
  );

  useEffect(() => {
    if (Platform.OS === "web") {
      getImg();
    } else {
      getToken();
    }
    getProfile();
  }, []);

  const getImg = async () => {
    const Token = await getToken();
    const response = await fetch(
      `https://graph.microsoft.com/beta/users/${person.id}/photo/$value`,
      {
        headers: {
          Authorization: "Bearer " + Token,
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          return null;
        } else {
          return res.blob();
        }
      })
      .catch((e) => {});

    if (response === null) {
      return;
    }

    setSource(URL.createObjectURL(response));
  };

  const getToken = async () => {
    const accessToken = await AsyncStorage.getItem("userToken");
    if (accessToken) {
      setToken(accessToken);
    }
    return accessToken;
  };

  const getProfile = async () => {
    try {
      const profileData = await GraphManager.getUsersProfile(person.id!);
      setProfile(profileData);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <Overlay
      handleClose={handleClose}
      pos={"absolute"}
      header={() => {
        return (
          <View>
            <View
              style={[
                styles.header,
                {
                  width: "100%",
                  flex: 1,
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  paddingRight: 20,
                  paddingTop: 15,
                },
              ]}
            >
              {/* <Text style={[styles.headline, { color: color.text, maxWidth: width - 84 }]}>{news.title}</Text> */}
              <CloseButton onPress={handleClose} />
            </View>
          </View>
        );
      }}
    >
      <View style={styles.cover}>
        <View style={[stl.shdw, styles.imageBox]}>
          {Platform.OS != "web" && token !== null ? (
            <Image
              style={styles.image}
              defaultSource={
                Images.default[image as keyof typeof Images.default]
                  ? Images.default[image as keyof typeof Images.default]
                  : require("../assets/SplashScreen.png")
              }
              source={{
                uri: `https://graph.microsoft.com/beta/users/${person.id}/photo/$value`,
                headers: {
                  Authorization: "Bearer " + token,
                },
              }}
            />
          ) : (
            <Image
              style={styles.image}
              defaultSource={require("../assets/SplashScreen.png")}
              source={source}
            />
          )}
        </View>
      </View>

      {/* <CloseView style={{ backgroundColor: color.text }}>
             <CloseButton onPress={handleClose} />
          </CloseView> */}

      <View style={{ height, padding: 20 }}>
        <Text style={[stl.headlineL, { color: color.text }]}>
          {person.displayName}
        </Text>

        {person.jobTitle && (
          <>
            <Text
              style={[stl.copyS, styles.label, { color: `${color.text}66` }]}
            >
              Position
            </Text>
            <Text
              style={[stl.copyL, styles.text, { color: `${color.text}B3` }]}
            >
              {person.jobTitle}
            </Text>
          </>
        )}

        {person.businessPhones != null && (
          <>
            <Text
              style={[stl.copyS, styles.label, { color: `${color.text}66` }]}
            >
              Telefon
            </Text>
            <Text
              style={[stl.copyL, styles.text, { color: `${color.text}B3` }]}
              onPress={() => callNumber(person.businessPhones![0]!)}
            >
              {person.businessPhones}
            </Text>
          </>
        )}

        {person.mobilePhone && (
          <Text style={[stl.copyS, styles.label, { color: `${color.text}66` }]}>
            Mobil Telefon
          </Text>
        )}
        {person.mobilePhone && (
          <Text
            style={[stl.copyL, styles.text, { color: `${color.text}B3` }]}
            onPress={() => callNumber(person.mobilePhone!)}
          >
            {person.mobilePhone}
          </Text>
        )}

        {person.mail != null && (
          <>
            <Text
              style={[stl.copyS, styles.label, { color: `${color.text}66` }]}
            >
              E-Mail
            </Text>
            <Text
              style={[stl.copyL, styles.text, { color: `${color.text}B3` }]}
              onPress={() => mailTo(person.mail!)}
            >
              {person.mail}
            </Text>
          </>
        )}

        {person.userPrincipalName != null && (
          <>
            <Text
              style={[stl.copyS, styles.label, { color: `${color.text}66` }]}
            >
              Teams
            </Text>
            <Text
              style={[stl.copyL, styles.text, { color: `${color.text}B3` }]}
              onPress={() => teamsTo(person.userPrincipalName!)}
            >
              chat in Teams
            </Text>
          </>
        )}

        {profile?.skills != null && profile.skills.length > 0 && (
          <>
            <Text
              style={[stl.copyS, styles.label, { color: `${color.text}66` }]}
            >
              Skills
            </Text>
            <View style={{ height: 56 }}>
              <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                style={{ overflow: "visible" }}
                contentContainerStyle={[
                  { overflow: "visible", alignItems: "center" },
                ]}
              >
                {profile.skills.map((skill) => (
                  <Tag
                    key={skill.displayName}
                    tag={skill.displayName}
                    onPress={() => {}}
                    card={true}
                  />
                ))}
              </ScrollView>
            </View>
          </>
        )}
      </View>
    </Overlay>
  );
};

export default PeopleOverlay;

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    position: "absolute",
    height: "100%",
    width: "100%",
    maxWidth: 600,
    zIndex: 100,
    borderRadius: 10,
    overflow: "hidden",
  },
  cover: {
    padding: 20,
  },
  imageBox: {
    height: 160,
    width: 160,
  },
  label: {
    paddingTop: 12,
  },
  text: {
    paddingTop: 4,
  },
  header: {},
  image: {
    borderRadius: 4,
    height: "100%",
    width: "100%",
  },
});
