import React, { useRef, useState } from "react";
import {
  StyleSheet,
  View,
  useWindowDimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StatusBar,
} from "react-native";
import { useSelector } from "react-redux";
import Constants from "expo-constants";
import { RootState } from "../store";
import { stl } from "../template/Styles";

interface Props {
  handleClose: () => void;
  children: React.ReactNode;
  header: () => React.ReactNode;
  onScroll: (e: NativeSyntheticEvent<NativeScrollEvent>) => void;
}

const Overlay3 = (
  { handleClose, children, header, onScroll }: Props,
  ref: React.Ref<ScrollView>
) => {
  const { height, width } = useWindowDimensions();
  const color = useSelector((state: RootState) => state.color);
  const statusBarHeight = Constants.statusBarHeight;

  const [barStyle, setBarStyle] = useState(false);

  const changeStyle = (value: number) => {
    if (value >= 100 - statusBarHeight && !barStyle) {
      setBarStyle(true);
      StatusBar.setBarStyle(
        color.background == "#ffffff" ? "dark-content" : "light-content",
        true
      );
    } else if (value < 100 - statusBarHeight && barStyle) {
      setBarStyle(false);
      StatusBar.setBarStyle(
        color.background == "#ffffff" ? "light-content" : "dark-content",
        true
      );
    }
  };

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        height,
        width,
        overflow: "visible",
      }}
    >
      <ScrollView
        ref={ref}
        onScroll={(e) => {
          onScroll(e);
          changeStyle(e.nativeEvent.contentOffset.y);
        }}
        onScrollEndDrag={(event) => {
          let { nativeEvent } = event;
          if (nativeEvent.contentOffset.y < -150) {
            handleClose();
          }
        }}
        showsVerticalScrollIndicator={false}
        stickyHeaderIndices={[1]}
        snapToOffsets={[0, 100]}
        scrollEventThrottle={1}
        snapToEnd={false}
        style={{
          maxWidth: 1000,
          width,
          height,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          //marginTop: 100
        }}
      >
        <View
          style={[
            {
              height: 100,
              width: "100%",
            },
          ]}
        />

        {/* Optionaler Header */}
        <View
          style={[
            stl.shdw,
            styles.header,

            { backgroundColor: color.card, paddingTop: statusBarHeight },
          ]}
        >
          {header && header()}
        </View>

        {/* Content */}
        <View
          style={[
            // contentTransform,
            {
              zIndex: 1,
              overflow: "hidden",
              backgroundColor: color.card,
              minHeight: height,
            },
          ]}
        >
          {children}
          <View style={{ height: 110 + statusBarHeight }} />
        </View>
      </ScrollView>
    </View>
  );
};

export default React.forwardRef(Overlay3);

const styles = StyleSheet.create({
  header: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    //paddingTop: 10,
    width: "100%",
    zIndex: 3,
  },
});
