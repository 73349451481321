import React, { useState } from "react";
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import {
  AirplainOutline,
  HomeOutline,
  OfficeOutline,
  OfficeOutline2,
  OfficeOutline3,
  VacationOutline,
} from "./Icons";
import GraphManager from "../graph/GraphManager";
import { stl } from "../template/Styles";
import { RootState } from "../store";
import { Office, OfficeArray } from "../App.types";

interface Props {}

const CheckIn = () => {
  const color = useSelector((state: RootState) => state.color);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const user = useSelector((state: RootState) => state.user);

  const setOffice = async (info: Office | null) => {
    if (info != null) {
      setLoading(true);
      await GraphManager.setOffice(info, user.id);
      dispatch({
        type: "UPDATE_USER",
        payload: { ...user, status: info },
      });
      setLoading(false);
    }
  };

  return (
    <View style={{ padding: 24, paddingRight: 16, flex: 1 }}>
      <View style={{ flexDirection: "row" }}>
        <Text style={[stl.copyXL, { color: color.text, paddingRight: 12 }]}>
          Check In
        </Text>
        {loading && <ActivityIndicator size="small" />}
      </View>
      <View style={styles.buttonBox}>
        {OfficeArray.map((type, index) => {
          return (
            <Button
              key={index}
              type={type}
              active={type == user.status}
              onPress={() => setOffice(type == user.status ? null : type)}
            />
          );
        })}
      </View>
    </View>
  );
};

interface ButtonProps {
  type: Office;
  active: boolean;
  onPress: () => void;
}

const Button = ({ type, active, onPress }: ButtonProps) => {
  const color = useSelector((state: RootState) => state.color);
  const size = 24;
  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [
        styles.button,
        stl.shdw,
        {
          backgroundColor: active ? color.baseColor : color.card,
          opacity: pressed ? 0.7 : 1,
        },
      ]}
    >
      {type == "urlaub" && (
        <VacationOutline
          color={active ? "#fff" : color.text}
          width={size}
          height={size}
        />
      )}
      {type == "reise" && (
        <AirplainOutline
          color={active ? "#fff" : color.text}
          width={size}
          height={size}
        />
      )}
      {type == "office" && (
        <OfficeOutline
          color={active ? "#fff" : color.text}
          width={size}
          height={size}
        />
      )}
      {/* {type == "office" && <OfficeOutline2 color={active ? "#fff" : color.text} width={size} height={size} />} */}
      {/* {type == "office" && <OfficeOutline3 color={active ? "#fff" : color.text} width={size} height={size} />} */}
      {type == "home" && (
        <HomeOutline
          color={active ? "#fff" : color.text}
          width={size}
          height={size}
        />
      )}
    </Pressable>
  );
};

export default CheckIn;

const styles = StyleSheet.create({
  buttonBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 12,
  },
  button: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    marginRight: 8,
    borderRadius: 4,
    flex: 1,
    minWidth: 48,
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    fontWeight: "800",
    fontSize: 16,
    fontFamily: "FS_Elliot_Pro-Heavy",
    paddingLeft: 8,
  },
  headline: {
    fontWeight: "800",
    fontSize: 20,
    fontFamily: "FS_Elliot_Pro-Heavy",
  },
  text: {
    flex: 1,
    fontWeight: "300",
    fontFamily: "FS-Elliot-light",
    paddingHorizontal: 8,
  },
});
