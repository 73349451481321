// Adapted from https://reactnavigation.org/docs/auth-flow.html
import React, { useEffect } from "react";
import {
  ActivityIndicator,
  Text,
  StyleSheet,
  View,
  Platform,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Mutabor } from "../components/Icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { stl } from "../template/Styles";
import { _getFood, _updateUser, _getCalendar } from "../functions/loadApp";
import { NavigationStackScreenProps } from "react-navigation-stack";
import { RootState } from "../store";

const AuthLoadingScreen = ({ navigation }: NavigationStackScreenProps) => {
  const color = useSelector((state: RootState) => state.color);
  const dispatch = useDispatch();

  useEffect(() => {
    bootstrapAsync();
  }, []);

  // Fetch the token from storage then navigate to our appropriate place
  // NOTE: Production apps should protect user tokens in secure storage.
  //and set the Mode
  const bootstrapAsync = async () => {
    const mode = await AsyncStorage.getItem("mode");

    if (mode) {
      dispatch({
        type: mode == "light" ? "LIGHT_MODE" : "DARK_MODE",
      });
    }

    if (Platform.OS === "web") {
      const expireTime = await AsyncStorage.getItem("expireTime");

      if (expireTime !== null) {
        // Get expiration time - 5 minutes
        // If it's <= 5 minutes before expiration, then refresh
        const expire = moment(expireTime).subtract(5, "minutes");
        const now = moment();

        if (now.isBefore(expire)) {
          const events = await _getCalendar();
          dispatch({
            type: "UPDATE_EVENTS",
            payload: {
              events,
            },
          });

          const payload = await _updateUser();
          if (payload != null) {
            dispatch({
              type: "UPDATE_DATA",
              payload,
            });

            //Update FoodMenu
            await _getFood();
            navigation.navigate("App");
          } else {
            navigation.navigate("Auth");
          }
        } else {
          navigation.navigate("Auth");
        }
      } else {
        navigation.navigate("Auth");
      }
    } else {
      const userToken = await AsyncStorage.getItem("userToken");

      if (userToken) {
        const events = await _getCalendar();
        dispatch({
          type: "UPDATE_EVENTS",
          payload: {
            events,
          },
        });

        const payload = await _updateUser();
        if (payload != null) {
          dispatch({
            type: "UPDATE_DATA",
            payload,
          });

          //Update FoodMenu
          await _getFood();

          navigation.navigate("App");
        } else {
          navigation.navigate("Auth");
        }
      } else {
        // This will switch to the App screen or Auth screen and this loading
        // screen will be unmounted and thrown away.
        navigation.navigate("Auth");
      }
    }
  };

  return (
    <View style={[styles.container, { backgroundColor: color.background }]}>
      <View style={styles.logo}>
        <Mutabor
          color={color.text}
          // width="80"
          // height="80"
        />
      </View>
      <ActivityIndicator size="large" color={color.text} />
      <Text style={[stl.headlineM, { color: color.text }]}>Logging in...</Text>
    </View>
  );
};

export default AuthLoadingScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    padding: 24,
    paddingBottom: 80,
  },
});
