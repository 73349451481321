import React, { useEffect, useRef, useState } from "react";
import {
  ScrollView,
  Animated,
  View,
  Platform,
  StyleSheet,
  Vibration,
  StatusBar,
  ActivityIndicator,
  Modal,
  Alert,
  useWindowDimensions,
  RefreshControl,
} from "react-native";
import * as Notifications from "expo-notifications";
import Constants from "expo-constants";
import moment from "moment";
import "moment/locale/de";
import { ConfluenceManager } from "../confluence/confluence";
import { useDispatch, useSelector } from "react-redux";
import TitelBar from "../components/TitelBar";
import NewsOverlay from "../components/NewsOverlay";
import { SafeAreaView } from "react-navigation";
import CafeOverlay from "../components/CafeOverlay";
import StundenplanOverlay from "../components/StundenplanOverlay";
import CheckIn from "../components/CheckIn";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Menu from "../components/Menu";
import { CafeData, Foods, NewsType, TerminData } from "../App.types";
import { RootState } from "../store";
import TodaysTermine from "../components/TodaysTermine";
import FoodOfTheWeek from "../components/FoodOfTheWeek";
import News from "../components/News";
import {
  _getCalendar,
  _getFood,
  _getStatus,
  _getNews,
} from "../functions/loadApp";
import { AuthManager } from "../auth/AuthManager";

type Subscription = {
  remove: () => void;
};

interface Props {
  navigation: any;
}

const NewsScreen = ({ navigation }: Props) => {
  const [expoPushToken, setExpoPushToken] =
    useState<Notifications.ExpoPushToken | null>(null);
  const [news, setNews] = useState<NewsType[]>([]);
  const [filter, setFilter] = useState<string[]>([]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [secondOverlay, setSecondOverlay] = useState(false);
  const [currentNews, setCurrentNews] = useState<NewsType | null>(null);
  const [action, setAction] =
    useState<"news" | "cafe" | "plan" | "menu" | null>(null);

  const [cafeData, setCafeData] = useState<CafeData | null>(null);

  const scale = useRef(new Animated.Value(1)).current;
  const opacity = useRef(new Animated.Value(1)).current;
  const translateY = useRef(new Animated.Value(0)).current;

  const notificationListener = useRef<Subscription>(null!);
  const responseListener = useRef<Subscription>(null!);

  const color = useSelector((state: RootState) => state.color);
  const events = useSelector((state: RootState) => state.events);
  const dispatch = useDispatch();

  const { width } = useWindowDimensions();

  useEffect(() => {
    toggleBackground();
  }, [overlay]);

  const toggleBackground = () => {
    if (overlay) {
      Animated.timing(scale, {
        toValue: 0.9,
        duration: 300,
        //easing: Easing.in(),
        useNativeDriver: true,
      }).start();
      Animated.spring(opacity, {
        toValue: 0.6,
        useNativeDriver: true,
      }).start();

      StatusBar.setBarStyle(
        color.background == "#ffffff" ? "light-content" : "dark-content",
        true
      );
    }

    if (!overlay) {
      Animated.timing(scale, {
        toValue: 1,
        duration: 300,
        //easing: Easing.in(),
        useNativeDriver: true,
      }).start();
      Animated.spring(opacity, {
        toValue: 1,
        useNativeDriver: true,
      }).start();

      StatusBar.setBarStyle(
        color.background == "#ffffff" ? "dark-content" : "light-content",
        true
      );
    }
  };

  useEffect(() => {
    toggleCard();
  }, [secondOverlay]);

  const toggleCard = () => {
    if (secondOverlay) {
      Animated.spring(translateY, {
        toValue: 100,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.spring(translateY, {
        toValue: 0,
        useNativeDriver: true,
      }).start();
    }
  };

  useEffect(() => {
    const timer = () =>
      setInterval(
        () => (Platform.OS === "web" ? logOut() : onRefresh()),
        900000
      );
    const timerId = timer();
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const logOut = async () => {
    try {
      await AuthManager.signOutAsync();

      dispatch({
        type: "RESET_USER",
      });

      navigation.navigate("Auth");
    } catch (error) {
      alert(error);
    }
  };

  const registerForPushNotificationsAsync = async () => {
    if (Constants.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();

      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        alert("Failed to get push token for push notification!");
        return;
      }
      const token = await Notifications.getExpoPushTokenAsync();

      setConfluence(token);
      setExpoPushToken(token);
    } else {
      alert("Must use physical device for Push Notifications");
    }

    if (Platform.OS === "android") {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }
  };

  const setConfluence = async (token: Notifications.ExpoPushToken) => {
    await ConfluenceManager.setToken(token);
  };

  const getMenu = async () => {
    const valueJSON = await AsyncStorage.getItem("cafeMenu");
    const value = valueJSON != null ? JSON.parse(valueJSON) : null;

    if (value == null) {
      return;
    }
    value.foods.forEach((food: Foods) => {
      food.date = moment(food.date, "DD.MM.YYYY");
    });
    value.foods = value.foods.sort((a: Foods, b: Foods) => {
      if (a.date.isBefore(b.date)) {
        return -1;
      } else if (a.date.isAfter(b.date)) {
        return 1;
      } else {
        return 0;
      }
    });
    setCafeData(value);
  };

  useEffect(() => {
    setLoading(true);

    getMenu();
    getNews();

    if (Platform.OS !== "web") {
      registerForPushNotificationsAsync();

      // This listener is fired whenever a notification is received while the app is foregrounded
      notificationListener.current =
        Notifications.addNotificationReceivedListener((notification) => {
          Vibration.vibrate();
          Alert.alert(
            `${notification.request.content.title}`,
            `Möchtest du die News jetzt lesen?`,
            [
              {
                text: "Cancel",
                onPress: () => {},
                style: "cancel",
              },
              {
                text: "OK",
                onPress: () => {
                  handleNotification(notification);
                },
              },
            ]
          );

          //handleNotification(notification);
          //setNotification(notification);
        });

      // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
      responseListener.current =
        Notifications.addNotificationResponseReceivedListener((response) => {
          handleNotification(response.notification);
        });

      return () => {
        Notifications.removeNotificationSubscription(
          notificationListener.current
        );
        Notifications.removeNotificationSubscription(responseListener.current);
      };
    }
  }, []);

  const getNews = async () => {
    const newNews = await _getNews();
    setNews(newNews);
    setLoading(false);
  };

  const handleNotification = async (
    notification: Notifications.Notification
  ) => {
    setLoading(true);
    const newNews = await _getNews();
    setNews(newNews);
    setLoading(false);
    let pushNews;

    if (newNews.length >= 1) {
      pushNews = newNews.find(
        (ob) => ob.title == notification.request.content.title
      );
    }

    if (typeof pushNews !== "undefined") {
      openNews(pushNews);
    }
  };

  const handleFilter = (tag: string) => {
    if (tag == "Filter löschen") {
      setFilter([]);
      return;
    }

    let newfilter = [...filter];

    if (newfilter.includes(tag)) {
      var pos = newfilter.indexOf(tag);
      newfilter.splice(pos, 1);
    } else {
      //newfilter = [filter]
      newfilter.push(tag);
    }

    setFilter(newfilter);
  };

  const filterNews = () => {
    let newNews = news;

    if (Array.isArray(newNews)) {
      newNews = newNews.filter((news) =>
        news.title.toLocaleLowerCase().includes(text.toLocaleLowerCase())
      );

      if (filter.length > 0) {
        filter.map(
          (fil) => (newNews = newNews.filter((news) => news.tags.includes(fil)))
        );
        //newNews = newNews.filter(news => filter.includes(news.tags))
      }
    }
    return newNews;
  };

  const openNews = (news: NewsType) => {
    setOverlay(true);
    setCurrentNews(news);
    setAction("news");
  };

  const openFood = () => {
    setOverlay(true);
    setAction("cafe");
  };

  let today = moment().startOf("day");
  today.locale("de");

  const getTodaysTermine = () => {
    const todaysTermine = events.filter((termin: TerminData) =>
      termin.title.isSame(today, "days")
    );
    if (todaysTermine.length == 1) {
      return todaysTermine[0];
    }
    return null;
  };

  const onRefresh = async () => {
    setRefreshing(true);
    const status = await _getStatus();
    dispatch({
      type: "UPDATE_STAUTUS",
      payload: status,
    });

    const events = await _getCalendar();
    dispatch({
      type: "UPDATE_EVENTS",
      payload: {
        events,
      },
    });
    _getFood();
    getNews();
    getMenu();
    setRefreshing(false);
  };

  const todaysTermine = getTodaysTermine();

  const todaysFood = cafeData
    ? cafeData.foods.filter((foodData) =>
        today.isSameOrBefore(foodData.date, "day")
      )
    : [];

  return (
    <View
      style={[
        styles.root,
        { backgroundColor: overlay ? color.text : color.background },
      ]}
    >
      <AnimatedContainer
        style={[
          styles.container,
          {
            transform: [{ scale }, { translateY }],
            opacity: opacity,
          },
        ]}
      >
        <SafeAreaView
          style={{
            flex: 1,
            paddingTop: Constants.statusBarHeight,
            backgroundColor: color.background,
          }}
          forceInset={{ top: "always" }}
        >
          <TitelBar
            handleMenu={() => {
              setAction("menu");
              setOverlay(true);
            }}
            refresh={false}
          />

          {loading && <ActivityIndicator size="large" color={color.text} />}

          {!loading && (
            <ScrollView
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{ alignItems: "flex-start" }}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                  tintColor={color.text}
                />
              }
            >
              <View style={{ flexDirection: "row", width: "100%" }}>
                <View
                  style={{
                    flexDirection: width > 1200 ? "row" : "column",
                    flex: 1,
                  }}
                >
                  <View style={{ flex: width > 1200 ? 1 : undefined }}>
                    <CheckIn />
                  </View>
                  <TodaysTermine
                    todaysTermine={todaysTermine}
                    today={today}
                    onPress={() => {
                      setOverlay(true);
                      setAction("plan");
                    }}
                    setOverlay={setOverlay}
                  />

                  {width > 1200 && (
                    <View style={{ flex: 1 }}>
                      <News
                        news={filterNews()}
                        {...{ openNews, filter, handleFilter }}
                        maxHeight={true}
                      />
                    </View>
                  )}
                </View>

                {width > 720 && width <= 1200 && (
                  <View style={{ flex: 1 }}>
                    <News
                      news={filterNews()}
                      {...{ openNews, filter, handleFilter }}
                      maxHeight={true}
                    />
                  </View>
                )}
              </View>

              <FoodOfTheWeek onPress={openFood} todaysFood={todaysFood} />

              {width <= 720 && (
                <News
                  news={filterNews()}
                  {...{ openNews, filter, handleFilter }}
                />
              )}
            </ScrollView>
          )}
        </SafeAreaView>
      </AnimatedContainer>

      <Modal
        visible={overlay}
        transparent={true}
        animationType="slide"
        statusBarTranslucent={true}
      >
        {action == "news" && (
          <NewsOverlay
            news={currentNews!}
            handleClose={() => {
              setOverlay(false);
              setAction(null);
            }}
          />
        )}
        {action == "cafe" && (
          <CafeOverlay
            data={cafeData!}
            handleClose={() => {
              setOverlay(false);
              setAction(null);
            }}
          />
        )}
        {action == "plan" && (
          <StundenplanOverlay
            handleClose={() => {
              setOverlay(false);
              setAction(null);
            }}
            today={today}
            setSecondOverlay={setSecondOverlay}
            secondOverlay={secondOverlay}
          />
        )}
        {action == "menu" && (
          <Menu
            handleClose={() => {
              setOverlay(false);
              setAction(null);
            }}
            navigation={navigation}
          />
        )}
      </Modal>
    </View>
  );
};

export default NewsScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: "hidden",
  },
  root: {
    flex: 1,
  },
  card: {
    paddingHorizontal: 20,
    paddingVertical: 16,
    zIndex: 1,
  },
  btn: {},
  day: {
    fontWeight: "300",
    fontSize: 20,
    fontFamily: "FS_Elliot_Pro-Light",
    paddingVertical: 12,
  },
  more: {
    fontWeight: "300",
    fontSize: 16,
    textDecorationLine: "underline",
    textAlign: "right",
    fontFamily: "FS_Elliot_Pro-Light",
    paddingVertical: 12,
  },
});

const AnimatedContainer = Animated.createAnimatedComponent(View);
