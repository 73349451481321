import React, { useEffect } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { Moment } from "moment";
import "moment/locale/de";
import { stl } from "../template/Styles";
import { Arrow } from "./Icons";
import { RootState } from "../store";
import { TerminData } from "../App.types";
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";

interface DayProps {
  day: string;
  date: string;
  active: boolean;
  clickable: boolean;
  onPress: () => void;
}

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const Day = ({ day, date, active, clickable, onPress }: DayProps) => {
  const color = useSelector((state: RootState) => state.color);
  const animation = useSharedValue(active ? 1 : 0);

  useEffect(() => {
    animation.value = withTiming(active ? 1 : 0, { duration: 700 });
  }, [active]);

  const animatedStyle = useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      animation.value,
      [0, 1],
      [`${color.background}00`, color.background]
    );

    return {
      backgroundColor,
      shadowColor: "rgb(0,0,0)",
      shadowOffset: { width: 0, height: 12 },
      shadowOpacity: 0.12 * animation.value,
      shadowRadius: 24 * animation.value,
      elevation: 15 * animation.value,
    };
  });

  return (
    <AnimatedPressable
      onPress={() => onPress()}
      style={[
        styles.dayBox,
        animatedStyle,
        // active && { backgroundColor: color.background },
        // active && stl.shdw
      ]}
    >
      <Text style={[stl.copyM, { color: color.text }]}>{day}</Text>
      <Text style={[stl.copyM, { color: color.text }]}>{date}</Text>
      <View
        style={[
          styles.circle,
          { backgroundColor: clickable ? color.baseColor : color.card },
        ]}
      />
    </AnimatedPressable>
  );
};

interface WeekProps {
  week: Moment[];
  activeDay: Moment;
  onDay: (day: Moment) => void;
  events: TerminData[];
  onNextWeek: () => void;
  onLastWeek: () => void;
  month: string;
  today: Moment;
}

const Week = ({
  week,
  activeDay,
  onDay,
  events,
  onNextWeek,
  onLastWeek,
  month,
  today,
}: WeekProps) => {
  const color = useSelector((state: RootState) => state.color);

  const firstWeek = week.some((day) => day.isSame(today, "day"));

  return (
    <View style={[styles.box, {}]}>
      <View>
        <Text style={[stl.copyL, { color: color.text }]}>{month}</Text>
      </View>

      <View style={[styles.daysBox]}>
        {!firstWeek ? (
          <Pressable
            hitSlop={10}
            onPress={onLastWeek}
            style={({ pressed }) => [
              styles.arrow,
              { opacity: pressed ? 0.7 : 1 },
            ]}
          >
            <Arrow
              color={color.text}
              style={{ transform: [{ rotate: "180deg" }] }}
            />
          </Pressable>
        ) : (
          <View style={styles.arrow} />
        )}

        {week.map((date, index) => {
          return (
            <Day
              key={index}
              day={date.format("dd")}
              date={date.format("DD")}
              active={date.date() == activeDay.date()}
              clickable={events.some((data) => data.title.isSame(date, "day"))}
              onPress={() => onDay(date.clone())}
            />
          );
        })}

        <Pressable
          hitSlop={10}
          onPress={onNextWeek}
          style={({ pressed }) => [
            styles.arrow,
            { opacity: pressed ? 0.7 : 1 },
          ]}
        >
          <Arrow color={color.text} />
        </Pressable>
      </View>
    </View>
  );
};

export default Week;

const styles = StyleSheet.create({
  circle: {
    height: 8,
    width: 8,
    borderRadius: 4,
  },
  dayBox: {
    paddingHorizontal: 8,
    paddingVertical: 12,
    alignItems: "center",
    borderRadius: 4,
    width: 44,
  },
  box: {
    alignItems: "center",
    padding: 16,
  },
  daysBox: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  arrow: {
    width: 20,
    justifyContent: "center",
    alignItems: "center",
  },
});
