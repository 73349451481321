import React, { useEffect, useState, useRef } from "react";
import {
  ScrollView,
  Animated,
  StyleSheet,
  View,
  StatusBar,
  Modal,
  Text,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import { useSelector } from "react-redux";
import Search from "../components/Search";
import { ConfluenceManager } from "../confluence/confluence";
import TitelBar from "../components/TitelBar";
import WikiTile from "../components/WikiTile";
import WikiOverlay from "../components/WikiOverlay";
import { SafeAreaView } from "react-navigation";
import Constants from "expo-constants";
import { stl } from "../template/Styles";
import { RootState } from "../store";
import { Content, Page } from "../App.types";
import CustomHtml from "../components/CustomHtml";
import Crums from "../components/Crums";

const WikiScreen = () => {
  const color = useSelector((state: RootState) => state.color);
  const user = useSelector((state: RootState) => state.user);
  const { width } = useWindowDimensions();

  const [text, setText] = useState("");
  const [content, setContent] = useState<Page | null>(null);
  const [currentContent, setCurrentContent] = useState<Page | null>(null);
  const [pfad, setPfad] = useState(["MutaborApp"]);
  const [overlay, setOverlay] = useState(false);
  const [currentPageId, setCurrentPageId] = useState<number | null>(null);
  const [data, setData] = useState<Content | null>(null);

  const scale = useRef(new Animated.Value(1)).current;
  const opacity = useRef(new Animated.Value(1)).current;

  const crums = useRef<ScrollView>(null!);

  useEffect(() => {
    toggleBackground();
  }, [overlay]);

  useEffect(() => {
    crums.current.scrollToEnd();
    updateContent();
  }, [pfad]);

  useEffect(() => {
    getAllConfluence();
  }, []);

  useEffect(() => {
    setData(null);
    if (currentPageId) {
      getConfluence(currentPageId);
    }
  }, [currentPageId]);

  const updateContent = () => {
    if (content == null) {
      return;
    }
    if (pfad.length > 1) {
      let newContent = content;

      for (let index = 1; index < pfad.length; index++) {
        const crum = pfad[index];
        newContent = newContent.children.find((page) => page.title == crum)!;
      }

      setCurrentPageId(newContent.id);
      setCurrentContent(newContent);
    } else {
      setCurrentContent(content);
      setCurrentPageId(content.id);
    }
  };

  const toggleBackground = () => {
    if (overlay) {
      Animated.timing(scale, {
        toValue: 0.9,
        duration: 300,
        //easing: Easing.in(),
        useNativeDriver: true,
      }).start();
      Animated.spring(opacity, {
        toValue: 0.6,
        useNativeDriver: true,
      }).start();

      StatusBar.setBarStyle(
        color.background == "#ffffff" ? "light-content" : "dark-content",
        true
      );
    }

    if (!overlay) {
      Animated.timing(scale, {
        toValue: 1,
        duration: 300,
        //easing: Easing.in(),
        useNativeDriver: true,
      }).start();
      Animated.spring(opacity, {
        toValue: 1,
        useNativeDriver: true,
      }).start();
      StatusBar.setBarStyle(
        color.background == "#ffffff" ? "dark-content" : "light-content",
        true
      );
    }
  };

  const addToTree = (tree: Page, page: Page, id: number) => {
    if (tree.id == id) {
      return {
        id: tree.id,
        title: tree.title,
        ancestors: tree.ancestors,
        children: [...tree.children, page],
      };
    } else if (tree.children.length == 0) {
      return tree;
    } else {
      let newChildren: Page[] = [];
      for (let index = 0; index < tree.children.length; index++) {
        const element = tree.children[index];
        newChildren.push(addToTree(element, page, id));
      }

      return {
        ...tree,
        children: newChildren,
      };
    }
  };

  const getAllConfluence = async () => {
    // setContent([])
    // setPfad(["MutaborApp"])

    let result = await ConfluenceManager.getAllPages(); //26543294 327683
    if (result == null) {
      alert("Error while loading Data");
      //result = dummyData
      return;
    }

    let newContent: Page = result[0];

    result.forEach((page) => {
      if (page.ancestors.length > 0) {
        const parentId = page.ancestors[page.ancestors.length - 1].id;

        newContent = addToTree(newContent, page, parentId);
      }
    });

    newContent.children = newContent.children.filter((child) => {
      return user.department!.toLowerCase().includes(child.title.toLowerCase());
    });

    setContent(newContent);
    setCurrentContent(newContent);
    setCurrentPageId(newContent.id);
    setPfad([newContent.title]);
  };

  const flatten = (content: Page) => {
    let newContent: Page[] = [];

    newContent.push({ ...content });
    if (content.children.length > 0) {
      content.children.map((child) => {
        newContent = newContent.concat(flatten(child));
      });
    }

    return newContent;
  };

  const filterContent = () => {
    let newContent: Page[] = [];
    if (content == null) {
      return newContent;
    }

    newContent = flatten(content);
    newContent = newContent.filter((x) => {
      return x.title.toLowerCase().includes(text.toLowerCase());
    });

    newContent.sort((a, b) => {
      let titel1 = a.title.toLowerCase();
      let titel2 = b.title.toLowerCase();
      if (titel1.startsWith(text) && !titel2.startsWith(text)) {
        return -1;
      } else if (!titel1.startsWith(text) && titel2.startsWith(text)) {
        return 1;
      }
      return 0;
    });

    return newContent;
  };

  const filterdContent = filterContent();

  const openOverlay = (id: number) => {
    if (width < 720) {
      setOverlay(true);
    } else {
      setCurrentPageId;
    }
    setCurrentPageId(id);
  };

  const handlePfad = (ob: Page) => {
    setPfad([...pfad, ob.title]);
    setCurrentContent(ob);
  };

  const goBackTo = (to: string) => {
    if (to === "Wiki") {
      setPfad(["MutaborApp"]);
      return;
    }
    let newPfad = [...pfad];
    while (newPfad[newPfad.length - 1] !== to) {
      newPfad.pop();
    }
    setPfad(newPfad);
  };

  const getConfluence = async (id: number) => {
    const result = await ConfluenceManager.getPage(id);

    if (result != null) {
      const html = result.body;

      setData({ content: html == "" ? null : html, title: result.title });
    }
  };

  return (
    <View
      style={[
        styles.root,
        { backgroundColor: overlay ? color.text : color.background },
      ]}
    >
      <Modal
        visible={overlay && currentPageId != null}
        transparent={true}
        animationType="slide"
      >
        <WikiOverlay
          handleClose={() => {
            setOverlay(false);
          }}
          pageId={currentPageId!}
        />
      </Modal>

      <AnimatedContainer
        style={[
          styles.container,
          {
            transform: [{ scale: scale }],
            opacity: opacity,
            backgroundColor: color.background,
          },
        ]}
      >
        <SafeAreaView
          style={{ flex: 1, paddingTop: Constants.statusBarHeight }}
        >
          <TitelBar
            refresh={true}
            back={pfad.length > 1 ? true : false}
            handleBack={() => {
              if (pfad.length > 1) {
                let newPfad = [...pfad];
                newPfad.pop();
                setPfad(newPfad);
              }
            }}
            handleMenu={() => getAllConfluence()}
          />

          <Search
            text={text}
            onChangeText={(text) => setText(text)}
            placeholder={"Wiki durchsuchen..."}
          />

          <Crums pfad={pfad} onPress={(crum) => goBackTo(crum)} ref={crums} />

          {currentContent == null && (
            <ActivityIndicator size="large" color={color.text} />
          )}

          <View style={{ flexDirection: "row", flex: 1 }}>
            {currentContent != null && (
              <ScrollView
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{ paddingBottom: 20 }}
                style={{
                  maxWidth: width > 720 ? 400 : 720,
                  minWidth: width > 720 ? 300 : 0,
                  flex: 1,
                }}
              >
                {text == "" ? (
                  <WikiTile
                    content={currentContent}
                    pfad={pfad}
                    handlePress={openOverlay}
                    setPfad={handlePfad}
                  />
                ) : (
                  filterdContent.map((page) => (
                    <WikiTile
                      key={page.id}
                      content={page}
                      pfad={pfad}
                      handlePress={openOverlay}
                      setPfad={handlePfad}
                    />
                  ))
                )}
              </ScrollView>
            )}

            {currentPageId != null && width > 720 && (
              <View
                style={{ padding: 24, paddingTop: 0, flex: 3, height: "100%" }}
              >
                {data == null ? (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ActivityIndicator size="large" color={color.text} />
                  </View>
                ) : (
                  <>
                    <Text
                      style={[
                        stl.headlineL,
                        { color: color.text, paddingBottom: 24 },
                      ]}
                    >
                      {data.title}
                    </Text>
                    <ScrollView style={{}}>
                      <CustomHtml
                        source={data.content}
                        getConfluence={getConfluence}
                        contentWidth={width - 396}
                        card={true}
                      />
                    </ScrollView>
                  </>
                )}
              </View>
            )}
          </View>
        </SafeAreaView>
      </AnimatedContainer>
    </View>
  );
};

export default WikiScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    flex: 1,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  tile: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 4,
    zIndex: 2,
    height: 32,
  },
  crumBox: {
    paddingVertical: 8,
    paddingHorizontal: 4,
    zIndex: 2,
    height: 32,
  },
  tileBox: {
    paddingTop: 12,
    paddingBottom: 16,
  },
  crum: {
    textTransform: "uppercase",
    opacity: 0.7,
  },
});

const AnimatedContainer = Animated.createAnimatedComponent(View);
