import { Event, User } from "@microsoft/microsoft-graph-types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import {
  FoodsData,
  Menu,
  NewsType,
  Office,
  TerminData,
  TerminProp,
} from "../App.types";
import { ConfluenceManager } from "../confluence/confluence";
import GraphManager from "../graph/GraphManager";

export const _getFood = async () => {
  const rawData = await ConfluenceManager.getCafeData();
  if (rawData == null) {
    alert("Wir konnten die Infos zum Cafe nicht aktualliesieren");
    return null;
  }

  const menu = parseMenu(rawData.menu);
  const foods: FoodsData[] = rawData.foods.map((rawFoodData) => {
    return { ...parseFood(rawFoodData.value), id: rawFoodData.id as number };
  });

  const data = { menu, foods };

  const result = JSON.stringify(data);
  await AsyncStorage.setItem("cafeMenu", result);
  return data;
};

const parseFood = (inValue: string) => {
  let value: string | string[] = inValue;

  let image = value.slice(value.indexOf("<img"));
  image = image.slice(0, image.indexOf(">"));
  image = image.slice(image.indexOf("src=") + 6);
  image = image.slice(0, image.indexOf(" "));
  image = image.slice(0, image.indexOf('\\"'));
  image = "https://confluence.mutabor.de/" + image;

  value = value
    .replace(/<(\/?)(((table){1}[^>]+)|([^>]+(table){1}))(>|$)/g, "/%&")
    .replace(/<\/?[^>]+(>|$)/g, " ")
    .split("/%&");

  value.pop();

  const dataValue = value[1];

  const data = {
    date: dataValue
      .slice(dataValue.indexOf("Datum") + 5, dataValue.indexOf("Bild"))
      .trim(),
    headline: dataValue
      .slice(dataValue.indexOf("Text") + 4, dataValue.indexOf("Anmeldungen"))
      .trim(),
    image,
    teilnehmer: dataValue.slice(dataValue.indexOf("Anmeldungen") + 11),
  };

  return data;
};

const parseMenu = (inValue: string) => {
  let value: string | string[] = inValue;

  value = value
    .replace(/<(\/?)(((table){1}[^>]+)|([^>]+(table){1}))(>|$)/g, "/%&")
    .replace(/<\/?[^>]+(>|$)/g, " ")
    .split("/%&");
  value.pop();

  let data: Menu[] = [];

  for (let index = 0; index < value.length; index = index + 2) {
    data.push({
      title: value[index].trim(),
      items: value[index + 1]
        .trim()
        .split("  ")
        .filter((x) => x > "")
        .reduce(
          (
            acc: {
              name: string;
              price: string;
            }[],
            x,
            index,
            array
          ) => {
            if (index % 2 == 1) {
              return [
                ...acc,
                {
                  name: array[index - 1],
                  price: x,
                },
              ];
            } else {
              return acc;
            }
          },
          []
        ),
    });
  }

  return data;
};

export const _updateUser = async () => {
  const newUserData = await GraphManager.updateUser();

  if (newUserData == null) {
    return null;
  }

  const newPeople = await GraphManager.updatePeople();
  const status = await _getStatus();
  const newUser = { ...newUserData, status };

  if (newPeople.length > 0) {
    //this.props.updateState(newUser, newPeople);
    let string = `{"people":[`;
    //string += JSON.stringify(newPeople[0]) + ","
    newPeople.map((obj) => (string += `${JSON.stringify(obj)},`));

    string = string.slice(0, string.length - 1);

    string += "]}";

    await AsyncStorage.setItem("people", string);

    return {
      user: newUser,
      people: newPeople,
    };
  } else {
    alert("Wir konnten die Faces Daten nicht aktualiesieren");
    let oldPeolpe = await AsyncStorage.getItem("people");

    if (oldPeolpe != null) {
      const oldPeolpeOb = JSON.parse(oldPeolpe).people as User[];

      return {
        user: newUser,
        people: oldPeolpeOb,
      };
    }
  }
  return null;
};

export const _getCalendar = async () => {
  const data: { value: Event[] } = await GraphManager.getEvents().catch(() => {
    return { value: [] };
  });
  const events: Event[] = data.value;

  const today = moment().startOf("day");

  if (events.length > 0) {
    let termine: TerminProp[] = [];

    let string = `{"events":[`;
    //string += JSON.stringify(newPeople[0]) + ","
    events.map((obj) => (string += `${JSON.stringify(obj)},`));

    string = string.slice(0, string.length - 1);

    string += "]}";

    await AsyncStorage.setItem("events", string);

    let recurringEvents: { id: string; startTime: string; endTime: string }[] =
      [];

    events.map((event) => {
      if (
        event.recurrence != null
        // moment(event.end!.dateTime + "Z").isAfter(today, "day")
      ) {
        recurringEvents.push({
          id: event.id!,
          startTime: today.toISOString(),
          endTime: moment(event.recurrence?.range?.endDate).toISOString(),
        });
      } else {
        termine.push({
          ...event,
          start: moment(event.start!.dateTime + "Z"),
          end: moment(event.end!.dateTime + "Z"),
        });
      }
    });

    if (recurringEvents.length > 0) {
      let recurring = await GraphManager.getMultipleEventIstances(
        recurringEvents
      );

      recurring.map((rec) => {
        termine.push({
          ...rec,
          start: moment(rec.start!.dateTime + "Z"),
          end: moment(rec.end!.dateTime + "Z"),
        });
      });
    }

    const filteredEvents = termine.filter((event) =>
      event.start.isSameOrAfter(today, "day")
    );
    return transformTerminData(filteredEvents);
  } else {
    alert(
      "There is a Problem with your Internet Connection. The Calendar could not be updated"
    );
    const oldEvents = await AsyncStorage.getItem("events");
    let oldEventsArray: TerminProp[] = [];
    if (oldEvents != null) {
      oldEventsArray = JSON.parse(oldEvents).events;

      oldEventsArray.forEach((event) => {
        event.start = moment(event.start!.dateTime);
        event.end = moment(event.end!.dateTime);
      });
    }

    return transformTerminData(oldEventsArray);
  }
};

export const _getCalendar2 = async () => {
  const data: { value: Event[] } = await GraphManager.getEvents().catch(() => {
    return { value: [] };
  });
  const events: Event[] = data.value;
  let termine: TerminProp[] = [];
  const today = moment().startOf("day");

  if (events.length > 0) {
    let string = `{"events":[`;
    //string += JSON.stringify(newPeople[0]) + ","
    events.map((obj) => (string += `${JSON.stringify(obj)},`));

    string = string.slice(0, string.length - 1);

    string += "]}";

    await AsyncStorage.setItem("events", string);

    await Promise.all(
      events.map(async (event) => {
        let newEvent: TerminProp = {
          ...event,
          start: moment(event.start!.dateTime + "Z"),
          end: moment(event.end!.dateTime + "Z"),
        };

        if (newEvent.recurrence != null) {
          const startTime = today.toISOString();
          const endTime = event.recurrence?.range?.endDate
            ? moment(event.recurrence?.range?.endDate).toISOString()
            : today.clone().add(6, "month").toISOString();
          const newEvents: { value: Event[] } =
            await GraphManager.getEventIstances(
              event.id!,
              startTime,
              endTime
            ).catch(() => {
              return { value: [] };
            });

          newEvents.value.forEach((event) => {
            termine.push({
              ...event,
              start: moment(event.start!.dateTime + "Z"),
              end: moment(event.end!.dateTime + "Z"),
            });
          });
        } else {
          termine.push(newEvent);
        }
      })
    );

    const filteredEvents = termine.filter((event) =>
      event.start.isSameOrAfter(today, "day")
    );
    return transformTerminData(filteredEvents);
  } else {
    alert(
      "There is a Problem with your Internet Connection. The Calendar could not be updated"
    );
    const oldEvents = await AsyncStorage.getItem("events");
    let oldEventsArray: TerminProp[] = [];
    if (oldEvents != null) {
      oldEventsArray = JSON.parse(oldEvents).events;

      oldEventsArray.forEach((event) => {
        event.start = moment(event.start!.dateTime);
        event.end = moment(event.end!.dateTime);
      });
    }

    return transformTerminData(oldEventsArray);
  }
};

const transformTerminData = (alteTerine: TerminProp[]) => {
  const neueTermine: TerminData[] = [];

  alteTerine.map((termin: TerminProp) => {
    const date = moment(termin.start.format("YYYY-MM-DD"));
    const index = neueTermine.findIndex((data) =>
      data.title.isSame(date, "day")
    );
    const neuerTermin = {
      ...termin,
      start: termin.start.dayOfYear(date.dayOfYear()),
      end: termin.end.dayOfYear(date.dayOfYear()),
    };

    if (index >= 0) {
      neueTermine[index].data.push(neuerTermin);
    } else {
      neueTermine.push({
        title: date,
        data: [neuerTermin],
      });
    }
  });

  neueTermine.sort((a, b) => {
    if (a.title.isBefore(b.title)) {
      return -1;
    } else if (a.title.isAfter(b.title)) {
      return 1;
    } else {
      return 0;
    }
  });

  return neueTermine;
};

export const _getStatus = async () => {
  const groups = await GraphManager.checkGroups();
  let status: Office | null = null;
  if (groups.length == 1) {
    switch (groups[0].displayName) {
      case "Home Office":
        status = "home";
        break;
      case "Dienstreise":
        status = "reise";
        break;
      case "Urlaub":
        status = "urlaub";
        break;
      case "Königs Kontor":
        status = "office";
        break;
      default:
        return null;
    }
  }

  return status;
};

export const _getNews = async () => {
  let newNews = await ConfluenceManager.getNews();

  let news: NewsType[] = [];

  if (newNews != null) {
    newNews?.map((newsOb) => {
      if (newsOb.value && newsOb.value != null) {
        const { text, tags } = parseNews(newsOb.value);
        const img = parseNewsImage(newsOb.img);
        news.push({
          title: newsOb.title,
          text,
          date: moment(newsOb.date),
          tags,
          image: { uri: img },
        });
      }
    });

    news.sort((a, b) => {
      if (a.date.isBefore(b.date)) {
        return 1;
      } else if (a.date.isAfter(b.date)) {
        return -1;
      } else {
        return 0;
      }
    });

    const data = '{"news": ' + JSON.stringify(news) + "}";
    await AsyncStorage.setItem("news", data);
  } else {
    alert(
      "There is a Problem with your Internet Connection. The News could not be updated"
    );
    const oldNewsData = await AsyncStorage.getItem("news");

    if (oldNewsData != null) {
      news = JSON.parse(oldNewsData).news;
    }
  }

  return news;
};

const parseNews = (inValue: string) => {
  let text = inValue.slice(
    inValue.indexOf('<td class="confluenceTd">Text</td>') + 59
  );
  text = text.slice(0, text.indexOf("</td>"));
  //text = text.replace(/<[^>]*>?/gm, '\n');

  let tag = inValue.slice(
    inValue.indexOf('<td colspan="1" class="confluenceTd">Tags</td>')
  );
  tag = tag.slice(tag.indexOf("<ul"));
  tag = tag.slice(0, tag.indexOf("</td>"));
  tag = tag.replace("&amp;", "&");
  const tagArray = tag.split("<li");
  let tags: string[] = [];
  tagArray.map((str: string) => {
    if (str.includes("checked")) {
      let helperStr = str.slice(str.indexOf(">") + 1, str.indexOf("</"));
      if (helperStr.includes(">")) {
        helperStr = helperStr.slice(helperStr.indexOf(">") + 1);
        if (helperStr.includes("<")) {
          tags.push(helperStr.slice(0, helperStr.indexOf("<")));
        } else {
          tags.push(helperStr);
        }
      } else {
        tags.push(helperStr);
      }
    }
  });

  return { tags, text };
};

const parseNewsImage = (inValue: string) => {
  let img = inValue.slice(inValue.indexOf('src="') + 5);
  img = img.slice(0, img.indexOf('"'));
  img = "https://confluence.mutabor.de" + img;
  return img;
};
