import React from 'react'
import { StyleSheet, View, Pressable, StyleProp, ViewStyle } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { Close, CloseOutline, CloseSolid } from './Icons'

interface Props {
    onPress: () => void,
    style?: StyleProp<ViewStyle>
}

const CloseButton = ({ onPress, style }: Props) => {
    const color = useSelector((state: RootState) => state.color)
    return (
        <Pressable onPress={onPress} style={[styles.box, { backgroundColor: color.text }, style]}>
            {({ pressed }) => (
                <View style={[{ opacity: pressed ? 0.7 : 1 }]}>

                    {/* <Ionicons name="ios-close" size={44} color={color.card} /> */}
                    {/* <CloseOutline height={44} width={44} color={color.text} /> */}
                    <Close height={40} width={40} color={color.background} />
                </View>
            )}
        </Pressable>
    )
}

export default CloseButton

const styles = StyleSheet.create({
    box: {
        height: 44,
        width: 44,
        borderRadius: 22,
        justifyContent: "center",
        alignItems: "center",

    }
})
