import React, { useEffect, useState } from "react";
import RenderHtml, {
  CustomBlockRenderer,
  defaultSystemFonts,
  IMGElementProps,
  useInternalRenderer,
} from "react-native-render-html";
// import * as Linking from "expo-linking";
import { WebView } from "react-native-webview";
import { tagsStyles } from "../template/Styles";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import {
  TableRenderer,
  useHtmlTableCellProps,
} from "@native-html/heuristic-table-plugin";
import { Platform, Image } from "react-native";
import { Linking } from "react-native";

interface Props {
  source: string;
  contentWidth?: number;
  getConfluence?: (id: number) => void;
  card?: boolean;
}

const CustomHtml = ({ source, contentWidth, getConfluence, card }: Props) => {
  const color = useSelector((state: RootState) => state.color);

  const systemFonts = [
    "'FS_Elliot_Pro-Heavy'",
    "'FS_Elliot_Pro-Light'",
    "'FS_Elliot_Pro'",
    ...defaultSystemFonts,
  ];

  const imgRenderer: CustomBlockRenderer = (props) => {
    const { Renderer, rendererProps } = useInternalRenderer("img", props);
    const uri = rendererProps.source.uri?.includes("about://")
      ? rendererProps.source.uri.replace(
          "about://",
          "https://confluence.mutabor.de"
        )
      : rendererProps.source.uri;
    if (Platform.OS !== "web") {
      return (
        <Renderer
          {...rendererProps}
          source={{ ...rendererProps.source, uri }}
        />
      );
    } else {
      return <WebImage rendererProps={rendererProps} uri={uri} />;
    }
  };

  const onPress = (event: any, url: string) => {
    if (typeof getConfluence !== "undefined" && url.includes("pageId")) {
      const index = url.indexOf("pageId");
      const id = parseInt(url.slice(index + 7, index + 15));
      getConfluence(id);
    } else {
      Linking.openURL(url);
    }
  };

  const renderersProps = {
    a: {
      onPress: onPress,
    },
  };

  const ThRenderer: CustomBlockRenderer = (props) => {
    const cellProps = useHtmlTableCellProps(props);
    // The cell parent prop contains information about this cell,
    // especially its position (x, y) and lengths (lenX, lenY).
    // In this example, we customize the background depending on the
    // y coordinate (row index).
    const { cell } = cellProps.propsFromParent;
    // console.log(cellProps.propsFromParent);
    const style = [
      cellProps.style,
      {
        borderTopLeftRadius: cell.x == 0 ? 4 : 0,
        borderBottomLeftRadius: cell.x == 0 ? 4 : 0,
        padding: 8,
        marginBottom: 12,
      },
    ];
    return React.createElement(cellProps.TDefaultRenderer, {
      ...cellProps,
      style,
    });
  };

  const TdRenderer: CustomBlockRenderer = (props) => {
    const cellProps = useHtmlTableCellProps(props);
    // The cell parent prop contains information about this cell,
    // especially its position (x, y) and lengths (lenX, lenY).
    // In this example, we customize the background depending on the
    // y coordinate (row index).
    const { cell } = cellProps.propsFromParent;
    const style = [
      cellProps.style,
      {
        borderTopLeftRadius: cell.x == 0 ? 4 : 0,
        borderBottomLeftRadius: cell.x == 0 ? 4 : 0,
        padding: 8,
        marginBottom: 12,
      },
    ];
    return React.createElement(cellProps.TDefaultRenderer, {
      ...cellProps,
      style,
    });
  };

  return (
    <RenderHtml
      source={{
        html: source,
        headers: {
          Authorization: "Basic dHdpbmtsZXI6SnVsaWEyMDA3",
        },
      }}
      defaultTextProps={{
        style: {
          fontFamily: "FS_Elliot_Pro-Light",
        },
      }}
      contentWidth={contentWidth}
      ignoredStyles={["color"]}
      ignoredDomTags={["form", "input"]}
      systemFonts={systemFonts}
      WebView={WebView}
      tagsStyles={tagsStyles(color, card)}
      renderers={{
        table: TableRenderer,
        td: TdRenderer,
        th: ThRenderer,
        img: imgRenderer,
      }}
      renderersProps={renderersProps}
    />
  );
};

export default CustomHtml;

interface WebImgProps {
  uri: string | undefined;
  rendererProps: IMGElementProps;
}

const WebImage = ({ uri, rendererProps }: WebImgProps) => {
  const [source, setSource] = useState(require("../assets/IconMutabor.png"));
  const [imgHeight, setImgHeight] = useState(
    rendererProps.initialDimensions?.height
      ? rendererProps.initialDimensions?.height
      : 15
  );
  const [imgWidth, setImgWidth] = useState(
    rendererProps.initialDimensions?.width
      ? rendererProps.initialDimensions?.width
      : 15
  );

  useEffect(() => {
    if (typeof uri !== "undefined") {
      fetch(uri, {
        method: "GET",
        headers: {
          Authorization: "Basic dHdpbmtsZXI6SnVsaWEyMDA3",
        },
      })
        .then((res) => res.blob())
        .then((content) => setSource(URL.createObjectURL(content)))
        .catch((err) => console.log(err));
    }
  }, []);

  const calcHeight = () => {
    const h =
      typeof rendererProps.height === "string"
        ? parseInt(rendererProps.height)
        : rendererProps.height;
    const w =
      typeof rendererProps.width === "string"
        ? parseInt(rendererProps.width)
        : rendererProps.width;
    if (typeof h === "number") {
      return h;
    }

    if (typeof w === "number") {
      return (imgHeight / imgWidth) * w;
    }

    return imgHeight;
  };

  const calcWidth = () => {
    const h =
      typeof rendererProps.height === "string"
        ? parseInt(rendererProps.height)
        : rendererProps.height;
    const w =
      typeof rendererProps.width === "string"
        ? parseInt(rendererProps.width)
        : rendererProps.width;

    if (typeof w === "number") {
      return w;
    }

    if (typeof h === "number") {
      return (imgWidth / imgHeight) * h;
    }

    return imgHeight;
  };

  const height = calcHeight();
  const width = calcWidth();
  return (
    <Image
      style={{
        height,
        width,
        minHeight: height,
        minWidth: width,
      }}
      onLoad={(x) => {
        setImgHeight(x.nativeEvent.target.height);
        setImgWidth(x.nativeEvent.target.width);
      }}
      source={source}
    />
  );
};
