import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  useWindowDimensions,
  Platform,
} from "react-native";
import { useSelector } from "react-redux";
import { stl } from "../template/Styles";
import { ConfluenceManager } from "../confluence/confluence";
import Button from "./Button";
import { Foods } from "../App.types";
import { RootState } from "../store";

interface Props {
  data: Foods | null;
  card?: boolean;
  single?: boolean;
}

const FoodTeaser = ({ data, card, single }: Props) => {
  const color = useSelector((state: RootState) => state.color);
  const user = useSelector((state: RootState) => state.user);
  const { width } = useWindowDimensions();
  const sourceInit =
    data != null
      ? {
          uri: data.image,
          headers: { Authorization: "Basic dHdpbmtsZXI6SnVsaWEyMDA3" },
        }
      : require("../assets/mutabor_auditorium.jpg");

  const [source, setSource] = useState(
    Platform.OS === "web"
      ? require("../assets/mutabor_auditorium.jpg")
      : sourceInit
  );

  useEffect(() => {
    if (data != null && Platform.OS === "web") {
      fetch(data.image, {
        method: "GET",
        headers: {
          Authorization: "Basic dHdpbmtsZXI6SnVsaWEyMDA3",
        },
      })
        .then((res) => res.blob())
        .then((content) => setSource(URL.createObjectURL(content)));
    }
  }, []);

  const [active, setActive] = useState(
    data != null && data.teilnehmer.includes(user.name)
  );

  return (
    <View
      style={[
        styles.box,
        stl.shdw,
        {
          backgroundColor: color.card,
          width: single ? width - 48 : 320,
          maxWidth: 1152,
        },
      ]}
    >
      <Image
        source={source}
        defaultSource={require("../assets/SplashScreen.png")}
        style={[styles.image, { aspectRatio: card ? 1.8 : 1 }]}
      />
      <View
        style={[
          styles.foodBox,
          { backgroundColor: card ? color.card : `${color.background}B0` },
        ]}
      >
        <Text style={[stl.copyS, { color: `${color.text}D0` }]}>
          {data != null
            ? data.date.format("D MMM YYYY")
            : "Wir lassen uns gerade inspirieren"}
        </Text>
        <Text style={[stl.headlineL, { color: color.text }]}>
          {data != null
            ? data.headline
            : "Schaut bald wieder für neue Angebote vorbei!"}
        </Text>
      </View>
      <View style={styles.btn}>
        {data != null && (
          <Button
            type={active ? "secondary" : ""}
            onPress={() => {
              if (!active) {
                ConfluenceManager.saveFood({ id: data.id, user: user.name });
                alert("Erfolgreich angemeldet!");
              } else {
                ConfluenceManager.deleteFood({ id: data.id, user: user.name });
                alert("Abgemeldet!");
              }
              setActive(!active);
            }}
            text={active ? "Abmelden" : "Portion sichern"}
          />
        )}
      </View>
    </View>
  );
};

export default FoodTeaser;

const styles = StyleSheet.create({
  box: {
    borderRadius: 4,
    marginRight: 24,
    alignItems: "flex-start",
  },
  image: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: "100%",
    height: undefined,
    minHeight: 170,
  },
  foodBox: {
    width: "100%",
    padding: 12,
    //paddingBottom: ,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  tag: {
    position: "absolute",
    top: 12,
    left: 12,
    flexDirection: "row",
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  btn: {
    paddingLeft: 12,
    paddingBottom: 12,
  },
});
