import React from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import { stl } from '../template/Styles'
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Page } from "../App.types";
import { Arrow } from "./Icons";

interface ChildProps {
    page: Page,
    handlePress: (id: number) => void,
    setPfad: (page: Page) => void
}

const WikiChild = ({ page, handlePress, setPfad }: ChildProps) => {

    const color = useSelector((state: RootState) => state.color)

    const handleChild = () => {

        if (page.children!.length > 0) {
            setPfad(page)
        } else {
            handlePress(page.id)
        }
    }

    return (
        <Pressable style={[styles.childBox, stl.shdw, { backgroundColor: color.card }]} onPress={() => handleChild()}>

            <Text style={[stl.copyL, { color: color.text }]}>{page.title}</Text>
            {page.children!.length > 0 && <Arrow color={color.text} style={{  }} />}

        </Pressable>
    )
}

interface WikiProps {
    content: Page,
    pfad: string[],
    handlePress: (id: number) => void,
    setPfad: (page: Page) => void
}

const WikiTile = ({ content, handlePress, setPfad }: WikiProps) => {

    const color = useSelector((state: RootState) => state.color)


    return (
        <View style={{ padding: 24 }}>


            <Pressable style={({ pressed }) => [styles.box, stl.shdw, { opacity: pressed ? 0.7 : 1, backgroundColor: color.card }]} onPress={() => {
                //if (child.children.length == 0) {
                handlePress(content.id)
                // } else {
                //     setPfad(child)
                // }

            }}>


                <Text style={[stl.headlineM, { color: color.text }]}>{content.title}</Text >

            </Pressable>
            {content.children.length > 0 && <View style={{ padding: 12, paddingLeft: 24, paddingRight: 0 }} >
                {content.children.map((childPage) => <WikiChild page={childPage} handlePress={handlePress} setPfad={setPfad} key={childPage.id} />)
                }
            </View>}
        </View>


    )
}

export default WikiTile;

const styles = StyleSheet.create({
    box: {
        flexDirection: "row",
        paddingHorizontal: 12,
        paddingVertical: 12,
        borderRadius: 4
    },
    childBox: {
        padding: 12,
        flexDirection: "row",
        borderRadius: 4,
        alignItems: "center",
        marginBottom: 8,
        justifyContent: "space-between"
    }
})


