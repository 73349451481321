import { createStore, combineReducers } from "redux";
import colorReducer from "./reducers/colorReducer"
import userReducer from "./reducers/userReducer"
import peopleReducer from "./reducers/peopleReducer"
import eventReducer from './reducers/eventReducer'

const reducer = combineReducers({
    color: colorReducer,
    user: userReducer,
    people: peopleReducer,
    events: eventReducer
})

const store = createStore(reducer);

export type RootState = ReturnType<typeof reducer>

export default store 