import React, { useRef, useState } from "react";
import {
  StyleSheet,
  Animated,
  View,
  useWindowDimensions,
  StatusBar,
} from "react-native";
import { useSelector } from "react-redux";
import Constants from "expo-constants";
import { ScrollView } from "react-native-gesture-handler";
import { RootState } from "../store";

interface Props {
  handleClose: () => void;
  children: React.ReactNode;
  header?: () => React.ReactNode;
  pos?: "absolute";
}

const Overlay = ({ handleClose, children, header, pos }: Props) => {
  const { height, width } = useWindowDimensions();
  const color = useSelector((state: RootState) => state.color);
  const contentOffset = useRef(new Animated.Value(0)).current;
  const statusBarHeight = Constants.statusBarHeight;

  const [barStyle, setBarStyle] = useState(false);

  const translateY = contentOffset.interpolate({
    inputRange: [0, 100 - statusBarHeight, 110, 110 + statusBarHeight],
    outputRange: [100, 100, 110 + statusBarHeight, 110 + statusBarHeight],
  });

  const translateYHeader = contentOffset.interpolate({
    inputRange: [0, 100 - statusBarHeight, 110],
    outputRange: [100, 100, 110 + statusBarHeight],
    // extrapolateRight: "clamp"
  });

  const translateYStausbar = contentOffset.interpolate({
    inputRange: [0, 110, 130],
    outputRange: [100, 100, 120],
    // extrapolateRight: "clamp"
  });

  const opacity = contentOffset.interpolate({
    inputRange: [110, 115],
    outputRange: [0, 1],
    // extrapolateRight: "clamp"
  });

  contentOffset.addListener(({ value }) => {
    if (value >= 100 - statusBarHeight && !barStyle) {
      setBarStyle(true);

      StatusBar.setBarStyle(
        color.background == "#ffffff" ? "dark-content" : "light-content"
      );
      //StatusBar.setBackgroundColor(color.background == "#ffffff" ? color.background : color.text)
    } else if (value < 100 - statusBarHeight && barStyle) {
      setBarStyle(false);

      StatusBar.setBarStyle(
        color.background == "#ffffff" ? "light-content" : "dark-content"
      );
      //StatusBar.setBackgroundColor(color.background == "#ffffff" ? color.background : color.text)
    }
  });

  return (
    <View style={{ flex: 1, alignItems: "center", height, width }}>
      <Animated.ScrollView
        scrollEventThrottle={1}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: contentOffset } } }],
          {
            useNativeDriver: true,
          }
        )}
        onScrollEndDrag={(event) => {
          let { nativeEvent } = event;

          if (nativeEvent.contentOffset.y < -150) {
            handleClose();
          }
        }}
        showsVerticalScrollIndicator={false}
        snapToOffsets={[0, 110]}
        snapToEnd={false}
        style={{
          maxWidth: 1000,
          width,
          height,
          // borderColor: "blue", borderWidth: 1
          //marginTop: 100
        }}
        contentContainerStyle={{ minHeight: height + 200 }}
      >
        {/* Objekt zum Blocken des Statusbar Hintergrundes */}
        <Animated.View
          style={{
            position: "absolute",
            transform: [{ translateY: translateYStausbar }],
            backgroundColor: color.card,
            height: statusBarHeight + 25,
            width: "100%",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            zIndex: !header || pos == "absolute" ? 0 : 10,
          }}
        />

        {/* Optionaler Header */}
        <Animated.View
          style={[
            styles.header,
            {
              zIndex: 10,
              transform: [{ translateY: translateYHeader }],
              position: pos,
              width: "100%",
              backgroundColor: pos == "absolute" ? undefined : color.card,
            },
          ]}
        >
          {header && header()}
        </Animated.View>

        {/* Content */}
        <Animated.View
          style={{
            zIndex: 9,
            transform: [{ translateY }],
            overflow: "hidden",
            backgroundColor: color.card,
            borderTopLeftRadius: !header || pos == "absolute" ? 10 : 0,
            borderTopRightRadius: !header || pos == "absolute" ? 10 : 0,
            minHeight: height + 200,
          }}
        >
          {children}
          <View style={{ height: height / 2 }} />
        </Animated.View>
      </Animated.ScrollView>
      <Animated.View
        style={{
          opacity,
          position: "absolute",
          height: statusBarHeight,
          backgroundColor: color.card,
          width,
        }}
      />
    </View>
  );
};

export default Overlay;

const styles = StyleSheet.create({
  header: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingTop: 10,
  },
});
