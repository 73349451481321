import { TerminData } from "../App.types"

interface eventAction {
    type: "UPDATE_EVENTS",
    payload: {
        events: TerminData[]
    }
}


const initalEvents: TerminData[] = [

]

const eventReducer = (state: TerminData[] = initalEvents, action: eventAction) => {
    switch (action.type) {

        case "UPDATE_EVENTS":
            return action.payload.events
        default:
            return state
    }



}

export default eventReducer