import React, { useEffect, useState } from "react";
import {
  TouchableOpacity,
  StyleSheet,
  View,
  Switch,
  Text,
  Image,
  Platform,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { AuthManager } from "../auth/AuthManager";
import * as Images from "../assets/faces/index";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Overlay from "./Overlay";
import { stl } from "../template/Styles";
import { RootState } from "../store";
import { NavigationTabProp } from "react-navigation-tabs";
import CloseButton from "./CloseButton";
import { LogOutOutline, SwitchSolid } from "./Icons";

interface Props {
  handleClose: () => void;
  navigation: NavigationTabProp;
}

const Menu = ({ handleClose, navigation }: Props) => {
  const [token, setToken] = useState<string | null>(null);
  const color = useSelector((state: RootState) => state.color);
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  let image = `${user.name}`;
  image = image
    .toLocaleLowerCase()
    .replace(" ", "_")
    .replace("ä", "ae")
    .replace("ü", "ue")
    .replace("ö", "oe");

  const [source, setSource] = useState(
    Images.default[image as keyof typeof Images.default]
  );

  useEffect(() => {
    if (Platform.OS === "web") {
      getImg();
    } else {
      getToken();
    }
  }, []);

  const getImg = async () => {
    const Token = await getToken();
    const response = await fetch(
      `https://graph.microsoft.com/beta/me/photo/$value`,
      {
        headers: {
          Authorization: "Bearer " + Token,
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          return null;
        } else {
          return res.blob();
        }
      })
      .catch((e) => {});

    if (response === null) {
      return;
    }
    setSource(URL.createObjectURL(response));
  };

  const getToken = async () => {
    const accessToken = await AsyncStorage.getItem("userToken");
    if (accessToken) {
      setToken(accessToken);
    }
  };

  const changeMode = async () => {
    try {
      const mode = await AsyncStorage.getItem("mode");

      await AsyncStorage.setItem("mode", mode == "light" ? "dark" : "light");

      dispatch({
        type: "CHANGE_MODE",
      });
    } catch (error) {
      alert(error);
    }
  };

  const logOut = async () => {
    try {
      await AuthManager.signOutAsync();

      dispatch({
        type: "RESET_USER",
      });

      navigation.navigate("Auth");
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Overlay handleClose={handleClose}>
      <View style={{ padding: 24 }}>
        <View style={[styles.imageBox, stl.shdw]}>
          {Platform.OS !== "web" && token !== null ? (
            <Image
              style={styles.image}
              defaultSource={
                Images.default[image as keyof typeof Images.default]
                  ? Images.default[image as keyof typeof Images.default]
                  : require("../assets/SplashScreen.png")
              }
              source={{
                uri: `https://graph.microsoft.com/beta/me/photo/$value`,
                headers: {
                  Authorization: "Bearer " + token,
                },
              }}
            />
          ) : (
            <Image
              style={styles.image}
              defaultSource={require("../assets/SplashScreen.png")}
              source={source}
            />
          )}
        </View>

        <View style={styles.user}>
          <Text style={[stl.headlineL, { color: color.text }]}>
            {user.name}
          </Text>
          <Text style={[stl.copyL, { color: color.text }]}>{user.email}</Text>
        </View>

        <CloseButton onPress={handleClose} style={styles.closeView} />

        <View style={{ flex: 1, paddingTop: 24 }}>
          <View
            style={[
              styles.box,
              stl.shdw,
              { backgroundColor: color.card, justifyContent: "space-between" },
            ]}
          >
            <TouchableOpacity
              onPress={changeMode}
              style={{ flexDirection: "row", alignItems: "center" }}
            >
              <View style={styles.iconBox}>
                <SwitchSolid color={color.text} />
              </View>

              <View style={styles.textBox}>
                <Text style={[stl.headlineM, { color: color.text }]}>
                  Change Mode
                </Text>
              </View>
            </TouchableOpacity>
            <Switch
              trackColor={{ false: color.text, true: color.text }}
              thumbColor={
                Platform.OS !== "ios" ? color.baseColor : color.background
              }
              ios_backgroundColor={color.text}
              onValueChange={changeMode}
              value={color.background === "#ffffff"}
              //@ts-ignore
              activeThumbColor={color.baseColor}
            />
          </View>

          <TouchableOpacity
            onPress={logOut}
            style={[styles.box, stl.shdw, { backgroundColor: color.card }]}
          >
            <View style={[styles.iconBox]}>
              <LogOutOutline color={color.text} />
            </View>

            <View style={styles.textBox}>
              <Text style={[stl.headlineM, { color: color.text }]}>
                Log-out
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </Overlay>
  );
};

export default Menu;

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    position: "absolute",
    height: "100%",
    width: "100%",
    maxWidth: 600,
    zIndex: 100,
    borderRadius: 10,
    overflow: "hidden",
  },
  iconBox: {
    width: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
  },
  textBox: {
    //paddingBottom: 16,
    paddingLeft: 16,
  },
  box: {
    flexDirection: "row",
    padding: 12,
    alignItems: "center",
    borderRadius: 4,
    marginBottom: 24,
  },
  user: {
    paddingVertical: 12,
  },
  closeView: {
    position: "absolute",
    top: 20,
    right: 20,
    zIndex: 1,
  },
  imageBox: {
    height: 160,
    width: 160,
  },
  image: {
    height: "100%",
    width: "100%",
    borderRadius: 4,
  },
  subTitle: {
    opacity: 0.6,
    textTransform: "lowercase",
  },
});
