import React, { useRef, useState, useEffect } from "react";
import {
  ScrollView,
  View,
  StyleSheet,
  Animated,
  StatusBar,
  Modal,
} from "react-native";
import { useSelector } from "react-redux";
import People from "../components/People";
import Filter from "../components/Filter";
import PeopleOverlay from "../components/PeopleOverlay";
import Search from "../components/Search";
import TitelBar from "../components/TitelBar";
import { SafeAreaView } from "react-navigation";
import Constants from "expo-constants";
import GraphManager from "../graph/GraphManager";
import { RootState } from "../store";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { NavigationTabProp } from "react-navigation-tabs";
import { Person } from "../App.types";

interface Props {
  navigation: NavigationTabProp;
}

type Group = {
  home: (string | undefined)[];
  office: (string | undefined)[];
  reise: (string | undefined)[];
  urlaub: (string | undefined)[];
};

const FacesScreen = (props: Props) => {
  const people = useSelector((state: RootState) => state.people);
  const color = useSelector((state: RootState) => state.color);

  const scale = useRef(new Animated.Value(1)).current;
  const opacity = useRef(new Animated.Value(1)).current;

  const [text, setText] = useState("");
  const [stausGroups, setStausGroup] = useState<null | Group>(null);
  const [filter, setFilter] = useState<typeof filters[number][]>([]);
  const [action, setAction] = useState<string | null>(null);
  const [overlay, setOverlay] = useState(false);
  const [activePerson, setActivePerson] = useState<Person | null>(null);

  useEffect(() => {
    getStautsGroups();

    if (color.background == "#ffffff") {
      StatusBar.setBarStyle("dark-content", true);
    } else {
      StatusBar.setBarStyle("light-content", true);
    }

    const didBlurSubscription = props.navigation.addListener(
      "willFocus",
      (payload) => {
        getStautsGroups();
      }
    );

    return () => {
      // Remove the listener when you are done
      didBlurSubscription.remove();
    };
  }, []);

  useEffect(() => {
    toggleBackground();
  }, [overlay]);

  const getStautsGroups = async () => {
    const groups = await GraphManager.getGroups();
    setStausGroup(groups);
  };

  const toggleBackground = () => {
    if (overlay) {
      Animated.timing(scale, {
        toValue: 0.9,
        duration: 300,
        //easing: Easing.in(),
        useNativeDriver: true,
      }).start();
      Animated.spring(opacity, {
        toValue: 0.6,
        useNativeDriver: true,
      }).start();

      StatusBar.setBarStyle(
        color.background == "#ffffff" ? "light-content" : "dark-content",
        true
      );
    }

    if (!overlay) {
      Animated.timing(scale, {
        toValue: 1,
        duration: 300,
        //easing: Easing.in(),
        useNativeDriver: true,
      }).start();
      Animated.spring(opacity, {
        toValue: 1,
        useNativeDriver: true,
      }).start();

      StatusBar.setBarStyle(
        color.background == "#ffffff" ? "dark-content" : "light-content",
        true
      );
    }
  };

  const sortFilterPeople = () => {
    let newPeople = people;
    let person: Person[] = [];
    let lowerCaseText = text.toLocaleLowerCase();

    if (Array.isArray(people)) {
      newPeople = newPeople.filter((person) =>
        person.displayName!.toLowerCase().includes(lowerCaseText)
      );

      newPeople.sort((a, b) => {
        let person1 = a.displayName!.toLowerCase();
        let person2 = b.displayName!.toLowerCase();
        if (
          person1.startsWith(lowerCaseText) &&
          !person2.startsWith(lowerCaseText)
        ) {
          return -1;
        } else if (
          !person1.startsWith(lowerCaseText) &&
          person2.startsWith(lowerCaseText)
        ) {
          return 1;
        }
        return 0;
      });

      //newPeople.filter(person => filter.includes(person.displayName.toLowerCase()) )
      filter.map((fil) => {
        if (fil == "Technologies") {
          newPeople = newPeople.filter(
            (person) => person.department == "MUTABOR Technologies GmbH"
          );
        } else if (fil == "Experience") {
          newPeople = newPeople.filter(
            (person) => person.department == "MUTABOR Brand Experience GmbH"
          );
        } else if (fil == "Identity") {
          newPeople = newPeople.filter(
            (person) => person.department == "MUTABOR Design GmbH"
          );
        } else if (fil == "Management") {
          newPeople = newPeople.filter(
            (person) => person.department == "MUTABOR Management GmbH"
          );
        } else if (fil == "Architecture") {
          newPeople = newPeople.filter(
            (person) => person.department == "MUTABOR Brandspaces GmbH"
          );
        } else {
          newPeople = newPeople.filter((person: MicrosoftGraph.User) =>
            person.displayName!.toLowerCase().startsWith(fil)
          );
        }
      });

      person = newPeople;
    }
    return person;
  };

  const handleFilter = (unit: typeof filters[number]) => {
    let newfilter = [...filter];

    if (newfilter.includes(unit)) {
      var pos = newfilter.indexOf(unit);
      newfilter.splice(pos, 1);
    } else {
      newfilter = [unit];
      //newfilter.push(unit)
    }

    setFilter(newfilter);
  };

  const getStatus = (person: MicrosoftGraph.User) => {
    let { id } = person;

    if (stausGroups == null) {
      return null;
    }

    if (stausGroups.home.includes(id)) {
      return "home";
    }

    if (stausGroups.office.includes(id)) {
      return "office";
    }

    if (stausGroups.reise.includes(id)) {
      return "reise";
    }

    if (stausGroups.urlaub.includes(id)) {
      return "urlaub";
    }

    return null;
  };

  return (
    <View
      style={[
        styles.root,
        { backgroundColor: overlay ? color.text : color.background },
      ]}
    >
      <AnimatedContainer
        style={[
          styles.container,
          {
            transform: [{ scale }],
            opacity: opacity,
            backgroundColor: color.background,
          },
        ]}
      >
        <SafeAreaView
          style={{ flex: 1, paddingTop: Constants.statusBarHeight }}
          forceInset={{ top: "always" }}
        >
          <TitelBar />

          <Search
            text={text}
            onChangeText={(text) => setText(text)}
            placeholder={"Mitarbeiter:in suchen..."}
          />

          <Filter
            activeFilters={filter}
            filters={filters}
            onPress={(tag) => handleFilter(tag as typeof filters[number])}
          />

          <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }}>
            <View style={styles.peopleBox}>
              {sortFilterPeople().map((person) => (
                <People
                  key={person.id}
                  onPress={() => {
                    setAction("people");
                    setOverlay(true);
                    setActivePerson(person);
                  }}
                  person={person}
                  office={getStatus(person)}
                />
              ))}
            </View>
          </ScrollView>
        </SafeAreaView>
      </AnimatedContainer>

      <Modal
        visible={overlay}
        transparent={true}
        animationType="slide"
        statusBarTranslucent={true}
      >
        {action == "people" && (
          <PeopleOverlay
            handleClose={() => {
              setOverlay(false);
            }}
            person={activePerson!}
          />
        )}
      </Modal>
    </View>
  );
};

export default FacesScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  root: {
    flex: 1,
  },
  peopleBox: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingRight: 12,
    paddingLeft: 24,
    paddingTop: 12,
    justifyContent: "flex-start",
  },
});

const AnimatedContainer = Animated.createAnimatedComponent(View);

const filters = [
  "Experience",
  "Identity",
  "Technologies",
  "Management",
  "Architecture",
  // "a",
  // "b",
  // "c",
  // "d",
  // "e",
  // "f",
  // "g",
  // "h",
  // "i",
  // "j",
  // "k",
  // "l",
  // "m",
  // "n",
  // "o",
  // "p",
  // "q",
  // "r",
  // "s",
  // "t",
  // "u",
  // "v",
  // "w",
  // "x",
  // "y",
  // "z",
] as const;
