import React from "react";
import FacesScreen from "../screens/FacesScreen";
import NewsScreen from "../screens/NewsScreen";
import WikiScreen from "../screens/WikiScreen";
import {
  MutaborIcon,
  PeopleOutline,
  PeopleSolid,
  WikiOutline,
  WikiSolid,
} from "../components/Icons";
import {
  createBottomTabNavigator,
  BottomTabBar,
  BottomTabBarProps,
} from "react-navigation-tabs";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { stl } from "../template/Styles";
import { useWindowDimensions } from "react-native";

const ThemedBottomTabBar = (props: BottomTabBarProps) => {
  const color = useSelector((state: RootState) => state.color);
  const { width } = useWindowDimensions();

  return (
    <BottomTabBar
      {...props}
      activeTintColor={color.baseColor}
      inactiveTintColor={color.text}
      style={{
        backgroundColor: color.background,
        borderTopWidth: 0.5,
        // height: width < 998 ? 70 : 50,
      }}
      //adaptive={true}
      showLabel={false}
      // labelStyle={width < 998 ? stl.copyM : [stl.copyL, { paddingLeft: 8 }]}
      tabStyle={{
        marginVertical: 8,
        alignItems: "center",
      }}
    />
  );
};

const TapNavigation = createBottomTabNavigator(
  {
    Faces: FacesScreen,
    Home: NewsScreen,
    Wiki: WikiScreen,
  },
  {
    initialRouteName: "Home",
    defaultNavigationOptions: ({ navigation }) => ({
      tabBarIcon: ({ focused, horizontal, tintColor }) => {
        const { routeName } = navigation.state;

        if (routeName == "Faces") {
          return focused ? (
            <PeopleSolid color={tintColor} />
          ) : (
            <PeopleOutline color={tintColor} />
          ); //focused ? <HomeSolid color={tintColor}/> :
        }
        if (routeName == "Wiki") {
          return focused ? (
            <WikiSolid color={tintColor} />
          ) : (
            <WikiOutline color={tintColor} />
          );
        }
        return <MutaborIcon color={tintColor} height={32} width={32} />;
        //return focused ? <HomeSolid color={tintColor} /> : <HomeOutline color={tintColor} />
      },
    }),
    tabBarComponent: ThemedBottomTabBar,
  }
);

export default TapNavigation;
