import React from 'react'
import { Platform, ScrollView, StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { Foods } from '../App.types'
import { RootState } from '../store'
import { stl } from '../template/Styles'
import Button from './Button'
import FoodTeaser from './FoodTeaser'

interface Props {
    onPress: () => void,
    todaysFood: Foods[]
}

const FoodOfTheWeek = ({ onPress, todaysFood }: Props) => {

    const color = useSelector((state: RootState) => state.color)
 
    return (
        <View style={{ paddingVertical: 24, width: "100%" }}>
            <Text style={[stl.copyXL, styles.text, { color: color.text }]}>Food of the Week</Text>

            { todaysFood.length <= 1 ?
                <View style={{ width: "100%", padding: 24, overflow: "visible" }}> 
                    {todaysFood.length == 1 &&  <FoodTeaser data={todaysFood[0]} card={true} single={true}/>}

                    {todaysFood.length == 0 && <FoodTeaser data={null} card={true} single={true}/>}
                </View>
            :
            <ScrollView horizontal={true} style={{ width: "100%", overflow: Platform.OS === "web" ? "scroll" : "visible" }} contentContainerStyle={{ padding: 24, overflow: "visible" }} showsHorizontalScrollIndicator={false}>
                
                {todaysFood.length > 1 && todaysFood.map((foodData, index) => {
                    return <FoodTeaser data={foodData} card={true} key={index} />
                })}

            </ScrollView>
            }

            <View style={{ alignSelf: "flex-start", paddingHorizontal: 24, }}>
                <Button onPress={onPress} text={"Mehr zum Cafe"} type={"secondary"} />
            </View>
        </View>
    )
}

export default FoodOfTheWeek

const styles = StyleSheet.create({
    text: {
        paddingHorizontal: 24,
    },
})
