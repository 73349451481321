import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  useWindowDimensions,
  ScrollView,
  Platform,
} from "react-native";
import { useSelector } from "react-redux";
import Filter from "./Filter";
import CloseButton from "./CloseButton";
import Overlay from "./Overlay";
import { stl } from "../template/Styles";
import "moment/locale/de";
import { RootState } from "../store";
import { NewsType } from "../App.types";
import CustomHtml from "./CustomHtml";

interface Props {
  handleClose: () => void;
  news: NewsType;
}

const NewsOverlay = ({ handleClose, news }: Props) => {
  const color = useSelector((state: RootState) => state.color);
  const { width } = useWindowDimensions();
  const [imageWidth, setImageWidth] = useState<number | null>(null);

  const sourceInit = news.image.uri?.startsWith(
    "https://confluence.mutabor.de/download/"
  )
    ? news.image
    : require("../assets/SplashScreen.png");
  const [source, setSource] = useState(
    Platform.OS === "web" ? require("../assets/IconMutabor.png") : sourceInit
  );

  useEffect(() => {
    if (
      Platform.OS === "web" &&
      news.image.uri?.startsWith("https://confluence.mutabor.de/download/")
    ) {
      fetch(news.image.uri, {
        method: "GET",
        headers: {
          Authorization: "Basic dHdpbmtsZXI6SnVsaWEyMDA3",
        },
      })
        .then((res) => res.blob())
        .then((content) => setSource(URL.createObjectURL(content)));
    }
  }, []);

  return (
    <Overlay
      handleClose={handleClose}
      pos={"absolute"}
      header={() => {
        return (
          <View>
            <View style={[styles.header]}>
              {/* <Text style={[stl.headlineL, { color: color.text, maxWidth: width - 84 }]}>{news.title}</Text> */}
              <CloseButton onPress={handleClose} />
            </View>
          </View>
        );
      }}
    >
      <View
        style={[
          stl.shdw,
          styles.imageBox,
          imageWidth != null && { height: imageWidth },
          width > 720 && styles.imageWebBox,
        ]}
        onLayout={({ nativeEvent: { layout } }) => setImageWidth(layout.width)}
      >
        <Image
          source={source}
          defaultSource={require("../assets/SplashScreen.png")}
          style={styles.image}
        />
      </View>

      <Text
        style={[
          stl.copyS,
          { color: color.text, paddingHorizontal: 24, paddingTop: 24 },
        ]}
      >{`${news.date.format("MMM D. YYYY")}`}</Text>

      <Text
        style={[stl.headlineL, { color: color.text, paddingHorizontal: 24 }]}
      >
        {news.title}
      </Text>

      <View style={{ paddingHorizontal: 24, paddingBottom: 24 }}>
        <CustomHtml source={news.text} contentWidth={width - 48} />
      </View>
      {/* <Text style={[stl.copyL, styles.text, { color: color.text }]}>{news.text}</Text> */}

      <View style={{ backgroundColor: color.background }}>
        {news.tags && (
          <Filter filters={news.tags} activeFilters={[]} onPress={() => {}} />
        )}
      </View>
    </Overlay>
  );
};

export default NewsOverlay;

const styles = StyleSheet.create({
  tags: {
    flexDirection: "row",
    paddingHorizontal: 20,
    maxHeight: 56,
  },
  text: {
    paddingBottom: 4,
    paddingHorizontal: 24,
  },
  headline: {
    fontWeight: "bold",
    fontSize: 24,
    fontFamily: "FS_Elliot_Pro-Heavy",
    paddingRight: 20,
  },
  image: {
    height: "100%",
    width: "100%",
  },
  header: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 10,
    minHeight: 64,
  },
  imageBox: {
    height: undefined,
    width: "100%",
    aspectRatio: 1,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  imageWebBox: {
    height: 350,
    width: 350,
    margin: 24,
    borderRadius: 4,
  },
});
