export interface ColorState {
    baseColor: string,
    text: string,
    background: string,
    card: string
}

interface ColorAction {
    type: "CHANGE_MODE" | "DARK_MODE" | "LIGHT_MODE",
}

const initalColor = {
    baseColor: "rgba(255, 65, 67, 1)",
    text: "#ffffff",
    background: "#000000",
    card: "#191B21",
}//{ r: 255, g: 65, b: 67, a: 1 },


const colorReducer = (state: ColorState = initalColor, action: ColorAction) => {
    switch (action.type) {
        case "CHANGE_MODE":
            return {
                ...state,
                text: state.background,
                background: state.text,
                card: state.card == "#191B21" ? "#ffffff" : "#191B21"
            }
        case "DARK_MODE":
            return {
                ...state,
                text: "#ffffff",
                background: "#000000",
                card: "#191B21"
            }
        case "LIGHT_MODE":
            return {
                ...state,
                text: "#000000",
                background: "#ffffff",
                card: "#ffffff"
            }
        default:
            return state
    }

}

export default colorReducer