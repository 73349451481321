import { StyleSheet } from "react-native";
import { MixedStyleRecord } from "react-native-render-html";
import { ColorState } from "../reducers/colorReducer";

export const stl = StyleSheet.create({
  headlineL: {
    fontFamily: "FS_Elliot_Pro-Heavy",
    fontWeight: "800",
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: 32,
  },
  headlineM: {
    fontFamily: "FS_Elliot_Pro-Heavy",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: 20,
    lineHeight: 24,
  },
  copyXL: {
    fontFamily: "FS_Elliot_Pro-Light",
    fontWeight: "300",
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: 32,
  },
  copyL: {
    fontFamily: "FS_Elliot_Pro-Light",
    fontWeight: "300",
    fontStyle: "normal",
    fontSize: 18,
    lineHeight: 24,
  },
  copyM: {
    fontFamily: "FS_Elliot_Pro-Light",
    fontWeight: "300",
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: 22,
  },
  copyS: {
    fontFamily: "FS_Elliot_Pro-Light",
    fontWeight: "300",
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: 20,
  },
  btn: {
    fontFamily: "FS_Elliot_Pro-Heavy",
    fontWeight: "800",
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: 20,
  },
  tags: {
    fontFamily: "FS_Elliot_Pro-Heavy",
    fontWeight: "800",
    fontStyle: "normal",
    fontSize: 12,
    lineHeight: 16,
    textTransform: "uppercase",
    opacity: 0.7,
  },
  shdw: {
    shadowColor: "rgb(0,0,0)",
    shadowOffset: { width: 0, height: 12 },
    shadowOpacity: 0.12,
    shadowRadius: 24,
    elevation: 15,
  },
});

export const tagsStyles = (
  color: ColorState,
  card: boolean | undefined
): MixedStyleRecord => {
  return {
    body: {
      color: color.text,
      fontFamily: "FS_Elliot_Pro-Light",
    },
    h1: {
      //headlineL
      fontFamily: "FS_Elliot_Pro-Heavy",
      fontWeight: "800",
      fontStyle: "normal",
      fontSize: 24,
      lineHeight: 32,
      color: color.text,
    },
    h2: {
      //headlineL
      fontFamily: "FS_Elliot_Pro-Heavy",
      fontWeight: "800",
      fontStyle: "normal",
      fontSize: 24,
      lineHeight: 32,
      color: color.text,
    },
    h3: {
      //headlineM
      fontFamily: "FS_Elliot_Pro-Heavy",
      fontWeight: "400",
      fontStyle: "normal",
      fontSize: 20,
      lineHeight: 24,
      color: color.text,
    },
    h4: {
      //CopyXL
      fontFamily: "FS_Elliot_Pro-Light",
      fontWeight: "300",
      fontStyle: "normal",
      fontSize: 24,
      lineHeight: 32,
      color: color.text,
    },
    h5: {
      //CopyL
      fontFamily: "FS_Elliot_Pro-Light",
      fontWeight: "300",
      fontStyle: "normal",
      fontSize: 18,
      lineHeight: 24,
      color: color.text,
    },
    h6: {
      //CopyM
      fontFamily: "FS_Elliot_Pro-Light",
      fontWeight: "300",
      fontStyle: "normal",
      fontSize: 16,
      lineHeight: 22,
      color: color.text,
    },
    p: {
      //CopyM
      fontFamily: "FS_Elliot_Pro-Light",
      fontWeight: "300",
      fontStyle: "normal",
      fontSize: 16,
      lineHeight: 22,
      color: color.text,
    },
    span: {
      //CopyM
      fontFamily: "FS_Elliot_Pro-Light",
      fontWeight: "300",
      fontStyle: "normal",
      fontSize: 16,
      lineHeight: 22,
      color: color.text,
    },
    strong: {
      fontFamily: "FS_Elliot_Pro-Heavy",
      color: color.text,
    },
    li: {
      //CopyM
      fontFamily: "FS_Elliot_Pro-Light",
      fontWeight: "300",
      fontStyle: "normal",
      fontSize: 16,
      lineHeight: 22,
      color: color.text,
    },
    ul: {
      color: color.text,
    },
    ol: {
      color: color.text,
    },
    pre: {
      fontFamily: "FS_Elliot_Pro-Light",
      color: color.text,
    },
    a: {
      color: color.baseColor,
      textDecorationLine: "none",
    },
    th: {
      color: color.background,
      backgroundColor: color.text,
    },
    td: {
      borderBottomWidth: color.text == "#000000" ? 1 : 0,
      borderColor: color.text,
      backgroundColor: card ? color.card : color.background,
      // borderRadius: 4,
      marginBottom: 12,
    },
    tr: {
      shadowColor: "rgb(0,0,0)",
      shadowOffset: { width: 0, height: 12 },
      shadowOpacity: 0.12,
      shadowRadius: 24,
      elevation: 15,
      paddingTop: 40,
      marginBottom: 10,
    },
  };
};
