import { Moment } from "moment";
import { ImageURISource } from "react-native";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";

export type Content = {
  content: string;
  title: string;
};

export type CafeData = {
  menu: Menu[];
  foods: Foods[];
};

export type Menu = {
  items: {
    name: string;
    price: string;
  }[];
  title: string;
};

export type Foods = {
  id: number;
  date: Moment;
  headline: string;
  image: string;
  teilnehmer: string;
};

export type FoodsData = {
  id: number;
  date: string;
  headline: string;
  image: string;
  teilnehmer: string;
};

export type NewsType = {
  title: string;
  date: Moment;
  image: ImageURISource;
  text: string;
  tags: string[];
};

export type TerminProp = MicrosoftGraph.Event & {
  start: Moment;
  end: Moment;
};

export type RecurringEvent = TerminProp & {
  recurrence: MicrosoftGraph.PatternedRecurrence;
};

export type TerminData = {
  title: Moment;
  data: TerminProp[];
};

export type Page = {
  children: Page[];
  id: number;
  title: string;
  ancestors: Page[];
};

export const OfficeArray = ["office", "home", "reise", "urlaub"] as const;
export type Office = typeof OfficeArray[number];

export type Person = MicrosoftGraph.User & {};

export type User = {
  name: string;
  email: string;
  id: string;
  jobTitle: string | null;
  department: string | null;
  status: Office | null;
};
