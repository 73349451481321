import { Office, User } from "../App.types"

type UserAction = {
    type: "UPDATE_DATA",
    payload: {
        user: User
    }
} | {
    type: "UPDATE_USER",
    payload: User
} | {
    type: "UPDATE_STATUS",
    payload: Office
}



const initalUser = {

    name: "Adele Vance",
    email: "adelev@contoso.com",
    jobTitle: null,
    id: "0",
    department: null,
    status: null

}


const userReducer = (state: User = initalUser, action: UserAction) => {
    switch (action.type) {
        case "UPDATE_STATUS":
            return {...state, status: action.payload}
        case "UPDATE_USER":
            return action.payload
        case "UPDATE_DATA":
            return action.payload.user
        default:
            return state
    }



}

export default userReducer
